import React, { useContext } from 'react';
// import { isArray } from 'lodash';

import boardContext from '../../context/BoardContext';

import ContactLogo, { getElement } from './component/ContactLogo';
import { Address, Email } from './icons';
import PtShape from './atom/PT_Shape';
import PTInput from './atom/PT_Input';
import { arrayIs } from '../../lib/lodash';
import { groupingType } from './pillowSlideType';

function group_elements(elements: any, fetching: any) {
    const grouping: { [key: string]: groupingType[] } = {};

    elements.map((e: any) => {
        if (grouping[e.group] === undefined) {
            grouping[e.group] = [e];
        } else {
            if (arrayIs(grouping[e.group])) {
                // if (isArray(grouping[e.group])) {
                grouping[e.group].push(e);
            } else {
                grouping[e.group] = e;
            }
        }
        return null;
    });

    // console.log(grouping)
    let obj: any = {};
    fetching.map((text: string) => {
        obj = { ...obj, [text]: grouping[text] };
        return null;
    });
    // console.log(obj);
    return obj;
    // return {
    //     grouping,
    //     availableColors,
    //     products,
    // }
}

export default function ContactSlide(props: any) {
    const { elements, presentationLogo, onSlideUpdate } = props;
    // editorLogo,
    const { contact_text, presentation_logo = [] } = group_elements(elements, ['contact_text', 'presentation_logo']);
    /**
     * #
     * GETTING APPLIED COLOR FROM SELECTED STYLES MAIN COLOR
     */
    const {
        element: { style, colors },
        design = [],
    }: any = useContext(boardContext);
    // console.log(style.applied, colors.value[style.applied]['main']['applied']);

    const filterDesignList = design.filter((d: any) => d.element_id === 'your_logo');

    const email = getElement(contact_text, 'textbox_right');
    const company = getElement(contact_text, 'textbox_left');
    const zip = getElement(contact_text, 'textbox_left_zip');
    const city = getElement(contact_text, 'textbox_left_city');
    const country = getElement(contact_text, 'textbox_left_country');
    const tel = getElement(contact_text, 'textbox_left_tel');
    const country_name = getElement(contact_text, 'company_name');

    const fillColor =
        colors !== undefined &&
        colors.value !== undefined &&
        style !== undefined &&
        style.applied !== undefined &&
        colors.value[style.applied].main !== undefined &&
        colors.value[style.applied].main.applied !== ''
            ? colors.value[style.applied].main.applied
            : '#666';
    // colors?.value[style?.applied]['main']['applide'] || '#666';
    const visibleFlag = filterDesignList[0] !== undefined ? filterDesignList[0] : null;

    return (
        <div className="contact_wrapper pt110">
            <ContactLogo value={presentationLogo} logo={presentation_logo} visible={visibleFlag} />

            <div className="contact_name m-b_15">
                {/* {country_name.draw_value} */}
                <PTInput
                    cssStyle="text-regular_default"
                    element={country_name}
                    onSlideUpdate={onSlideUpdate}
                    style={{
                        textAlign: 'center',
                    }}
                />
            </div>

            <div className="flex justify-center text-regular_medium text-line-height">
                <PtShape cssname="flex contact_section">
                    <Address />
                    <div className="ml10 w-80 editable">
                        <p>
                            <PTInput
                                cssStyle="text-regular_medium"
                                element={company}
                                onSlideUpdate={onSlideUpdate}
                                style={{ color: '#231f20', fontSize: '12px' }}
                            />
                        </p>
                        <p>
                            <PTInput
                                cssStyle="text-regular_medium"
                                element={zip}
                                onSlideUpdate={onSlideUpdate}
                                style={{ color: '#231f20', fontSize: '12px' }}
                            />
                        </p>
                        <p>
                            <PTInput
                                cssStyle="text-regular_medium"
                                element={city}
                                onSlideUpdate={onSlideUpdate}
                                style={{ color: '#231f20', fontSize: '12px' }}
                            />
                        </p>
                        <p>
                            <PTInput
                                cssStyle="text-regular_medium"
                                element={country}
                                onSlideUpdate={onSlideUpdate}
                                style={{ color: '#231f20', fontSize: '12px' }}
                            />
                        </p>
                        <p>
                            <PTInput
                                cssStyle="text-regular_medium"
                                element={tel}
                                onSlideUpdate={onSlideUpdate}
                                style={{ color: '#231f20', fontSize: '12px' }}
                            />
                        </p>
                    </div>
                </PtShape>
                <PtShape
                    cssStyle={{
                        width: '2px',
                        height: '82px',
                        display: 'block',
                    }}
                    fill={fillColor}
                />
                <PtShape cssname="flex contact_section pl30">
                    <Email />
                    <div className="ml10 w-80 editable">
                        <p>
                            <PTInput
                                cssStyle="text-regular_medium"
                                element={email}
                                onSlideUpdate={onSlideUpdate}
                                style={{ color: '#231f20', fontSize: '12px' }}
                            />
                        </p>
                    </div>
                    {/* <div className='ml10'>
                        {email.draw_value}
                    </div> */}
                </PtShape>
            </div>
        </div>
    );
}
