import axiosInstance from '../lib/axios';
import urls from './';

export function regenerateAccessKey(base: string, clientId: string, params: any, presentation_type: string, auth: string, extra: string) {
    const urll = `${base}/${urls.regenerateAccesskey}`;
    return axiosInstance(urll, {
        method: 'GET',
        headers: {
            client_id: clientId,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
            presentation_type,
        },
        params: {
            grant_type: 'access_key',
            auth: 'basic',
            extras: params,
        },
    }).then((response: any) => {
        const {
            accesskey,
            extraKey,
            fonts,
            templateColor,
            features,
            presentationType,
            expirytime = '',
        } = response.data;

        return {
            accesskey,
            extraKey,
            fonts,
            templateColor,
            features,
            presentationType,
            expirytime,
        };
    });
}

// export function putUpdateColorStatus ({
//     baseUrl,
//     clientID,
//     extraKey,
//     accesskey,
//     data,
// }: any) {
//     const urll = `${baseUrl}/${urls.putUpdateColorStatus}`;
//     return axiosInstance(urll, {
//         method: 'put',
//         headers: {
//             client_id: clientID,
//             'x-extra-token': `${extraKey}`,
//             authorization: `Bearer ${accesskey}`,
//         },
//         data
//     }).then((response: any) => {
//         return { status: response.status };
//     });
// }