import axiosInstance from '../../lib/axios';
import urls from '../';

export const postEcqupdate = async (base: any, client_id: any, extra: any, auth: any, payload: any, state: any) => {
    const { activeSlide = 0, setCurrent, sslides, setSlides, ecqFormatting, setECQeditaData, setProductImages } = state;
    const urll = `${base}/${urls.postEcqUpdate}`;
    return await axiosInstance(urll, {
        method: 'post',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data: payload,
    }).then((response: any) => {
        const { status, data } = response;
        const { data: resp, productReferenceData } = data;

        if (status === 200) {
            const updateSlide: any = sslides.map((aslide: any) =>
                aslide._id === payload.slide ? { ...aslide, elements: resp } : aslide
            );
            setSlides(updateSlide);
            setCurrent(updateSlide[activeSlide]);
            if (productReferenceData.products !== undefined && productReferenceData.products.length > 0) {
                const newResetEcqData = ecqFormatting(productReferenceData.products);
                setECQeditaData(newResetEcqData);
                setProductImages(productReferenceData.products);
            }
        }
        return { status, resp, productReferenceData };
    });
};
