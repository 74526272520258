import axiosInstance from '../../lib/axios';
import urls from '../';

export const postPDF = async (base: any, client_id: any, extra: any, auth: any, request: any, download = true) => {
    const urll = `${base}/${urls.postPDF}`;
    const responseType: any = download ? 'arraybuffer' : '';

    return await axiosInstance(urll, {
        method: 'post',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
            Accept: 'application/pdf',
        },
        responseType,
        data: request,
    }).then((response: any) => {
        const fileName = request.presentation !== '' ? `${request.presentation}.pdf` : 'file.pdf';
        // @ts-ignore
        if (window.navigator !== undefined && window.navigator.msSaveOrOpenBlob !== undefined) {
            //IE11 and the legacy version Edge support
            const blob = new Blob([response.data], { type: 'text/html' });
            // @ts-ignore
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            // other browsers
            if (download) {
                const bl = new Blob([response.data], { type: 'text/html' }),
                    a = document.createElement('a');
                a.href = URL.createObjectURL(bl);
                a.download = fileName;
                a.hidden = true;
                document.body.appendChild(a);
                a.click();
            } else {
                return response.data;
            }
        }

        console.log('PDF request success');
        return true;
    });
};

