import React, { useEffect, useState, useContext } from 'react';

import themeContext from '../../../../context/ThemeContext';

import './themes.css';

import ThemesColor from './Type/color';
import ThemesTheme from './Type/Theme';

const Themes = () => {
    const { themes, activetheme = 'default' }: any = useContext(themeContext);

    const [value, setvalue] = useState('');
    const [, setactivetab] = useState('general');

    useEffect(() => {
        setvalue(activetheme);
        themes.forEach((theme: any) => {
            if (theme._id === activetheme) setactivetab(theme.group);
        });
    }, [themes, activetheme]);

    //
    const [themeType] = useState('themes'); //setThemeType
    const [themeList] = useState([
        {
            name: 'Forest green',
            color: ['#012626', '#038C7F', '#3EBDB2'],
        },
        {
            name: 'Ocean',
            color: ['#012840', '#03738C', '#3FA8BF'],
        },
        {
            name: 'Sex on the beach',
            color: ['#260101', '#F27405', '#F2BB16'],
        },
    ]);

    // const sort = (a: any, b: any) => {
    //     return a.order - b.order;
    // }

    /* const activeTab = (tabname: string) => {
        setThemeType(tabname);
    }; */

    const tabDisplay = (themeType: string) => {
        switch (themeType) {
            case 'color':
                return <ThemesColor themeList={themeList} />;

            case 'themes':
                return <ThemesTheme active={'general'} list={themes} value={value} />;

            default:
                return null;
        }
    };

    return <section className="flex flex-column h-f cc2-asi de-panel-theme">{tabDisplay(themeType)}</section>;
};

export default Themes;
