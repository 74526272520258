import React from 'react';

import './loader.css';

interface ILoader {
    isLoader: number;
}

const Loader = ({ isLoader }: ILoader) => {
    return isLoader === 0 ? null : (
        <div className="flex justify-center align-center h-f w-full fixed loaderWrapper">
            <div className="loader"></div>
        </div>
    );
};
export default Loader;
