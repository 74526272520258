import React from 'react';
// import { v4 as uuidv4 } from 'uuid';
// import { publicPathLocal } from '../../helpers';
import { AvailableColorProp } from './shape';

const AvailableColor = ({
    _id,
    draw_value,
    element_id,
    height,
    width,
    x_pos,
    y_pos,
    group,
    localmenuView,
    slideType,
}: AvailableColorProp) => {
    const style = {
        height,
        width,
        left: x_pos,
        top: y_pos,
    };

    return (
        <div className="availablecolor absolute" style={style}>
            <img src={draw_value} alt="" />
        </div>
    );
};
export default AvailableColor;

