import axiosInstance from '../../lib/axios';
import urls from '..';

export const putUpdateStatus = async (base: any, client_id: any, extra: any, auth: any, data: any, set: any) => {
    const urll = `${base}/${urls.putUpdateStatus}`;
    return await axiosInstance(urll, {
        method: 'PUT',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data,
    }).then(({ data: res, status }: any) => {
        if (status === 200) {
            const { data } = res;
            set(data.isActive);
        }
    });
};
