export const CORPORATE_COLOR = 'corporate-color';
export const COVER_IMAGE = 'cover-image';

export const FRONT_COVER = 'front-cover';
export const BACK_COVER = 'back-cover';

export const SIZE_ALLOW = 2048;
export const EXTENSION_ALLOW = ['jpg', 'jpeg', 'gif', 'png'];

export const ERROR_LIST = [
    {
        type: 'size',
        text: '- File size: <strong>max 2MB</strong>',
        err: false,
        msg: 'This file size of the selected image needs to be below 2 MB.',
    },
    {
        type: 'extension',
        text: '- File type: <strong>jpg, png or gif</strong>',
        err: false,
        msg: 'The file type of the selected image needs to be .jpg, .png or .gif',
    },
];
