import axiosInstance from '../../lib/axios';
import urls from '../';

// ## SLIDE VISIBILITY CHANGE
export const postChangeSlideVisibility = async (
    base: any,
    id: any,
    presentationId: any,
    visible: any,
    client_id: any,
    extra: any,
    auth: any
) => {
    const urll = `${base}/${urls.postChangeSlideVisibility}?slide=${id}&presentation_id=${presentationId}`;
    return await axiosInstance(urll, {
        method: 'POST',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data: { visible },
    }).then((response: any) => {
        return { status: response.status };
    });
};
