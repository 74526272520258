import React, { useContext } from 'react';
import boardContext from '../../context/BoardContext';

interface SvgProp {
    _id: string;
    allow_template_color: boolean;
    draw_value: string;
    element_id: string;
    height: string;
    width: string;
    visible: boolean;
    x_pos: string;
    y_pos: string;
}

const Svg = ({ draw_value, height, width }: SvgProp) => {
    const context: any = useContext(boardContext);

    let fill: any = '';
    if (context.look) {
        fill = context.look.template_color || '';
    }

    const style = {
        height,
        width,
        fill,
        display: 'block',
        // pointerEvents: 'none'
    };
    var result = draw_value.slice(0, 4) + ` style="height:100%; width:100%; fill:${fill}" ` + draw_value.slice(4);

    return result ? (
        <div
            id="preventevent"
            className="absolute"
            style={{ ...style, top: '0' }}
            dangerouslySetInnerHTML={{ __html: result }}
        />
    ) : null;
};
export default Svg;

