import axiosInstance from '../../lib/axios';
import urls from '../';
import { backgroundSortFolderAndImage } from '../../helpers';

// ## BACKGROUND IMAGES
export const getBackgroundImages = async (
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    param?: any,
    contact_id?: any,
    setBackgroundImageList?: any
) => {
    const urll = `${base}/${urls.getBackgroundImages}`;
    const headerParams: any = {};
    if (param !== undefined) {
        headerParams.path = param;
    }
    if (contact_id !== '') {
        headerParams.contact_id = contact_id;
    }
    return await axiosInstance(urll, {
        method: 'GET',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        params: headerParams,
    }).then((response: any) => {
        const {
            data: { data },
            status,
        } = response;

        if (setBackgroundImageList !== undefined) {
            const obj: any = backgroundSortFolderAndImage(data);
            setBackgroundImageList(obj);
        }
        return { status };
    });
};
