import React, { useContext } from 'react';

import lookContext from '../../../../context/LookContext';
import settingContext from '../../../../context/SettingContext';
import languageContext from '../../../../context/languageContext';

import LogoItem from './LogoItem';

interface LogosProps {
    colorpick: (obj: any) => void;
    onPresentationLogoChange1?: any;
    onCustomerLogoChange1?: any;
}

const Logos = ({ colorpick, onPresentationLogoChange1, onCustomerLogoChange1 }: LogosProps) => {
    const { presentationLogo, editorLogo }: any = useContext(lookContext);
    const language: any = useContext(languageContext);
    const { setting }: any = useContext(settingContext);
    const {
        end_customer_logo,
        end_customer_logo_background,
        end_customer_logo_check,
        presentation_logo,
        presentation_logo_background,
        presentation_logo_check,
    }: any = setting;

    // console.log(setting);

    return (
        <div className="pt-20 setting__element">
            <div className="">
                {end_customer_logo.visible === true && (
                    <LogoItem
                        key={editorLogo.name}
                        label={language.end_customer_logo_check}
                        pass={editorLogo.name}
                        color={editorLogo.background_color}
                        checkStatus={editorLogo.visible}
                        colorpick={colorpick}
                        onLogoChange={(obj: any) => onCustomerLogoChange1(obj)}
                        display={end_customer_logo_check}
                        background={{ ...end_customer_logo_background, label: language.background_color }}
                    />
                )}
                <div className="cc2-setting__divider mt20" />
                {presentation_logo.visible === true && (
                    <LogoItem
                        key={presentationLogo.name}
                        label={language.presentation_logo_check}
                        pass={presentationLogo.name}
                        color={presentationLogo.background_color}
                        checkStatus={presentationLogo.visible}
                        colorpick={colorpick}
                        onLogoChange={(obj: any) => onPresentationLogoChange1(obj)}
                        display={presentation_logo_check}
                        background={{ ...presentation_logo_background, label: language.background_color }}
                    />
                )}
            </div>
        </div>
    );
};
export default Logos;
