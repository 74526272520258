import React, { useEffect, useState } from 'react';

interface LogoItemProps {
    label: string;
    pass: any;
    color: any;
    checkStatus: boolean | undefined;
    colorpick: (obj: any) => void;
    onLogoChange?: any;
    display: any;
    background: any;
}

function LogoItem({ label, pass, color, checkStatus, colorpick, onLogoChange, display, background }: LogoItemProps) {
    const [locColor, setLocColor] = useState(color);
    const [locCheck, setLocCheck] = useState(checkStatus);

    useEffect(() => {
        setLocCheck(checkStatus);
    }, [checkStatus]);
    useEffect(() => {
        setLocColor(color);
    }, [color]);

    const style = {
        width: '56px',
        height: '36px',
        display: 'block',
        border: '1px solid rgb(0, 0, 0)',

        background: locColor,
    };

    const changeCheck = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        onLogoChange({ type: 'visible', value: target.checked });
    };

    const pickColor = () => {
        colorpick({ color: locColor, name: pass });
    };

    return (
        <div className="logo__element">
            {display.visible === true && (
                <div className="pfc-toggle label-left toggle_small justify-space-between mb10">
                    <label className="cc_switch">
                        <input
                            type="checkbox"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeCheck(e)}
                            defaultChecked={locCheck === true ? true : false}
                        />
                        <span className="slider"></span>
                    </label>
                    <span className="label text-regular_default fz14">{label !== '' ? label : display.label}</span>
                </div>
            )}

            {background.visible === true && (
                <div className="flex align-center pt-10" onClick={pickColor}>
                    <span className="mr10" style={style}></span>
                    <span className="text-black flex1 fz12"> {background.label} </span>
                </div>
            )}
        </div>
    );
}
export default LogoItem;
