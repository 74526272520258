function getColorTitle(name: string) {
    switch (name) {
        case 'Show End customer logo':
        case 'end_customer_logo':
            return 'End customer logo background color';

        case 'Show Debtor logo':
        case 'presentation_logo_background':
            return 'Debtor logo background color';

        case 'look_and_feel':
            return 'Pick your own color';

        case 'font_color':
            return 'Font color';

        case 'title_text_background':
            return 'Title text background';

        case 'background_color':
            return 'Background color';
        case 'title_text':
            return 'Font color';
    }
    return '';
}
export default getColorTitle;

