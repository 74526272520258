import React, { useState } from 'react';

import Overlay from '../../Overlay/overlay';
import '../backgroundimage.css';
import './background.css';

import { popCloseIcons, selectIcon, uploadIcon } from '../../../helpers/IconList';

import BackgroundUpload from './BackgroundUpload';

type BackgroundSelectProps = {
    localEdit: any;
    view: any;
    onBackgroundSelect: any;
    backgroundImageList: any;
    onSlideUpdate: (a: any) => void;
    setbackgroundSelectOption: (a: any) => void;
    localmenuClose: () => void;
    fetch?: any;
    slideType?: any;
    imageUpload?: any;
    propChoice: string;
};

const BackgroundSelect: React.FC<BackgroundSelectProps> = ({
    propChoice,
    localEdit,
    view,
    onBackgroundSelect,
    imageUpload,
    localmenuClose,
    backgroundImageList,
    setbackgroundSelectOption,
}) => {
    const [choice, setChoice] = useState(propChoice);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadflag, setUploadflag] = useState(true);
    const UPLOAD_LIMIT = 75;

    // FUNCTION AREA
    const close = () => {
        view(false);
        setbackgroundSelectOption('option');
    };

    const uploadImage = () => {
        const obj = {
            id: localEdit._id,
            type: 'draw_value',
            group: localEdit.group,
        };
        imageUpload(selectedFile, obj);
        close();
        localmenuClose();
    };

    // DRAW AREA
    const userOption = () => {
        return (
            <div className="flex justify-center align-center p-50">
                <div className="upload-choice">
                    <button
                        onClick={() => {
                            onBackgroundSelect();
                            close();
                        }}
                    >
                        <div className="flex align-center justify-center">
                            <img src={selectIcon} alt="" />
                        </div>
                    </button>
                    <h4>Select</h4>
                    background image
                </div>

                <div className="upload-choice">
                    <button onClick={() => setChoice('upload')}>
                        <div className="flex align-center justify-center">
                            <img src={uploadIcon} alt="" />
                        </div>
                    </button>
                    <h4>Upload</h4>
                    new background image
                </div>
            </div>
        );
    };

    const userUploadForm = () => {
        return (
            <div>
                <BackgroundUpload
                    change={setSelectedFile}
                    file={selectedFile}
                    uploadLimitexeed={backgroundImageList >= UPLOAD_LIMIT}
                    setUploadflag={setUploadflag}
                />
            </div>
        );
    };

    // CONDITIONS CHECKS
    const currentSelect = choice === 'option';
    const title = currentSelect ? 'Select or upload background image' : 'Upload image file';

    return (
        <Overlay>
            <div className="backgroundBox backgroudSelectBox">
                <div className="modal_title">
                    <h4>{title}</h4>
                    <button onClick={close}>
                        <img src={popCloseIcons} alt="close" />
                    </button>
                </div>
                <div className="backgroudSelectBox_body">{currentSelect ? userOption() : userUploadForm()}</div>
                {!currentSelect && (
                    <div className="backgroundBox__footer">
                        <button onClick={close}>Cancel</button>
                        <button className="ok" onClick={uploadImage} disabled={uploadflag}>
                            Ok
                        </button>
                    </div>
                )}
            </div>
        </Overlay>
    );
};

export default BackgroundSelect;
