import axiosInstance from '../../lib/axios';
import urls from '../';

/**
 * * Tips for new changes will appear in local menu
 * * Tips will keep changing in 10000 ms
 * @param base
 * @param client_id
 * @param extra
 * @param auth
 * @returns
 */
export const getAllTips = async (base: any, client_id: any, extra: any, auth: any) => {
    const urll = `${base}/${urls.getAllTips}`;
    return await axiosInstance(urll, {
        method: 'get',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        params: {
            type: 'pillow',
        },
    }).then((response: any) => {
        const { status, data } = response;
        return { status, data: data.data };
    });
};
