import axiosInstance from '../../lib/axios';
import urls from '../';
import { getStoreViewFromURL } from '../../helpers';

export const postLoadDefaultTheme = async (base: any, client_id: any, extra: any, auth: any, data: any) => {
    const storeview = getStoreViewFromURL();
    const urll = `${base}/${urls.postLoadDefaultTheme}`;
    return await axiosInstance(urll, {
        method: 'post',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        params: { storeview },
        data,
    }).then((response: any) => {
        const {
            data,
            status = 200,
            productReferenceData,
            productReferenceDataDefault,
            translation,
            allColorScheme,
        } = response.data; // dummyflag ? dummyJson : response.data;
        const product_slide_elements: any = {};
        data.product_slide_elements.forEach((pse: any) => {
            if (pse.element_id === 'style') {
                pse['applied'] = 'style_1';
                pse.value.map((v: any) => {
                    if (v.status === true) pse.applied = v.name;
                    return null;
                });
            } else if (pse.element_id === 'product_highlight') {
                pse['applied'] = 'round';
                pse.value.highlight.map((v: any) => {
                    if (v.status === true) pse.applied = v.name;
                    return null;
                });
            }
            product_slide_elements[pse.element_id] = { ...pse };
            return null;
        });
        return {
            status,
            productReferenceData,
            prodDefault: productReferenceDataDefault,
            translation,
            ...data,
            product_slide_elements,
            allColorScheme,
        };
    });
};
