import axiosInstance from '../../lib/axios';
import urls from '../';

export const postReset = async (base: any, client_id: any, extra: any, auth: any, payload: any, state: any) => {
    const { current, setCurrent, sslides, setSlides } = state;
    const urll = `${base}/${urls.postReset}`;
    return await axiosInstance(urll, {
        method: 'post',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data: payload,
    }).then((response: any) => {
        const { status, data } = response;

        if (data.data.length > 0 && current !== undefined) {
            const elements: any = [];
            current.elements.map((c: any) => {
                let m = false;
                data.data.map((d: any) => {
                    if (d._id === c._id) {
                        m = true;
                        elements.push({ ...c, ...d });
                    }
                    return null;
                });
                if (!m) elements.push(c);
                return null;
            });
            const newSLides: any = sslides.map((s: any) => {
                if (current._id === s._id) {
                    return { ...s, elements };
                }
                return s;
            });
            setSlides(newSLides);
            setCurrent({ ...current, elements });
        }

        return { status, data: data.data };
    });
};
