import axiosInstance from '../../lib/axios';
import urls from '../';

// ## SLIDE ADD
export const postAddImageSlide = async (base: any, payload: any, client_id: any, extra: any, auth: any, state: any) => {
    const { sslides, setSlides, activeSlide, setActiveSlide, localMenuSlideEditValueUpdate, setCurrent } = state;
    const urll = `${base}/${urls.postAddImageSlide}`;
    return await axiosInstance(urll, {
        method: 'POST',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data: payload,
    }).then((response: any) => {
        const {
            status,
            data: { data },
        } = response;

        if (status === 200) {
            let addedSlideList: any = [];

            const idx = activeSlide; // ? activeSlide + 1 : 1;
            addedSlideList = sslides.map((s: any, i: any) => {
                if (i > payload.index) {
                    return { ...s, order_no: s.order_no + 1 };
                }
                return s;
            });
            addedSlideList.splice(idx, 0, data);
            setSlides(addedSlideList);
            setActiveSlide(idx);
            if (idx !== undefined) {
                localMenuSlideEditValueUpdate(addedSlideList[idx], 'background_image');
            }
            setCurrent(addedSlideList[idx]);
        }
        return { status, data };
    });
};
