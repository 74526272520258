import axiosInstance from '../../lib/axios';
import urls from '../';

export const postProductDesignInfo = async (
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    payload: any,
    state: any
) => {
    const { design, setDesign } = state;
    const urll = `${base}/${urls.postProductDesignInfo}`;
    return await axiosInstance(urll, {
        method: 'post',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data: payload,
    }).then((response: any) => {
        const result: any = design.map((element: any) => {
            if (element._id === payload.id) {
                return { ...element, value: payload.value };
            }
            return element;
        });
        setDesign(result);

        return { status: response.status };
    });
};
