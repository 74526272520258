import React, { useContext } from 'react';

import boardContext from '../../context/BoardContext';
import languageContext from '../../context/languageContext';
import TextLine from './TextLine';

import { TextonlyProps } from './shape';

const Textonly = (props: TextonlyProps) => {
    const {
        draw_value,
        element_id,
        color,
        visible,
        font_size,
        height,
        width,
        x_pos,
        y_pos,
        text_align,
        font_weight,
        slideType,
        allow_template_color,
    } = props;

    const context: any = useContext(boardContext);
    const translate: any = useContext(languageContext);

    // font_name,
    let fontFamily = '';
    let globalFontColor = '';
    let tempFontColor = '';
    if (context.presentationFont) {
        fontFamily = context.presentationFont;
        globalFontColor = context.look.font_color || '';
        tempFontColor = context.look.template_color || '';
    }

    if (!visible) return null;

    let fontWeight = 400;
    if (font_weight !== undefined && font_weight === 'bold') fontWeight = 700;

    const computedvalue = allow_template_color ? tempFontColor : color ? color : globalFontColor;

    const style = {
        height,
        width,
        fontFamily,
        // fontFamily: font_name, // TODO:: disucssed for now not to use this
        fontSize: `${font_size}px`,
        fontWeight,
        color: computedvalue,
        left: x_pos,
        top: y_pos,
        text_align: text_align,
    };

    const flagEle = element_id === 'available_colors_title' || element_id === 'product_detail_title';
    if (flagEle) {
        style.color = tempFontColor;
    }

    const flagclass = slideType === 'contact_slide' ? 'contactText' : '';
    const limitTextForECQItemDesc = element_id === 'item_description';
    const changedTest = draw_value ? (limitTextForECQItemDesc ? draw_value.substring(0, 30) : draw_value) : '';

    const newchangedTest = translate[`${element_id}`] || changedTest;

    if (element_id === 'price_label') {
        if (props.price_value === null || props.price_value === '') return null;
    }

    let editeffect = '';
    if (element_id === 'bottom_total_exclusive_vat_value_text') {
        editeffect = 'editeffect';
    }

    let newfontchangecss = '';
    if (
        element_id === 'price_per_product_value_text' ||
        element_id === 'total_exclusive_vat_value_text' ||
        element_id === 'total_exclusive_vat_label_text' ||
        element_id === 'bottom_price_per_product_value_text' ||
        element_id === 'bottom_total_exclusive_vat_value_text'
    ) {
        newfontchangecss = 'font-gotham-book';
    }

    return (
        <div className={`titletext absolute ${flagclass} ${editeffect} ${newfontchangecss}`} style={style}>
            <div
                className="flex text Wrap"
                id="textContainer"
                style={{ flexDirection: 'column', flex: 1 }}
                onClick={() => {}}
            >
                <TextLine text={newchangedTest} align={text_align} />
            </div>
        </div>
    );
};
export default Textonly;
