import React, { Fragment, useContext, useEffect, useState } from 'react';

import lookAndFeelContext from '../../../../context/LookAndFeelContext';
import settingContext from '../../../../context/SettingContext';
import languageContext from '../../../../context/languageContext';
// import FontFunFunction from '../../helpers/FontFunFunction';
import './look.css';

interface LookAndFeelProps {
    onLookAndFeelChange?: any;
    colorpick: (obj: any) => void;
}
// interface fontmap {
//     _id: any;
//     font_family: string;
// }

const Look = (props: LookAndFeelProps) => {
    const { colorpick, onLookAndFeelChange } = props;
    const { templateColor, lookFeel, brandColor }: any = useContext(lookAndFeelContext); // fonts, baseUrl,
    const language: any = useContext(languageContext);
    const { setting }: any = useContext(settingContext);
    const { template_color: temp_color, brand_color, font, pick_own_color = { visible: true } }: any = setting;

    const [selected, setSelected] = useState(templateColor);
    const [brandList] = useState(brandColor);

    const { font_color, template_color } = lookFeel; // font_name,

    useEffect(() => {
        setSelected(template_color);
    }, [template_color]);

    // const fontChange = (e: any) => {
    //   const { target: { value } } = e;
    //   const fontObj = fonts.find((f: any) => f.font_family === value)
    //   FontFunFunction({ ...fontObj, baseUrl }).then((response: any) => {
    //     if (response)
    //       onLookAndFeelChange({ type: 'font_name', value });
    //   });
    // }

    const colorChange = (value: any) => {
        onLookAndFeelChange({
            type: 'template_color',
            value,
        });
        setSelected(value);
        // onLookAndFeelChange();
    };
    const pickColor = () => {
        colorpick({ color: template_color, name: 'look_and_feel' });
    };

    const pickColor2 = () => {
        colorpick({ color: font_color, name: 'font_color' });
    };

    const style = {
        background: `${template_color}`,
        width: '56px',
        height: '36px',
        display: 'block',
        border: '1px solid rgb(0, 0, 0)',
    };
    const style2 = {
        background: `${font_color}`,
        width: '56px',
        height: '36px',
        display: 'block',
        border: '1px solid rgb(0, 0, 0)',
    };

    return (
        <div className="pt-20 setting__element lookandfeel">
            {temp_color.visible === true && (
                <Fragment>
                    <h3 className="gotham-black fz16 fw-normal">{language.template_color}</h3>
                    <p className="fz12 mt20 mb10 ">{language.template_color_description}</p>
                    <section className="flex flex-wrap">
                        {templateColor.length > 0 &&
                            templateColor.map(({ hex_value }: any) => (
                                <span
                                    className={`relative ${selected === hex_value ? 'setting__element__color__bg selected' : 'setting__element__color__bg'}`}
                                    key={hex_value}
                                    style={{ background: hex_value }}
                                    onClick={() => colorChange(hex_value)}
                                />
                            ))}
                    </section>
                </Fragment>
            )}

            {brand_color.visible === true && (
                <Fragment>
                    <p>{language.brand_color_description}</p>
                    <section className="flex setting__element__color__list">
                        {brandList.map(({ hex_value }: any) => (
                            <span
                                className={`${selected === hex_value ? 'setting__element__color__bg selected' : 'setting__element__color__bg'}`}
                                key={hex_value}
                                style={{ background: hex_value }}
                                onClick={() => colorChange(hex_value)}
                            />
                        ))}
                    </section>
                </Fragment>
            )}

            {pick_own_color.visible === true && (
                <div className="flex align-center mt20" onClick={pickColor}>
                    <span className="mr10" style={style}></span>
                    <span className="text-black flex1 fz12">{language.pick_own_color}</span>
                </div>
            )}

            {font !== undefined && font.visible !== undefined && font.visible === true && (
                <Fragment>
                    <div className="cc2-setting__divider mt20" />
                    <div className="">
                        <h3 className="gotham-black fz16 fw-normal">{language.template_font_colour}</h3>
                    </div>
                    <div className="flex align-center pt-10" onClick={pickColor2}>
                        <span className="mr10" style={style2}></span>
                        <span className="text-black flex1 fz12" style={{ flex: 1 }}>
                            {'Pick font color'}
                        </span>
                    </div>
                </Fragment>
            )}
        </div>
    );
};
export default Look;
