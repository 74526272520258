// src/component/Tip/index.tsx
import React, { useState, useEffect } from 'react';

export interface TipDisplayType {
    tiplist: any[];
    language: any;
}

const TipDisplay = ({ tiplist = [], language = {} }: TipDisplayType) => {
    const [currentTip, setCurrentTip] = useState<string>('');

    useEffect(() => {
        if (tiplist != null && tiplist.length > 0 && currentTip === '') {
            // const text = language[tiplist[0].key] || '...';
            const text = tiplist[0].key !== undefined ? language[tiplist[0].key] : '...';
            setCurrentTip(text);
        }

        const updateCurrentTip = () => {
            if (tiplist?.length > 0) {
                const randomIndex = Math.floor(Math.random() * tiplist.length);
                const text = tiplist[randomIndex].key !== undefined ? language[tiplist[randomIndex].key] : '...';
                setCurrentTip(text);
            }
        };

        const interval = setInterval(updateCurrentTip, 10000);
        return () => clearInterval(interval);
    }, [tiplist, language, currentTip]);

    return <>&nbsp;{currentTip}</>;
};
export default TipDisplay;
