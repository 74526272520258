import axiosInstance from '../../lib/axios';
import urls from '../';

export const getRemovePresentation = async (base: any, client_id: any, extra: any, auth: any, presentation: any) => {
    const urll = `${base}/${urls.removePresentation}`;
    return await axiosInstance(urll, {
        method: 'GET',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        params: { presentation },
    }).then((response: any) => {
        const {
            data: { success },
            status,
        } = response;
        return { status, success };
    });
};
