import React, { createContext, useContext, useState, useEffect } from "react";

import boardContext from "../../context/BoardContext";
import LayoutContext from "../../context/LayoutContext";
import langConst from "../../context/languageContext";

import LayoutStyle from "./atom/PT_SVG";
import PTTextarea from "./atom/PT_Textarea";
import PTInput from "./atom/PT_Input";
import PTMargin from "./atom/PT_Margin";
import PTImage from "./atom/PT_Image";
import DebtorLogo from "./component/DebtorLogo";

import SVG from "./atom/PT_SVG";
import { arrayIs } from "../../lib/lodash";
import { Idetail, BoardContextType, pillowSlideType, LayoutContextType, flagsType, availableColorsType, groupingType } from "./pillowSlideType";

function group_elements(elements: any) {
    // const grouping: groupingType | null | any[] = null;
    const grouping: { [key: string]: groupingType[] } = {};
    const availableColors: any = { title: null, colors: [] };
    const userContent: any = {};

    const lableObj = ["short_description_text", "long_description_text", "sku_info_text", "product_highlight", "product_image"];
    const products: any = {};
    elements.map((e: any) => {
        if (e.group === "user_content") {
            userContent[e.element_id] = e;
        } else if (grouping !== null && grouping[e.group] === undefined) {
            if (e.group === "available_colors") {
                if (e["element_id"] === "available_colors_title") {
                    availableColors.title = e;
                } else availableColors.colors.push(e);
            } else {
                if (lableObj.includes(e.element_id)) {
                    if (e.group !== "") {
                        grouping[e.group] = e;
                    }
                } else {
                    grouping[e.group] = [e];
                }
            }
        } else {
            if (grouping !== null && arrayIs(grouping[e.group])) {
                grouping[e.group].push(e);
            }
            // if (isArray(grouping[e.group])) grouping[e.group].push(e);
            else {
                if (grouping !== null) grouping[e.group] = e;
            }
        }
        return null;
    });
    return {
        grouping,
        availableColors,
        products,
        userContent,
    };
}

const PillowContext = createContext<pillowSlideType | undefined>(undefined);
function usePillowContest() {
    const context = useContext(PillowContext);
    if (!context) {
        throw new Error("Something went wrong");
    }
    return context;
}
export default function PillowSlide(props: pillowSlideType) {
    const { element, design, editorLogo, look, presentationLogo }: any = useContext(boardContext) as BoardContextType;
    const layoutContectValue = useContext(LayoutContext) as LayoutContextType;
    const { activeColor, isPredefineColor, isCustomColor }: any = layoutContectValue;
    const { green = "", point = "" }: any = useContext(langConst);

    const { brandLogoURL = "", greenpointscore = "", elements, product_artwork_design, image_type, onSlideUpdate, brandName = "" } = props; // product_template_type
    // let { grouping, availableColors } = group_elements(elements); // products
    let { font_color } = look;
    /** NOTE:
     * 5. attribute icon hidden after changing
     * cause: responst font_color is "" sometime
     * solution: #ffffff fallback if font_color is "" empty string
     */
    font_color = font_color === "" ? "#ffffff" : font_color;
    const [flags, setFlags] = useState<flagsType | null>(null);
    const [grouping, setGrouping] = useState<any>({});
    const [availableColors, setAvailableColors] = useState<availableColorsType>({
        title: null,
        colors: [],
    });
    const [userContent, setUserContent] = useState<any>({});

    const brandLogoURLContains1x1 = brandLogoURL.includes("1x1.gif");
    /**
     * PPRT-0148
     * Hide Brand logo when
     * product details -> Brand value is Unbranded or blank
     */
    const unbrandedFlag = brandName.toLowerCase() === "unbranded" || brandName === "";

    const shortTextlengt = 85;
    const longTextlengt = 367; // 370 // 500; // 419;

    useEffect(() => {
        const f: any = {};
        design.map((d: any) => {
            f[d.element_id] = d.value;
            return null;
        });
        setFlags(f);

        return () => {
            setFlags(null);
        };
    }, [design]);

    useEffect(() => {
        const d = group_elements(elements);
        setGrouping(d.grouping);
        setAvailableColors(d.availableColors);
        setUserContent(d.userContent);
        // grouping = d.grouping;
        // availableColors = d.availableColors;
        // console.log(d);
    }, [elements]);

    // console.log({ grouping, availableColors });

    if (element.length === 0) return null;
    function detailFormating(details: Idetail[]) {
        if (details.length === 0) return [];
        const result = details
            .filter((filter: Idetail) => filter.element_id !== "product_detail")
            .map((detail: Idetail) => {
                const newSvg = detail.default_value !== null && detail.default_value !== undefined ? detail.default_value.replaceAll("{{fillColor}}", `'${font_color}'`) : "i";
                return (
                    <div key={detail._id} className='flex mb5 align-center w-half'>
                        <div className='display-child-block' dangerouslySetInnerHTML={{ __html: newSvg }} />
                        <div className='word-break-all ml5 line-height_0 text-semi-bold_small fz_10'>
                            {detail._id !== "" ? (
                                <PTInput
                                    cssStyle='text-semi-bold_small fz_10 line-height_13 fw-350'
                                    style={{ color: font_color }}
                                    element={detail}
                                    onSlideUpdate={onSlideUpdate}
                                    fallbackText={true}
                                />
                            ) : null}
                        </div>
                    </div>
                );
            });
        return result;
    }

    const fromColor: boolean = isPredefineColor === true || isCustomColor === true;

    const ecf: boolean = element.colors?.value[element.style.applied].accent_1?.status;
    const ecf2: boolean = element.colors?.value[element.style.applied].accent_2?.status;
    const calMain = fromColor === true ? activeColor.main : element.colors?.value[element.style.applied].main?.applied;

    let calAccent1 = fromColor === true ? (ecf ? activeColor.accent1 : calMain) : ecf ? element.colors?.value[element.style.applied].accent_1?.applied : calMain;
    let calAccent2 = fromColor === true ? (ecf2 ? activeColor.accent2 : calMain) : ecf2 ? element.colors?.value[element.style.applied].accent_2?.applied : calMain;
    /*
     * Assign fallback value if let value is undefined
     */
    if (calAccent1 === undefined) calAccent1 = calMain;
    if (calAccent2 === undefined) calAccent2 = calMain;

    return (
        <PillowContext.Provider value={props}>
            <div className={"pillow fle"}>
                {props.children}

                <div className='hidden absolute flex wfull'>
                    <div className={"flex1 w350 p-t_20 p-r_20 p-b_20 p-l_25"}>
                        <div className='flex flex-column justify-space-between h-all'>
                            <div>
                                {/* SHORT DESCRIPTION */}
                                <div className='short_description_text flex align -center m-b_15' style={{ color: font_color, minHeight: "42px" }}>
                                    {flags !== null && flags["product_title"] && grouping !== null && grouping["short_description"] !== undefined && (
                                        <PTTextarea
                                            cssStyle='gotham-black short_description_text'
                                            style={{
                                                color: font_color,
                                                minHeight: "42px",
                                                overflow: "hidden",
                                                lineHeight: "100%",
                                            }}
                                            editheight={42}
                                            element={grouping["short_description"]}
                                            onSlideUpdate={onSlideUpdate}
                                            limit={shortTextlengt}
                                        />
                                    )}
                                </div>

                                {flags && (flags["product_sku"] || flags["green_points"]) && (
                                    <div
                                        className='flex justify-space-between'
                                        style={{
                                            height: "34px",
                                            color: font_color,
                                        }}
                                    >
                                        <div className='flex1 flex align-center' style={{ color: font_color }}>
                                            {flags !== null && grouping["sku_info"] !== null && flags["product_sku"] && grouping["sku_info"] !== undefined && (
                                                <PTInput
                                                    cssStyle='text-semi-bold_medium'
                                                    style={{ color: font_color }}
                                                    element={grouping["sku_info"]}
                                                    onSlideUpdate={onSlideUpdate}
                                                />
                                            )}
                                        </div>
                                        {greenpointscore !== null && flags !== null && flags["green_points"] && (
                                            <div className='flex1 flex justify-end'>
                                                <div className='green-point-cc flex flex-row text-bold_default p06 pr14'>
                                                    <div className='flex w40 relative'>
                                                        <span className='flex01'>
                                                            <SVG.GreenPoint />
                                                        </span>

                                                        <div
                                                            className='flex01 absolute green-number gotham-black fz12 m-r_5 flex-1 text-uppercase flex align-center justify-center'
                                                            style={{
                                                                marginRight: "0px",
                                                                left: "20px",
                                                            }}
                                                        >
                                                            {greenpointscore}
                                                        </div>
                                                    </div>

                                                    <div className='green-title gotham-black fz11 flex align-center text-uppercase text-bold_default line-height_10 wa'>
                                                        {green}
                                                        <br />
                                                        {point}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}

                                {/* LONG DESCRIPTION */}
                                {flags !== null && flags["product_description"] && grouping["long_description"] !== undefined && (
                                    <div className='long_description_text flex align-c enter m-t_20' style={{ color: font_color, minHeight: "78px" }}>
                                        <PTTextarea
                                            cssStyle='tex t-regular_small'
                                            style={{ color: font_color, minHeight: "78px" }}
                                            editheight={0}
                                            element={grouping["long_description"]}
                                            onSlideUpdate={onSlideUpdate}
                                            limit={longTextlengt}
                                        />
                                    </div>
                                )}

                                {/* AVAILABLE COLORS */}
                                {flags !== null && flags["product_information"] && (
                                    <>
                                        <div className='m-t_20'>
                                            <div className='available_color_image m-b_15 p-t_10 p-r_10'>
                                                <div className='available-color text-uppercase mb10' style={{ color: font_color }}>
                                                    {availableColors !== undefined && availableColors.title ? (
                                                        <div className='text-bold_small'>
                                                            <PTInput
                                                                cssStyle='text-bold_small'
                                                                style={{ color: font_color }}
                                                                element={availableColors.title}
                                                                onSlideUpdate={onSlideUpdate}
                                                            />
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {availableColors !== undefined &&
                                                    availableColors.title !== undefined &&
                                                    availableColors.title !== null &&
                                                    availableColors.colors !== undefined &&
                                                    availableColors.colors.length > 0 &&
                                                    availableColors.colors.map((color: any) => <PTImage key={color._id} value={color.draw_value} />)}
                                            </div>
                                        </div>

                                        {/* DETAIL SECTION */}
                                        <div className='flex flex-wrap'>{grouping["product_detail"] !== undefined && detailFormating(grouping["product_detail"])}</div>
                                    </>
                                )}
                            </div>

                            {flags !== null && flags["user_content"] && (
                                <>
                                    <div>
                                        {userContent["user_title"] !== undefined && (
                                            <PTTextarea
                                                title='Add title'
                                                cssStyle='text-bold_small gotham-black word-break-word fw-450 mb10 elementActive'
                                                style={{
                                                    color: font_color,
                                                    minHeight: "16px",
                                                    display: "block",
                                                    marginButton: "4px",
                                                    outlineColor: font_color,
                                                }}
                                                editheight={20}
                                                element={userContent["user_title"]}
                                                onSlideUpdate={onSlideUpdate}
                                                limit={0}
                                            />
                                        )}
                                        {userContent["user_text"] !== undefined && (
                                            <PTTextarea
                                                title='Add text'
                                                cssStyle='text-regular_small gootham-book word-break-word fw-325 fz11 elementActive'
                                                style={{ color: font_color, minHeight: "58px", outlineColor: font_color }}
                                                editheight={58}
                                                element={userContent["user_text"]}
                                                onSlideUpdate={onSlideUpdate}
                                                limit={longTextlengt}
                                            />
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className={"flex1 justify-end rela tive"} style={{ height: "525px" }}>
                        {flags !== null && !brandLogoURLContains1x1 && !unbrandedFlag && flags.product_debtor_logo && (
                            <div className='absolute' style={{ zIndex: 1, top: "5px", right: "5px" }}>
                                <DebtorLogo
                                    brandLogo={brandLogoURL}
                                    value={presentationLogo}
                                    style={{ backgroundColor: editorLogo.background_color }}
                                    elements={grouping.end_customer_logo.length > 0 ? grouping.end_customer_logo : []}
                                    onSlideUpdate={onSlideUpdate}
                                />
                            </div>
                        )}
                        {flags !== null && flags.product_price ? (
                            grouping.price_text !== undefined && grouping.price_text[1].default_value === null ? (
                                ""
                            ) : (
                                <div className='flex-inline p-y px pillow-margin-backgorund absolute align-center' style={{ color: "#000", zIndex: 1, right: 0 }}>
                                    <span className='pl10' style={{ color: "#000" }}>
                                        {grouping.price_text[0] !== undefined && (
                                            <PTInput cssStyle='text-semi-bold_default' element={grouping.price_text[0]} onSlideUpdate={onSlideUpdate} />
                                        )}
                                    </span>
                                    <span className='pl10' style={{ color: "#000", minWidth: "70px" }}>
                                        {grouping.price_text[1] !== undefined && (
                                            <PTMargin cssStyle='text-semi-bold_default' style={{ width: "80px" }} element={grouping.price_text[1]} onSlideUpdate={onSlideUpdate} />
                                        )}
                                    </span>
                                </div>
                            )
                        ) : (
                            ""
                        )}
                        {image_type !== "" && (image_type as string) !== "mood" && (
                            <>
                                <div className={"absolute flex justify-center align-center"} style={{ height: "inherit", width: "578px", opacity: "0.07" }}>
                                    {element.product_highlight?.value.status !== false ? (
                                        element.product_highlight?.applied === "round" ? (
                                            <LayoutStyle.CircleIcon fill={calMain} />
                                        ) : element.product_highlight?.applied === "diamond" ? (
                                            <LayoutStyle.DiamonIcon fill={calMain} />
                                        ) : null
                                    ) : null}
                                </div>
                                <div className={"relative flex justify-center align-center h-full"}>
                                    {grouping.product_image !== undefined && (
                                        <img
                                            src={product_artwork_design}
                                            alt='product'
                                            style={{ maxWidth: "440px", maxHeight: "440px" }}
                                            onClick={() => {
                                                // props.setBackgroundview(true)
                                                // props.setBackgroundImageList(ll)
                                            }}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </PillowContext.Provider>
    );
}

PillowSlide.Title = function PillwoSlideTest() {
    const props: pillowSlideType = usePillowContest();
    return props.image_type === "mood" ? (
        <div className='absolute flex justify-end overflow-hidden textalign-right h-full' style={{ width: "928px" }}>
            <div>
                <img src={props.product_artwork_design} style={{ height: "100%" }} alt='' />
            </div>
        </div>
    ) : null;
};

PillowSlide.Shape = function PillowSlideShape() {
    const { image_type }: pillowSlideType = usePillowContest();
    const { element }: any = useContext(boardContext) as BoardContextType;
    const layoutContectValue = useContext(LayoutContext) as LayoutContextType;
    const { activeColor, isPredefineColor, isCustomColor }: any = layoutContectValue;

    const fromColor: boolean = isPredefineColor === true || isCustomColor === true;

    const calMain = fromColor === true ? activeColor.main : element.colors?.value[element.style.applied].main?.applied;

    return (
        <div className='absolute flex wfull'>
            <div className={"flex1 justify-end textalign-right"}>
                {image_type !== "mood" && (
                    <div className={"absolute flex justify-center align-center w-full"} style={{ height: "inherit", opacity: "0.4" }}>
                        {element.product_highlight?.value.status === true ? (
                            element.product_highlight?.applied === "square" ? (
                                <LayoutStyle.SquareIcon fill={calMain} />
                            ) : null
                        ) : null}
                    </div>
                )}
            </div>
        </div>
    );
};

PillowSlide.Layout = function PullowSlideLayout() {
    const { element }: any = useContext(boardContext) as BoardContextType;

    const layoutContectValue = useContext(LayoutContext) as LayoutContextType;
    const { activeColor, isPredefineColor, isCustomColor }: any = layoutContectValue;
    const fromColor: boolean = isPredefineColor === true || isCustomColor === true;

    const ecf: boolean = element.colors?.value[element.style.applied].accent_1?.status;
    const ecf2: boolean = element.colors?.value[element.style.applied].accent_2?.status;
    const calMain = fromColor === true ? activeColor.main : element.colors?.value[element.style.applied].main?.applied;

    let calAccent1 = fromColor === true ? (ecf ? activeColor.accent1 : calMain) : ecf ? element.colors?.value[element.style.applied].accent_1?.applied : calMain;
    let calAccent2 = fromColor === true ? (ecf2 ? activeColor.accent2 : calMain) : ecf2 ? element.colors?.value[element.style.applied].accent_2?.applied : calMain;
    /*
     * Assign fallback value if let value is undefined
     */
    if (calAccent1 === undefined) calAccent1 = calMain;
    if (calAccent2 === undefined) calAccent2 = calMain;
    return (
        <div className='absolute h-full'>
            {element.style?.applied === "style_1" ? (
                <LayoutStyle.Style1 fill={calMain} accent1={calAccent1} accent2={calAccent2} />
            ) : element.style?.applied === "style_2" ? (
                <LayoutStyle.Style2 fill={calMain} accent1={calAccent1} accent2={calAccent2} />
            ) : (
                <LayoutStyle.Style3 fill={calMain} accent1={calAccent1} accent2={calAccent2} />
            )}
        </div>
    );
};
