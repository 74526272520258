// src/container/App.tsx
import 'babel-polyfill';
import 'promise-polyfill/src/polyfill';
import 'es6-promise';
import 'react-app-polyfill/ie11';
import React from 'react';

import Overview from './Overview';
import Presentation from './Presentation';

import globalContext from '../context/globalContext';
import overContext from '../context/OverContext';

import { AppPropsType, AppStateType } from './appType';

import '../styles/sources.css';
import './container.css';
// import './dyson.css';

import { getAccessKey } from '../api/getAccessKey/getAccessKey';
import { regenerateAccessKey } from '../api/calls';
import Tool from './Tool/Tool';

class App extends React.Component<AppPropsType, AppStateType> {
    private wrapperRef: React.RefObject<HTMLDivElement>;

    constructor(props: AppPropsType) {
        super(props);

        this.state = {
            presentationID: props.config?.presentationID || null,
            activeSlide: 0,
            history: [],
            displayOverview: false,
            clientID: '',
            old_customer_id: props.config?.old_customer_id,
            accesskey: '',
            extraKey: null,
            fonts: [],
            templateColor: [],
            brandColor: [
                { hex_value: '#3C69E7' },
                { hex_value: '#ffffff' },
                { hex_value: '#79443B' },
                { hex_value: '#006A4E' },
                { hex_value: '#D891EF' },
                { hex_value: '#FFAA1D' },
                { hex_value: '#BD33A4' },
            ],
            error: false,
            baseUrl: '',
            type: '',
            setting: {},
            extra: {},
            expirytime: '',
            presentationType: '',
        };
        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        const {
            config: { baseUrl, clientID, customer_id, contact_id, presentation_type, overView, extra },
        } = this.props;
        const parm = JSON.stringify({ customer_id, contact_id });

        getAccessKey(baseUrl, clientID, parm, presentation_type).then(
            ({
                accesskey,
                extraKey,
                fonts,
                templateColor,
                features: { global_menu = [] },
                presentationType,
                expirytime,
            }: any) => {
                const setting: any = {};

                if (global_menu.length > 0) {
                    global_menu.map((res: any) => {
                        const { name } = res;
                        setting[name] = res;
                        return null;
                    });
                }

                const localDate = new Date(expirytime);
                const localTimeStamp = localDate.getTime();

                this.setState({
                    displayOverview: overView,
                    baseUrl,
                    clientID,
                    expirytime: localTimeStamp,
                    extra,
                    accesskey,
                    extraKey,
                    fonts,
                    templateColor,
                    setting,
                    type: presentationType?._id || presentation_type,
                    presentationType,
                });
            }
        );
    }

    regenerateToken = async (_id: any, flag = false) => {
        const _this = this;
        const { baseUrl, clientID = '', accesskey, extraKey, expirytime } = this.state;
        const params = { grant_type: 'access_key', auth: 'basic' };

        const nowdate: any = Date.now();
        const check = expirytime <= nowdate; // currentdate;

        if (check || flag) {
            if (accesskey && extraKey) {
                return await regenerateAccessKey(baseUrl, clientID, params, _id, accesskey, extraKey).then(
                    ({ accesskey, expirytime, presentationType, ...resp }: any) => {
                        _this.setState({ accesskey, expirytime, presentationType });
                        return { accesskey, ...resp };
                    }
                );
            }
        }
        return { accesskey };
    };

    setActiveSlide = (activeSlide: any) => {
        this.setState({ activeSlide });
    };

    setDisplayOverview = (displayOverview: boolean) => {
        this.setState({ displayOverview });
    };

    editPresentationID = (id: any) => {
        window.localStorage.setItem('presentionID', id);
        this.setState({
            presentationID: id,

            displayOverview: this.state.displayOverview === false,
        });
    };

    setType = (typeid: string) => {
        this.setState({ type: typeid });
    };

    setFeatures = (features: string) => {
        this.setState({ setting: features });
    };

    render() {
        const { type, displayOverview, accesskey, extraKey, error } = this.state;

        if (error) return <div className="unauthorized">Unauthorized</div>;

        if (extraKey === null || accesskey === null) return null;

        const config = this.props.config;
        return (
            <globalContext.Provider value={config.baseUrl}>
                <div
                    id="app_wrapper"
                    className={`pf-container ${this.props.config.tools === true ? 'main-page' : 'other-page'}`}
                    ref={this.wrapperRef}
                >
                    {this.props.config.tools === true ? (
                        <Tool {...this.state} type={type} validationToken={this.regenerateToken} />
                    ) : displayOverview === true ? (
                        <overContext.Provider
                            value={{
                                sorts: config.pageSortList,
                                sizes: config.pageSizeList,
                                sort: config.pageSort,
                                size: config.pageSize,
                            }}
                        >
                            <Overview
                                {...this.state}
                                onEditPresentationID={this.editPresentationID}
                                setDisplayOverview={this.setDisplayOverview}
                                setType={this.setType}
                                setFeatures={this.setFeatures}
                                type={type}
                                container={config.container}
                                validationToken={this.regenerateToken}
                            />
                        </overContext.Provider>
                    ) : (
                        <Presentation
                            {...this.state}
                            display={displayOverview}
                            setDisplayOverview={this.setDisplayOverview}
                            sliders={[]}
                            change={this.setActiveSlide}
                            current={null}
                            validationToken={this.regenerateToken}
                        />
                    )}
                </div>
            </globalContext.Provider>
        );
    }
}
export default App;
