import React, { useState } from "react";

function EditableItemCell({
    style,
    sku = "",
    children = "",
    name = "",
    change,
    editedWith,
    setCell,
}: any) {
    const [, setQtyFocus] = useState(false);

    function cellChange(e: any) {
        if (editedWith === "" || editedWith === name || name === "qty" || name === 'label') {
            change(sku, e.target.value, name);
        }
    }

    function onQtyFocus() {
        setCell(sku);
        setQtyFocus(true);
    }
    function onQtyBlur() {
        setQtyFocus(false);
    }

    return (
        <div className="EditableItemCell">
            <input
                type="text"
                value={children}
                onChange={cellChange}
                style={style}
                onFocus={onQtyFocus}
                onBlur={onQtyBlur}
            />
        </div>
    );
}
export default EditableItemCell;
