import React, { useState, useEffect, useContext } from "react";

import LookAndFeelContext from "../../../../../context/LookAndFeelContext";
import languageContext from "../../../../../context/languageContext";

import StyleIcon from "../../../../PillowSlide/atom/PT_SVG";

const Shape = () => {
    const { element, onElementChangge }: any = useContext(LookAndFeelContext);
    const language: any = useContext(languageContext);

    const { style = { value: [] }, product_highlight = { value: { highlight: [], status: true } } } = element;

    const [sty, setsty] = useState(style?.value);
    const [hlight, sethlight] = useState(product_highlight);

    useEffect(() => {
        setsty(style.value);
    }, [style]);

    useEffect(() => {
        sethlight(product_highlight);
    }, [product_highlight]);

    const onChange = (label: string, list: any, type?: any) => {
        const newSty = list.map((s: any) => {
            if (s.name === label) {
                return { ...s, status: true };
            }
            return { ...s, status: false };
        });
        if (type === "style") {
            onElementChangge({ ...element.style, value: newSty, applied: label });
        }
        if (type === "highlight") {
            const highlightObj = {
                ...element.product_highlight,
                value: {
                    ...element.product_highlight.value,
                    highlight: newSty,
                },
                applied: label,
            };
            onElementChangge(highlightObj);
        }
    };

    return (
        <div>
            <h3 className='gotham-bold fz16 fw-350 mb15'>{language["style"] !== undefined ? language["style"] : "Style"}</h3>
            <div className='flex justify-spac e-between cc2-style_wrap'>
                {sty
                    .filter((s: any) => s.name !== "style_2")
                    .map(({ name, status }: any) => (
                        <div
                            key={name}
                            role='button'
                            className={`cc2-style-item ${status === true ? "active" : "cursor-pointer"}`}
                            onClick={() => {
                                if (status === false) onChange(name, sty, "style");
                            }}
                        >
                            {name === "style_1" && <StyleIcon.StyleOne />}
                            {name === "style_2" && <StyleIcon.StyleTwo />}
                            {name === "style_3" && <StyleIcon.StyleThree />}
                        </div>
                    ))}
            </div>

            <div className='cc2-setting__divider'>
                <div className={"flex justify-space-between"}>
                    <h3 className='gotham-bold fz16 fw-350 mb15'>{language["product_highlight"] !== undefined ? language["product_highlight"] : "Product highlight"}</h3>
                    <label className='cc_switch'>
                        <input
                            type='checkbox'
                            checked={hlight.value.status}
                            name='highlight'
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const highlightObj = {
                                    ...element.product_highlight,
                                    value: {
                                        ...element.product_highlight.value,
                                        status: e.target.checked,
                                        name: "status",
                                    },
                                };
                                onElementChangge(highlightObj);
                            }}
                        />
                        <span className='slider_pillow cursor-pointer round'></span>
                    </label>
                </div>
            </div>

            <div className='flex justify-spa ce-between'>
                {hlight.value.highlight.length > 0 &&
                    hlight.value.highlight.map(({ name, status }: any) => {
                        return (
                            <div
                                className={`highlight-item ${status === true ? "active" : "cursor-pointer"}`}
                                key={name}
                                role='button'
                                onClick={() => {
                                    if (status === false) onChange(name, hlight.value.highlight, "highlight");
                                }}
                            >
                                {name === "round" && <StyleIcon.CircleIcon />}
                                {name === "square" && <StyleIcon.SquareIcon />}
                                {name === "diamond" && <StyleIcon.DiamonIcon />}
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default Shape;
