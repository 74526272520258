import axiosInstance from '../../lib/axios';
import urls from '../';
import { getStoreViewFromURL } from '../../helpers';

// ## PRESENTATION DETAIL
export const getUserPresentationDetail = async (
    base: any,
    presentation: any,
    client_id: any,
    extra: any,
    auth: any
) => {
    const urll = `${base}/${urls.getUserPresentationDetail}`;
    const storeview = getStoreViewFromURL();
    return await axiosInstance(urll, {
        method: 'GET',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        params: { presentation, storeview },
    }).then((response: any) => {
        const {
            data,
            status = 200,
            productReferenceData,
            productReferenceDataDefault,
            translation,
            allColorScheme,
        } = response.data; // dummyflag ? dummyJson : response.data;

        const product_slide_elements: any = {};
        data.product_slide_elements.forEach((pse: any) => {
            if (pse.element_id === 'style') {
                pse['applied'] = 'style_1';
                pse.value.map((v: any) => {
                    if (v.status === true) pse.applied = v.name;
                    return null;
                });
            } else if (pse.element_id === 'product_highlight') {
                pse['applied'] = 'round';
                pse.value.highlight.map((v: any) => {
                    if (v.status === true) pse.applied = v.name;
                    return null;
                });
            }
            product_slide_elements[pse.element_id] = { ...pse };
            return null;
        });
        /**
         * end_customer_logo: 1
         * exported_format: 0
         * presentation_background: 1
         * presentation_display_name: 1
         * presentation_font: 0
         * presentation_format: 0
         * presentation_logo: 1
         * presentation_original_name: 0
         * slides: 1
         */
        // console.log(data.product_slide_elements, product_slide_elements)
        return 'pillow' === response.data.data.type
            ? {
                  status,
                  productReferenceData,
                  prodDefault: productReferenceDataDefault,
                  translation,
                  ...data,
                  product_slide_elements,
                  allColorScheme,
              }
            : {
                  status,
                  productReferenceData,
                  prodDefault: productReferenceDataDefault,
                  translation,
                  ...data,
              };
    });
};
