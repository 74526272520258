import axiosInstance from '../../lib/axios';
import urls from '..';

export const deleteCoverImage = async (base: any, client_id: any, extra: any, auth: any, data: any, set: any) => {
    const urll = `${base}/${urls.deleteCoverImage}/${data.settingId}`;

    const { setCover, setLoading } = set;
    return await axiosInstance(urll, {
        method: 'DELETE',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data,
        params: {
            type: data.type,
        },
    }).then(({ status, data: res }: any) => {
        if (status === 200) {
            setCover(res.data);
        }
        setLoading((o: number) => o - 1);
        return status;
    });
};
