import React, { useContext } from 'react';
import { TRASH_SVG } from '../icons';
import languageContext from '../../../context/languageContext';

const ImageComp = ({ upload, onDelete, data }: any) => {
    const language = useContext<any>(languageContext);

    return (
        <div className="cc-tlist">
            <div className="p20">
                <div className="flex justify-space-between fz14 mb20">
                    <div className="fz14 lh14 fw-350 gotham-medium">
                        {data.imageName} <span className="fw-325 gotham-book">{language?.h_uploaded}</span>
                    </div>
                    <div className="relative">
                        <button
                            className="absolute"
                            onClick={onDelete}
                            style={{
                                top: '-8px',
                                left: '-36px',
                            }}
                        >
                            {/* <i className=" icon pfc-delete"></i> */}
                            <TRASH_SVG />
                        </button>
                    </div>
                </div>
                {data.errorline.length > 0 && (
                    <p className="fz14 fw-325 lh21 py20" style={{ borderTop: '1px solid #e2e2e2', color: 'red' }}>
                        {data.errorline}
                    </p>
                )}
                <div className="flex justify-center cc-image">
                    <img className="cursor-pointer " src={data.imageUrl} onClick={upload} alt="" />
                </div>
            </div>
        </div>
    );
};
export default ImageComp;
