// src/component/Panel/ProductSlideLayout/Thumb.tsx
import React, { useContext } from 'react';

import lookAndFeelContext from '../../../../context/LookAndFeelContext';
import languageContext from '../../../../context/languageContext';
import { ECQ_DEFAULT_SVG, E_PROOF_SVG, E_PROOF_PRICE_SVG } from '../icons';

export const CAT_IMAGE_LIST = ['4_products', '2_products', '1_product', 'photo_layout'];

const Item = ({ id, template_type, name, thumb, flag }: any) => {
    const { onTemplateChange, setTemplateId }: any = useContext(lookAndFeelContext);
    const { ...translation }: any = useContext(languageContext);

    const { active = '', inactive = '' } = thumb;

    function templateChange(_id: any, name: any) {
        if (flag === true) return null;
        setTemplateId(_id);
        onTemplateChange(name);
    }

    const getImageSvg = (match: string, flag = false) => {
        const color = flag === true ? '#3082F9' : '#c3c3c3';
        switch (match) {
            case 'default':
                return <ECQ_DEFAULT_SVG color={color} />;
            case 'eproof':
                return <E_PROOF_SVG color={color} />;
            case 'eproof_with_price':
                return <E_PROOF_PRICE_SVG color={color} />;
        }
    };

    if (CAT_IMAGE_LIST.includes(template_type) === true) {
        return (
            <div className="slideTemplate_list" onClick={() => templateChange(id, name)}>
                <label className="flex flex-column align-center cursor-pointer">
                    <img src={flag === true ? active : inactive} alt="" />
                    &laquo;{translation[`${template_type}`]}&raquo;
                </label>
            </div>
        );
    }

    return (
        <div className="slideTemplate_list mb20 cursor-pointer" onClick={() => templateChange(id, name)}>
            <div className="img mb10">
                {/* <img src={flag === true ? active : inactive} alt="" /> */}
                {getImageSvg(template_type, flag)}
            </div>
            <div> &laquo;{translation[`${template_type}`]}&raquo;</div>
        </div>
    );
};
export default React.memo(Item);
