/**
 * src/component/BackgroundImage/BackgroundImage.tsx
 * component : BACKGROUND IMAGE CHANGE
 * uses : Background Image change, Product Image change
 */

import React, { useState, useContext, useEffect, Fragment } from "react";
import languageContext from "../../context/languageContext";

import FolderDisplay from "./FolderDisplay";
import { ICON_DELETE, ICON_ARROW } from "./icons";

// Icons
import { popCloseIcons, uploadFolderUpArrowIcon } from "../../helpers/IconList";
// STYLE
import "./backgroundimage.css";
import { ReactComponent as Icon } from "../../assets/folder.svg";
import Button from "../_atom/Button/Button";

type localEditType = {
    element_id: string;
    group: string;
    slideType: string;
    _id: string;
};
type BackgroundImagesProps = {
    localEdit: localEditType;
    view: any;
    backgroundImageList: any;
    setBackgroundImageList: (a: any) => void;
    setbackgroundSelectOption: (a: any) => void;
    setBackgroundUpload: (a: any) => void;
    deleteBackgroundImage: any;
    onSlideUpdate: (a: any) => void;
    elements: any;
    localmenuClose: () => void;
    breadcrumb: any;
    slideType: any;
};

const BackgroundImage: React.FC<BackgroundImagesProps> = ({
    localEdit,
    view,
    localmenuClose,
    backgroundImageList = 0,

    deleteBackgroundImage,
    setbackgroundSelectOption,
    setBackgroundUpload,
    onSlideUpdate,
    elements = [],
    breadcrumb,
    slideType = "",
}) => {
    const delObj = { fileName: null, subdir: null };
    const [value, setValue] = useState("");
    const [ini, setIni] = useState(true);

    const [path, setPath] = useState("");
    const [currentReq, setCurrentReq] = useState(delObj);

    const [open, setOpen] = useState(false);
    const [confirm] = useState(false);
    const [all, setAll] = useState(false);
    const offset = -350;
    const [top, setTop] = useState<any>(offset);
    // const [btnDisable, setBtnDisable] = useState(false);

    const [deleteActionFlag, setDeleteActionFlag] = useState(false);

    const { select_product, title_slide_backgorund_image }: any = useContext(languageContext);
    let title: any = title_slide_backgorund_image;
    let itemCss = "imageBtn";
    let activeValue = "";
    if (localEdit !== undefined && localEdit.element_id !== "") {
        const filter = elements.filter((ele: any) => ele._id === localEdit._id);
        activeValue = filter[0].draw_value !== "" ? filter[0].draw_value : "";
    }

    const sendChang = () => {
        if (backgroundImageList.length === 0 || value === "") return null;
        const obj = {
            id: localEdit._id,
            type: "draw_value",
            group: localEdit.group,
            value,
        };
        onSlideUpdate(obj);
        close();
        localmenuClose();
    };

    if (localEdit !== undefined && localEdit.group !== "") {
        if (localEdit.group.includes("product_image") || localEdit.group.includes("left_large_image")) {
            title = select_product;
            itemCss = "productBtn";
        }
    }

    const close = () => {
        view(false);
        setbread([]);
    };

    const { list, setbread, fetch } = breadcrumb;

    const breadclick = (index: any) => {
        const { path, name } = list[index];
        const newlist = list.slice(0, index + 1);
        // console.log(path, name, newlist);
        setbread(newlist);

        // const slash = path[path.length - 1];
        const req: any = `${path}${name}`;
        fetch(req);
    };
    const makepath = (index: any) => {
        const { path, name } = backgroundImageList[index];
        setbread([...list, { name, path }]);
        // const slash = path[path.length - 1];
        const req: any = `${path}${name}`;
        setPath(req);
        fetch(req);
    };

    /* const fn_delete_all = () => {
        // setBtnDisable(false);
        setAll(true);
        setConfirm(true);
        setOpen(true);
        setTop(200);
    }; */

    const fn_delete_image = (index: any) => {
        // setBtnDisable(false);
        const { name, path } = backgroundImageList[index];
        const newPath = path.replace(/^\/|\/$/g, "");
        console.log("newPath", newPath);
        setCurrentReq({
            fileName: name,
            subdir: newPath !== "" ? newPath : path,
        });
        setOpen(true);
        setTop(200);
    };

    const fn_delete_ok = () => {
        // alert('OK')
        // setBtnDisable(true);
        if (all) {
            const newPath = path.replace(/^\/|\/$/g, "");
            deleteBackgroundImage({
                subdir: newPath,
                deleteAll: true,
            });
            return null;
        }
        deleteBackgroundImage(currentReq);
        setValue("");
    };
    useEffect(() => {
        setTop(offset);
        setCurrentReq({ fileName: null, subdir: null });
        setOpen(false);
        setAll(false);
    }, [backgroundImageList, offset]);

    useEffect(() => {
        if (backgroundImageList.lenght > 0 && path === "/Your Images") {
            setDeleteActionFlag(true);
        } else {
            let flag = false;
            backgroundImageList.map((l: any) => {
                if (l.type !== "dir") flag = true;
                return null;
            });
            const conditionFlag = path === "/Your Images" && flag;
            setDeleteActionFlag(conditionFlag);
        }
    }, [backgroundImageList, list.length, path]);

    const breadcrumFlag = slideType === "title_slide" || slideType === "image_slide" || slideType === "image_slide2";

    // DELETE ACTION ONLY APPEARS IN "/Your Images" DIRECTORY
    function sortByName(a: any, b: any) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    }

    return (
        <>
            <div className='backgroundBoxWrap'>
                <div className={!breadcrumFlag ? "backgroundBox" : "backgroundBox backgroundSelectModal plus_delete"}>
                    <div className='backgroundBox__title'>
                        <h4>{title}</h4>
                        <button onClick={close}>
                            <img src={popCloseIcons} alt='close' />
                        </button>
                    </div>
                    {breadcrumFlag && (
                        <div className='bbimage'>
                            <span
                                className='foldername'
                                role='button'
                                onClick={() => {
                                    setbread([]);
                                    // setPath('')
                                    fetch("/");
                                }}
                            >
                                <span className='home'>
                                    <Icon className='home__icon' />
                                </span>
                                Library
                            </span>
                            {list.map(({ name }: any, index: any) => {
                                return (
                                    <Fragment key={name}>
                                        <span className='noteffect'>&gt;</span>
                                        <span
                                            className='foldername'
                                            role='button'
                                            title={name}
                                            onClick={() => {
                                                // console.log(index);
                                                if (list.length !== index + 1) breadclick(index);
                                            }}
                                        >
                                            {name}
                                        </span>
                                    </Fragment>
                                );
                            })}
                        </div>
                    )}
                    {deleteActionFlag ? (
                        <div className='flex justify-end'>
                            {/* <button className="btn_delete_all" onClick={fn_delete_all}>
                                <span className="mr10">{ICON_DELETE}</span>
                                Delete all
                            </button> */}
                        </div>
                    ) : null}

                    <div className='backgroundBox__body'>
                        {backgroundImageList.length > 0 ? (
                            <>
                                <div className='backgroundScrollWrapline'></div>
                                <div className='backgroundScrollWrap'>
                                    {backgroundImageList
                                        .sort(sortByName)
                                        .sort((x: any, y: any) => (x.name === "Your Images" ? -1 : y.name === "Your Images" ? 1 : 0))
                                        .map(({ name, type = "image", ...other }: any, index: any) => {
                                            // const flag = ini && (url).toLocaleLowerCase() === (activeValue).toLocaleLowerCase();
                                            // const flag2 = url === value;
                                            // console.log(other);
                                            if (type === "image") {
                                                const { url, thumb } = other;
                                                const flag = ini && url.toLocaleLowerCase() === activeValue.toLocaleLowerCase();
                                                const flag2 = url === value;
                                                return (
                                                    <button
                                                        key={name}
                                                        onClick={() => {
                                                            setIni(false);
                                                            setValue(url);
                                                        }}
                                                        className={flag ? `${itemCss} active` : flag2 ? `${itemCss} active` : itemCss}
                                                    >
                                                        {deleteActionFlag ? (
                                                            <span className='delete-image-btn' role='button' onClick={() => fn_delete_image(index)}>
                                                                <span>{ICON_DELETE}</span>
                                                            </span>
                                                        ) : null}
                                                        <img src={thumb} alt={name} />
                                                    </button>
                                                );
                                            }
                                            return <FolderDisplay key={name} index={index} name={name} makepath={makepath} list={list.length} />;
                                        })}
                                </div>
                            </>
                        ) : (
                            <>
                                {/* <div className="backgroundScrollWrapline"></div> */}

                                <div
                                    className='backgroundScrollWrap after_delete_all_screen_bg flex flex-column justify-center align-center w-full'
                                    style={
                                        {
                                            // height: '412px',
                                            // width: '720px',
                                            // border: '1px solid #CCC'
                                        }
                                    }
                                >
                                    <p className='w-full textalign-center py20 px30 fz14'>No images available to display. You haven’t uploaded any images yet.</p>
                                    <button
                                        className='flex align-center upload-images-btn mt30'
                                        onClick={() => {
                                            setbackgroundSelectOption("upload");
                                            close();
                                            setBackgroundUpload(true);
                                        }}
                                    >
                                        <img className='mr8' src={uploadFolderUpArrowIcon} alt='' />
                                        Upload images
                                    </button>
                                </div>
                            </>
                        )}
                    </div>

                    <div className='backgroundBox__footer'>
                        <button onClick={close}>Cancel</button>
                        <button className='ok' onClick={sendChang} disabled={value === ""}>
                            Ok
                        </button>
                    </div>
                </div>
            </div>
            {
                <div className='confirm_overlay flex align-center justify-center' style={!open ? { display: "none" } : {}}>
                    {/* {!open ? { display: 'none' } : {}} */}
                    {/* test */}
                </div>
            }
            <div className={`confirm_overlay_content textalign-center ` + (confirm ? "" : "confirm_overlay_off")} style={{ top }}>
                {all ? (
                    <p className='mb10 fz16'>
                        Are you sure you want to delete <strong>all images</strong>?<br />
                        This action can’t be undone.
                    </p>
                ) : (
                    <p className='mb10 textalign-center fz16'>Are you sure you want to delete this image?</p>
                )}
                <div className='flex'>
                    {/* <button
                        className='btn_cancel mr20'
                        disabled={btnDisable}
                        onClick={() => {
                            // setConfirm(false);
                            setTop(offset)
                            setCurrentReq(delObj)
                            setOpen(false);
                            setAll(false);
                        }}
                    >
                        {ICON_ARROW}
                        Cancel
                    </button> */}
                    {/* <button className='btn_ok' onClick={fn_delete_ok} disabled={btnDisable}>OK</button> */}
                    <Button
                        sty='mr20 btn-cancel'
                        click={() => {
                            // setConfirm(false);
                            setTop(offset);
                            setCurrentReq(delObj);
                            setOpen(false);
                            setAll(false);
                        }}
                    >
                        {ICON_ARROW}Cancel
                    </Button>
                    <Button click={fn_delete_ok}>OK</Button>
                </div>
            </div>
        </>
    );
};
export default BackgroundImage;
