export const textGroupList = [
    'sku_info',
    'short_description',
    'long_description',
    'available_colors',
    'price_text',

    'product-name',
    "product_details",
    'date',
    'product_detail'
];

export const imageGroupList = [
    'decorator_product_image',
    'product_image_1',
    'product_image_2',
    'product_image_3',
    'available_colors',
    'product_artwork_background',
    'product_image',
];

export const editableElementList = [
    'sku_info_text',
    'short_description_text',
    'long_description_text',
    'price_text',
    'product_name',
    'brand_label',
    'material_label',
    'length_label',
    'width_label',
    'height_label',
    'weight_label',
    'country_of_origin_label',
    'diameter_label',
    'available_colors_title',
    'product_detail_title',

    'article_label',
    'color_label',
    'method_label',
    'location_label',
    'size_label',
    'pms_label',
    'price_label',
    'bottom_price_label',
    'bottom_total_label',

    'length_value',
    'width_value',
    'height_value',
    'diameter_value',
    'weight_value',
    'material_value',
    'brand_value',
    'country_of_origin_value',

    'article_value',
    'color_value',
    'method_value',
    'location_value',
    'size_value',
    'pms_value',

    'date_label',
    'date_value',
];
