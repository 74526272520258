import axiosInstance from '../../lib/axios';
import urls from '../';

/**
 * Save Custom Color
 */
export async function saveColor(base: any, data: any, client_id: any, extra: any, auth: any) {
    const urll = `${base}/${urls.saveColor}`;

    return await axiosInstance(urll, {
        method: 'post',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data,
    })
        .then((response: any) => {
            const {
                status,
                data: { message, success, data },
            } = response;

            return { status, success, message, data };
        })
        .catch((error: any) => {
            return Promise.reject(error);
        });
}
