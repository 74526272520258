import React, { useState, useContext, useRef } from 'react';

import ThemeContext from '../../../../context/ThemeContext';
import languageContext from '../../../../context/languageContext';
import './margin.css';

const Margin = () => {
    const theme: any = useContext(ThemeContext);
    const language: any = useContext(languageContext);

    const [margin, setMargin] = useState<string | number>(theme.margin);
    const [bottomAlert, setBottomAlert] = useState(false);

    const inputRef: any = useRef(null);

    const updateMargin = async () => {
        const data = await theme.fetchPresentationUpdate({ margin });
        if (data?.status === 200) {
            setBottomAlert(true);
            setTimeout(() => setBottomAlert(false), 300);
        }
    };

    const handleMarginChange = ({ target }: any) => {
        if (isNaN(target.value)) return null;
        setMargin(target.value);
    };

    const handleClick = () => {
        if (margin === '' || margin < 0 || margin > 99) {
            alert('Please enter valid number (between 0 and 100).');
            setMargin('');
            inputRef.current.focus();
        } else if (margin < 20) {
            const confirmStatus = window.confirm(
                `The margin you have selected seems low, are you sure you want to use ${margin}% as the default margin on your products ?`
            );
            if (confirmStatus) {
                updateMargin();
            } else {
                inputRef.current.focus();
            }
        } else {
            updateMargin();
        }
    };

    return (
        <section className="pt-20 c-s-p-margin h-f">
            <p className="fz12 mb20">
                The margin percentage which you can fill in below will be instantly applied to the prices of all
                products in this catalogue after clicking OK.
            </p>

            <div className="flex justify-space-between align-center w-full mb20">
                <span className="fz14 fw-325">Margin</span>
                <div className="flex align-center ">
                    <input
                        type="text"
                        ref={inputRef}
                        className="margin-input"
                        value={margin}
                        onChange={handleMarginChange}
                    />
                    <span className="fz16 fw-325">%</span>
                </div>
            </div>

            <button className="action pfc-dark-blue-bg w-full" onClick={handleClick}>
                {language.ok}
            </button>

            {bottomAlert ? (
                <div className="margin_bottom_text">
                    <span className="margi n_checkmark">&#x2714;</span>️{language.price_have_been_recalculated}
                </div>
            ) : null}
        </section>
    );
};

export default Margin;
