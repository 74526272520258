import React, { useState, useEffect } from "react";
import { upIcon, downIcon } from "../../helpers/IconList";
// import { decimalPlacing } from "./edithelp";

const EditableMargin = ({
    sku = "",
    name = "",
    children = "",
    change,
    style,
}: any) => {
    const [child, setChild] = useState(children);

    useEffect(() => {
        if (children) {
            setChild(children);
        }
    }, [children]);

    function cellChange(e: any) {
        const {
            target: { value },
        } = e;
        // console.log("margin is = ", value);
        if (value < 100) {
            const val2 = value;
            change(sku, val2, name);
            setChild(value);
        } else {
            setChild(child);
        }
    }

    function up() {
        if (child < 99) {
            const newChild = Number(child) + 1;
            change(sku, newChild, name);
        }
    }
    function down() {
        if (child >= 1) {
            const newChild = Number(child) - 1;
            setChild(newChild);
            change(sku, newChild, name);
        }
    }

    return (
        <div className="flex editablebox">
            <input
                type="number"
                className="editablebox_input"
                value={child}
                onChange={cellChange}
                min={0}
                style={style}
            />
            <div className="flex editablebox_action">
                <img src={upIcon} onClick={up} alt="up" />
                <img src={downIcon} onClick={down} alt="down" />
            </div>
        </div>
    );
};
export default EditableMargin;
