import React from 'react';

import './overlay.css';
import './modal.css';

const Overlay = (props: any) => {
    return <div className="overlay_wrap">{props.children}</div>;
};

export default Overlay;
