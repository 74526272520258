import axiosInstance from '../../lib/axios';
import urls from '../index';
import { getStoreViewFromURL } from '../../helpers';

export const getAllAdminSetting = async (
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    {
        setColorFlag,
        setCoverFlag,
        setScheme,
        setCover,
        setLanguage,
        setLoading,
    }: any
) => {
    const urll = `${base}/${urls.getAllAdminSetting}`;
    const storeview = getStoreViewFromURL();

    return await axiosInstance(urll, {
        method: 'GET',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        params: {
            storeview,
        },
    }).then(({ data: res, status }: any) => {
        const { translation = {}, data } = res;
        if (status === 200) {
            setLanguage(translation);
            if (data !== undefined) {
                data.forEach(({ setting, isActive, ...obj }: any) => {
                    if (setting === 'corporate-color') {
                        const {
                            mainColor = '',
                            accent1 = '',
                            accent2 = '',
                            _id = null,
                        } = obj;
                        setColorFlag(isActive);
                        setScheme((o: any) => ({
                            ...o,
                            mainColor,
                            accent1,
                            accent2,
                            _id,
                        }));
                    }
                    if (setting === 'cover-image') {
                        setCoverFlag(isActive);
                        setCover((o: any) => ({ ...o, ...obj }));
                    }
                });
            }
        }
        setLoading((o: number) => o - 1);
    });
};
