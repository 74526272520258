import React, { useContext, useState, useEffect, useRef } from "react";
import { cloneDeep } from "lodash";

// import { postRearrange } from '../../../api/calls';
import { postRearrange } from "../../../api/postRearrange/postRearrange";
import { catcchErrorHandling } from "../../../helpers";

import languageContext from "../../../context/languageContext";

import "./slide.css";
// import './footer_bar.css';

import Item from "./Item";
import { MenuContextType, MenuContext } from "../../../context/MenuContext";

import { Slider } from "../../../@type/footerBar";
import Menu from "./Menu/Menu";
import { ARROW_SVG, BG_SVG } from "./icons";

const SlidesNav = ({
    sliders,
    index,
    presentation,
    thumbAction,
    setLoading,
    setError,
    baseUrl,
    clientID,
    extraKey,
    setCurrent,
    change,
    onDeleteItem,
    onVisibleItem,
    onAddImageSlide,
    renderCount,
    setSlides,
    thumbGenerator,
    setImageSlide2,
    activeIndex,
    setDim,
}: any) => {
    const language: any = useContext(languageContext);
    const menuS = useContext<MenuContextType | null>(MenuContext);
    // console.log('FooterBvar', menuS);

    const [locSlider, setLocSlider] = useState<Slider[]>(sliders);
    const [dragId, setDragId] = useState<any>();
    const [pos, setPos] = useState({
        x: 0,
        y: 0,
        order_no: null,
        index: null,
        _id: null,
        visible: false,
    });
    const [menuShow, setMenuShow] = useState(false);
    const horizentalContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const container = horizentalContainerRef.current;

        const handleWheel = (event: WheelEvent) => {
            setMenuShow(false);

            if (event.deltaY !== 0) {
                event.preventDefault();
                if (container !== null) {
                    container.scrollLeft += event.deltaY;
                }
            }
        };

        if (container !== null) {
            container?.addEventListener("wheel", handleWheel, { passive: false });
        }

        return () => {
            if (container !== null) {
                container?.removeEventListener("wheel", handleWheel);
            }
        };
    }, []);

    useEffect(() => {
        setTimeout(() => {
            const w = "146px";
            const h = "81px";
            const del: NodeListOf<HTMLDivElement> = document.querySelectorAll(".testThumb");

            del.forEach(element => {
                if (element.parentNode) {
                    element.parentNode.removeChild(element);
                }
            });

            const tar: NodeListOf<HTMLDivElement> = document.querySelectorAll(".ground");
            tar.forEach((element, kk) => {
                const thumbWrap = document.createElement("div");
                const cl = element.cloneNode(true);
                thumbWrap.classList.add("testThumb");
                thumbWrap.appendChild(cl);

                const di = document.createElement("div");
                di.style.width = w;
                di.style.height = h;
                di.classList.add("one");
                thumbWrap.appendChild(di);

                const sub: HTMLDivElement | null = document.getElementById(`strip_${kk + 1}`) as HTMLDivElement;
                if (sub !== null && sub.parentNode) {
                    sub.parentNode.insertBefore(thumbWrap, sub.nextSibling);
                }
            });
            // for (let kk = 0; kk <= tar.length; kk++) {
            //     if (tar[kk]) {
            //         const thumbWrap = document.createElement('div');
            //         const cl = tar[kk].cloneNode(true);
            //         thumbWrap.classList.add('testThumb');
            //         thumbWrap.appendChild(cl);
            //
            //         const dd2 = document.createElement('div');
            //         dd2.style.width = w;
            //         dd2.style.height = h;
            //         dd2.classList.add('one');
            //         thumbWrap.appendChild(dd2);
            //
            //         const subt: any = document.getElementById(`strip_${kk + 1}`);
            //         if (subt) subt.parentNode.insertBefore(thumbWrap, subt.nextSibling);
            //     }
            // }
        }, 300);
    }, [sliders, renderCount]);

    useEffect(() => setLocSlider(sliders), [sliders]);

    useEffect(() => {
        if (locSlider.length > 0) {
            // console.log('slide deleted');
            let order: any = null;
            locSlider.map(({ order_no, type }: any) => {
                if (type === "image_slide2") {
                    order = order_no;
                }
                return null;
            });
            setImageSlide2(order);
        }
    }, [locSlider, setImageSlide2]);

    const handleArrowClick = () => {
        const isActive = (menuS && menuS.navMenu) || false;
        menuS?.updateNavMenu(!isActive);
        setDim((old: any) => ({ ...old, slidemenu: old.slidemenu !== true }));
    };

    const handleDrag = (ev: any) => {
        const title = ev.currentTarget.dataset.order * 1;
        setDragId(title);
    };

    const handleDrop = (ev: any) => {
        const title = ev.currentTarget.dataset.order;
        const targetID = title * 1;
        const from = sliders[`${dragId}`];
        const copyArray = cloneDeep(sliders);
        const norslides = copyArray.filter((xx: any, index: any) => {
            // console.log(xx);
            return index !== dragId;
        });
        norslides.splice(targetID, 0, from);
        //* creaing order_no with index value
        const orderReqObj: any = [];
        const organized = norslides.map((x: any, index: any) => {
            const order_no = index + 1;
            orderReqObj.push({ _id: x._id, order_no });
            return { ...x, order_no };
        });

        const req = { presentation, slides: orderReqObj };
        const accessToken: any = thumbAction();
        accessToken.then(({ accesskey }: any) => {
            postRearrange(baseUrl, clientID, extraKey, accesskey, req)
                .then(({ status }: any) => {
                    if (status === 200) {
                        setSlides(organized);
                        setCurrent(organized[`${index}`]);
                        thumbGenerator(false, 100);
                    }
                })
                .catch((err: any) => {
                    setLoading(0);
                    const msg = catcchErrorHandling(err);
                    setError(msg);
                });
        });
    };

    const onItemAction = (event: any, index: any, _id: any, order_no: any, visible: boolean) => {
        const rect = event.target.getBoundingClientRect();
        const docW = document.documentElement.clientWidth;
        const flag = docW < rect.right + 200;
        if (_id !== pos._id) {
            setPos((old: any) => ({
                ...old,
                flag: true,
                x: flag ? rect.left - 200 : rect.left + 25,
                y: rect.y - 118,
                order_no,
                index,
                _id,
                visible,
            }));
            setMenuShow(true);
        } else {
            setMenuShow(false);
            setPos({
                x: 0,
                y: 0,
                order_no: null,
                index: null,
                _id: null,
                visible: false,
            });
        }
    };

    const isActive = (menuS && menuS.navMenu) || false;

    return (
        <div className={`dyson relative pt15 px20 pb10 flex-shink-0 cc-footer ${isActive ? "" : "collapse"}`}>
            {!isActive ? (
                <div className='flex justify-space-between cc-fstatus text-semi-bold_default'>
                    Slide {activeIndex + 1} of {sliders.length}
                </div>
            ) : null}
            <div className={`aa flex justify-space-between`}>
                <section ref={horizentalContainerRef} className='flex relative horizontal-scrollable-list'>
                    {locSlider
                        .sort((a, b) => a.order_no - b.order_no)
                        .map((slide: any, ind: number) => {
                            return (
                                <Item
                                    {...slide}
                                    activeClass={index === ind ? "active" : ""}
                                    key={`r-${ind}`}
                                    index={ind}
                                    onActiveChange={change}
                                    handleDrag={handleDrag}
                                    handleDrop={handleDrop}
                                    name={language[slide.type]}
                                    onItemAction={onItemAction}
                                    menu={{
                                        menuShow,
                                        _id: pos._id,
                                    }}
                                />
                            );
                        })}
                </section>
            </div>

            {menuShow === true && (
                <Menu
                    {...pos}
                    fn={{
                        show: setMenuShow,
                        pos: setPos,
                        add: onAddImageSlide,
                        visible: onVisibleItem,
                        del: onDeleteItem,
                    }}
                />
            )}

            <div className={`cc-fbtn ${!isActive ? "close" : "open"}`} onClick={handleArrowClick}>
                <div className='cc-fbb'>
                    <BG_SVG />
                </div>
                <div className='cc-fba'>
                    <ARROW_SVG />
                </div>
            </div>
        </div>
    );
};

export default SlidesNav;
