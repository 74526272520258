import axiosInstance from '../../lib/axios';
import urls from '../';

export const deleteBackgroundImages = async (
    base: any,
    data: any,
    client_id: any,
    extra: any,
    auth: any,
    state: any
) => {
    const { setSlides, sslides, backgroundImageList, setBackgroundImageList } = state;
    const urll = `${base}/${urls.deleteImages}`;

    return await axiosInstance(urll, {
        method: 'DELETE',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        params: data,
    }).then((response: any) => {
        const {
            status,
            data: { success },
        } = response;
        const newImageList: any = [];
        if (data.deleteAll !== undefined && data.deleteAll === true) {
            const updatingSslides: any = sslides.map((s: any) => {
                if (s.type === 'title_slide' || s.type === 'image_slide') {
                    const updatingElements =
                        s.elements !== undefined &&
                        s.elements.map((ele: any) => {
                            if (ele.element_id === 'background_image') {
                                let updateVisible = true;
                                backgroundImageList.map((bI: any) => {
                                    if (ele.draw_value.includes(bI.name) === true) {
                                        updateVisible = false;
                                    }
                                    return null;
                                });
                                return { ...ele, visible: updateVisible };
                            }
                            return ele;
                        });
                    return { ...s, elements: updatingElements };
                }
                return s;
            });
            setSlides(updatingSslides);
        } else {
            const updatingSslides: any = sslides.map((s: any) => {
                const updatingElements =
                    s.elements !== undefined &&
                    s.elements.map((ele: any) => {
                        if (ele.element_id === 'background_image' && ele.draw_value.includes(data.fileName) === true) {
                            return { ...ele, visible: false };
                        }
                        return ele;
                    });
                return { ...s, elements: updatingElements };
            });

            backgroundImageList.map((list: any) => {
                if (list.name !== data.fileName) {
                    newImageList.push(list);
                }
                return null;
            });
            setSlides(updatingSslides);
        }
        setBackgroundImageList(newImageList);
        return { status, success };
    });
};
