import axiosInstance from '../../lib/axios';
import urls from '../';

const global = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
};

export const getProductTemplate = async (base: any, client_id: any, extra: any, auth: any, data: any) => {
    const urll = `${base}/${urls.getProductTemplate}`;
    return await axiosInstance(urll, {
        method: 'get',
        headers: {
            ...global,
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        params: { ...data },
    }).then((response: any) => {
        const { status, data } = response;
        return { status, data: data.data };
    });
};
