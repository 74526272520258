// src/container/Overview/Item.tsx
import React from 'react';

import PresentationAction from './PresentationAction';
import { datFormat } from '../../../helpers';

import { ItemPropType } from './presentationType';

const PresentationItem = ({ name = '', date = '', edit, deleted, imgtype = '', imgvalue = '' }: ItemPropType) => {
    // const textLimit = 30;
    const style: any = {};
    if (imgtype === 'color') {
        style.background = imgvalue;
    }

    return (
        <div
            className="list-wrap"
            role="button"
            onClick={(e) => {
                e.preventDefault();
                edit();
            }}
        >
            <div>
                <div role="button" className="list-image" style={style}>
                    {imgtype === 'image' && imgvalue !== '' && <img src={`${imgvalue}`} alt="file" />}
                    {/* img {imgname} */}
                    {/* <svg fill="none" viewBox="0 0 24 24" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
                    <path xmlns="http://www.w3.org/2000/svg" d="M2 6C2 4.89543 2.89543 4 4 4H9C9.26522 4 9.51957 4.10536 9.70711 4.29289L11.4142 6H20C21.1046 6 22 6.89543 22 8V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V6ZM8.58579 6L4 6V18H20V8H11C10.7348 8 10.4804 7.89464 10.2929 7.70711L8.58579 6Z"></path>
                    </svg> */}
                </div>
                <div className="list-info">
                    <p>{name}</p>
                    <span>{datFormat(date)}</span>
                </div>
                <PresentationAction edit={edit} deleted={deleted} />
            </div>
        </div>
    );
};
export default React.memo(PresentationItem);
