import axiosInstance from '../../lib/axios';
import urls from '../';

export const putSaveSetting = async (base: any, client_id: any, extra: any, auth: any, data: any, set: any) => {
    const urll = `${base}/${urls.postSaveSetting}/${data._id}`;

    return await axiosInstance(urll, {
        method: 'PUT',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data,
    }).then(({ status }: any) => {
        if (status === 200) {
            set((o: any) => ({ ...o, mainColor: data.mainColor, accent1: data.accent1, accent2: data.accent2 }));
        }
        return status;
    });
};
