import axios, { AxiosResponse, AxiosError } from 'axios';

// import { VITE_PRESENTATIONTOOL_SERVER_URL } from "@/config/index";

const axiosInstance = axios.create({
    headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
    },
});

axiosInstance.interceptors.response.use(
    function (response: AxiosResponse): AxiosResponse {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        if (response !== undefined && response.data !== undefined && response.data.message !== undefined) {
            if (process.env.NODE_ENV === undefined || process.env.NODE_ENV === 'development') {
                // dev code
                console.info('CC - :', response.data.message);
            }
        }
        return response;
    },
    function (error: AxiosError): Promise<never> {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);

export default axiosInstance;
