import axiosInstance from '../../lib/axios';
import urls from '../';

export const postUploadImage = async (base: any, client_id: any, extra: any, auth: any, payload: any, set: any) => {
    const { setCover, setLoading } = set;
    const formData = new FormData();
    const { subdir, image, status } = payload;
    formData.append('image', image);
    formData.append('status', status);
    const urll = `${base}/${urls.postUploadImage}`;
    return await axiosInstance
        .post(urll, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                client_id,
                'x-extra-token': `${extra}`,
                authorization: `Bearer ${auth}`,
            },
            params: {
                subdir,
            },
        })
        .then(({ data: res, status }: any) => {
            const { data } = res;
            setCover(data);
            setLoading((o: number) => o - 1);
            return status;
        });
};
