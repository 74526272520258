import React, { CSSProperties, ReactNode } from "react";

interface PagesType {
    page: any;
    index: number;
    children: ReactNode;
}
const Pages = ({ page, index, children }: PagesType) => {
    const { _id, name } = page;
    const style: CSSProperties = { position: "relative", overflow: "hidden" };
    return (
        <div style={{ ...page.styl, width: "928px", height: "522px" }} data-slides={index} id={`slide-${index}`} data-info={`${_id}-${name}`}>
            <div className='ground' style={style} data-title={name}>
                <div className={`cc slide-${_id}`} data-title={name}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Pages;
