import React, { useRef, useState, useContext } from 'react';

import Look from '../Look/Look';
import Item from './Item';

import lookandFeelContext from '../../../../context/LookAndFeelContext';
import languageContext from '../../../../context/languageContext';

import './template.css';

const Template = ({ colorpick, onLookAndFeelChange }: any) => {
    const verticalContainerRef = useRef<HTMLDivElement>(null);
    const lookandfeel: any = useContext(lookandFeelContext);
    const { template, templateId } = lookandfeel;
    const language: any = useContext(languageContext);
    // const { select_layout } = language;
    const designTab = {
        active: 1,
        tabs: [
            { id: 1, label: 'Layout' },
            { id: 2, label: 'Colours' },
        ],
    };
    const [tabDisplay, setTabDisplay] = useState(designTab);

    return (
        <>
            <section className="c-s-p-design flex flex-column h-f">
                <div className="flex  flex-shink-0 c-s-p-tab">
                    {tabDisplay.tabs.map(({ label, id }: any) => {
                        const name = language[label] !== undefined ? language[label] : label;
                        return (
                            <div
                                key={id}
                                role="button"
                                className={`${tabDisplay.active !== id ? 'cur-pointer' : 'active'}`}
                                onClick={() => setTabDisplay((olddata: any) => ({ ...olddata, active: id }))}
                            >
                                <span>{name}</span>
                            </div>
                        );
                    })}{' '}
                </div>
                <div ref={verticalContainerRef} className="h-f overflow-hidden 0verflow-scroll-container">
                    <div className="oveflow-scroll-content relative scroll-container">
                        {tabDisplay.active === 1 ? (
                            <div className="">
                                {/* <h3 className="gotham-black fz16 fw-normal">{select_layout}</h3> */}
                                {template.map(({ _id, thumb, template_type, name }: any) => (
                                    <Item
                                        key={_id}
                                        id={_id}
                                        template_type={template_type}
                                        thumb={thumb}
                                        flag={_id === templateId}
                                        name={name}
                                    />
                                ))}
                            </div>
                        ) : (
                            <Look colorpick={colorpick} onLookAndFeelChange={onLookAndFeelChange} />
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};
export default Template;
