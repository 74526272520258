const cache_font_list = [];
async function FontFunFunction({
    font_family,
    font_woff2_src,
    font_name_woff2,
    baseUrl,
}) {
    if (cache_font_list.includes(font_family)) {
        return true;
    } else {
        if (!window.FontFace) {
            const newStyle = document.createElement("style");
            const extension = font_name_woff2.split(".").pop();
            newStyle.appendChild(
                document.createTextNode(
                    `@font-face {font-family: "${font_family}";src: url(${font_family}) format(${extension});}`
                )
            );
            document.head.appendChild(newStyle);
            cache_font_list.push(font_family);
            return true;
        } else {
            try {
                const url = encodeURI(baseUrl + font_woff2_src);
                const font = new FontFace(`${font_family}`, `url(${url})`);
                const response = await font.load();
                document.fonts.add(font);
                cache_font_list.push(font_family);
                return response;
            } catch (err) {
                console.log(`${err.code} : ${err} ${err.name}`);
            }
        }
    }
}
export default FontFunFunction;
