import React, { useState, useEffect } from "react";
import { upIcon, downIcon } from "../../helpers/IconList";
import { decimalAndCurrency, replaceCommaWithDecimal, replaceDecimalWithComma } from "./edithelp";

const EditableBox = ({
    sku = "",
    name = "",
    children = "",
    // trail = "",
    change,
    // editedWith,
    // original,
    style,
    cur = "",

    setCell,
    celvalue,
}: any) => {
    // const [ini, setinit] = useState(true);
    const [child, setChild] = useState(children || "");
    const [qtyfocus, setQtyFocus] = useState(false); // setQtyFocus
    const [unitvalue, setUnitvalue] = useState("");
    useEffect(() => {
        // console.log(typeof children, children);
        // if (celvalue !== sku) {
        //     if (!qtyfocus) {
        //         const withCur = decimalAndCurrency(children, cur);
        //         setUnitvalue(withCur);
        //     }
        //     // const ii: any = decimanTwo(children);
        // }
        if (children) {
            setChild(children);
            const withCur = decimalAndCurrency(children, cur);
            setUnitvalue(withCur)
        }
        // setUnitvalue(children);
        // console.log(ch)
        // , cur, qtyfocus, sku, celvalue
    }, [children, cur, qtyfocus, sku, celvalue]);

    function handlechange(e: any) {
        // console.log(e.target.value);
        const {
            target: { value, name = "blank" },
        } = e;

        // const regexp = /^(?:[\d-]*,?[\d-]*|[\d-]*,[\d-]*)$/;
        const regexp = /^(?:[\d-]*,?[\d-]*|[\d-]*\.[\d-]*,[\d-]*)$/;
        // const regexp = /^(?:[0-9](\.,[0-9])*?\.?,?[0-9]*)$/;
        const testreg = value.match(regexp);
        // console.log(regexp.Moleskine(value));
        if (testreg) {
            setChild(value);
            change(sku, value, name);
        } else {
            setChild(child);
        }
        // if (value.match(regexp) === null) {
        //     // console.log(value.match(regexp));
        //     // alert("Invalid input");
        //     setChild(child);
        //     return null;
        // } else {
        //     // console.log(name, value);
        //     // const val = replaceCommaWithDecimal(value);
        //     setChild(value);
        //     change(sku, value, name);
        //     // cellChange(value, name);
        // }
    }

    function up() {
        // if (editedWith === "" || editedWith === name || name === "qty") {
        const numberval = Number(replaceCommaWithDecimal(child));
        const newChild = Number(numberval) + 1;
        const newVal = replaceDecimalWithComma(newChild);
        // console.log(newChild);
        setChild(newVal);
        change(sku, newVal, name);
        // }
    }
    function down() {
        const numberval = Number(replaceCommaWithDecimal(child));
        if (numberval >= 2) {
            const newChild = numberval - 1;
            setChild(newChild);
            change(sku, newChild, name);
        }
        // }
    }
    function checkKey(e: any) {
        // e.preventDefault();
        // console.log(e.keyCode);
        if (e.keyCode === 38) up();
        else if (e.keyCode === 40) down();
    }

    function onQtyFocus() {
        setCell(sku);
        setQtyFocus(true);
    }
    function onQtyBlur() {
        // setCell(null);
        setQtyFocus(false);
    }

    return (
        <div className="flex editablebox">
            <input
                type={qtyfocus ? "text" : "text"}
                className="editablebox_input"
                value={qtyfocus ? child : unitvalue}
                onFocus={onQtyFocus}
                onChange={handlechange}
                onBlur={onQtyBlur}
                name="unit_price"
                // min={original || original === 0 ? original : child}
                min="0"
                style={style}
                onKeyDown={checkKey}
                lang="nl"
            />
            <div className="flex editablebox_action">
                <img src={upIcon} onClick={up} alt="up" />
                <img src={downIcon} onClick={down} alt="down" />
            </div>
        </div>
    );
};
export default EditableBox;
