export const BACKGROUND = 'background';
export const BACKGROUND_IMAGE = 'background_image';
export const PRESENTATION_LOGO = 'presentation_logo';
export const END_CUSTOMER_LOGO = 'end_customer_logo';
export const END_CUSTOMER_LOGO_BG = 'end_customer_logo_background';
export const TITLE_TEXT = 'title_text';
export const TITLE_TEXT_BACKGROUND = 'title_text_background';

// FULLSCREEN
// SIDEBAR -> DESIGN
export const SAVE_COLOR_LIMIT = 12;
export const SAVE_COLOR_LIMIT_MESSAGE = "You can't save more than 12 colour schemes.";
export const SAVE_SAME_COLOR_MESSAGE = 'You have already added this colour scheme.';
export const COLOR_DELETE_CONFIRM_MESSAGE = 'Are you sure you want to delete this colour scheme?';
