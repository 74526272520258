import React, { useState, useEffect } from 'react';

// import './title_bar.css';
import './title.css';
import { ITitleBar } from '../../../@type/titleBar';

import { SHARE_SVG, CLOSE_SVG, LOGO_SVG } from './icons';

const TitleBar = ({
    display,
    setDisplayOverview,
    title,
    setTitle,
    onEmailShareflag,
}: ITitleBar) => {
    const [edit, setEdit] = useState<boolean>(false);
    const [locTitle, setLocTitle] = useState(title);

    useEffect(() => setLocTitle(title), [title]);

    const editActivate = () => {
        setEdit(true);
        setTimeout(function () {
            const ele = document.getElementById('titleText');
            ele?.focus();
        }, 200);
    };

    const onLeave = () => {
        if (locTitle !== title) {
            setTitle(locTitle);
        }
        setEdit(false);
    };

    const focushere = (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.selectionStart = title?.length;
        event.target.selectionEnd = Number(title?.length);
    };

    const handleChange = ({ target }: any) => {
        if (target.value.length <= 50) setLocTitle(target.value);
    };

    return (
        <div className='flex flex-shink-0 justify-space-between px20 py15 cc-title'>
            <div className='flex'>
                <div className='flex mr30'>
                    <div className='mr10 cc-tlogo'>
                        <LOGO_SVG />
                    </div>
                    <div className='flex align-center h-f gotham-black pfc-white'>
                        {!edit ? (
                            <span className='cc-ttitle' onClick={editActivate}>
                                {locTitle}
                            </span>
                        ) : (
                            <input
                                type='text'
                                id='titleText'
                                name='title'
                                className='gotham-black cc-tinput'
                                onFocus={focushere}
                                value={locTitle}
                                onChange={handleChange}
                                onBlur={onLeave}
                            />
                        )}
                    </div>
                </div>
                <div className='flex align-center'>
                    <button
                        className='mr10 action btn-icon pfc-bright-blue'
                        onClick={() => {
                            onEmailShareflag(true);
                        }}
                    >
                        <span className='mr10'>
                            <SHARE_SVG />
                        </span>
                        <span>Flip</span>
                    </button>
                    <button
                        className='action btn-icon pfc-bright-blue'
                        onClick={() => {
                            onEmailShareflag(false);
                        }}
                    >
                        <span className='mr10'>
                            <SHARE_SVG />
                        </span>
                        <span>Share</span>
                    </button>
                </div>
            </div>
            <div>
                <div className='flex align-center h-f'>
                    <button
                        onClick={() => setDisplayOverview(!display)}
                        className='flex align-center pfc-white btn-clean'
                    >
                        <span className='mr10 gotham-book fz16'>Close</span>
                        <CLOSE_SVG />
                    </button>
                </div>
            </div>
        </div>
    );
};
export default TitleBar;
