import React, { useState, useContext, useEffect } from 'react';
// import { cloneDeep } from 'lodash'

import lookAndFeelContext from '../../../../context/LookAndFeelContext';
import languageContext from '../../../../context/languageContext';
import { deepCloneObject } from '../../../../lib/lodash';
// import settingContext from '../../../../context/SettingContext';
import lookContext from '../../../../context/LookContext';

export default function SlideElement({ colorpick, onPresentationLogoChange1, onCustomerLogoChange1 }: any) {
    const { lookFeel, element, onElementChangge }: any = useContext(lookAndFeelContext);
    const language: any = useContext(languageContext);
    // const { setting }: any = useContext(settingContext);
    const { presentationLogo, editorLogo }: any = useContext(lookContext);

    const [localElement, setLocalElement] = useState(element);
    const { font_color } = lookFeel; // font_name,

    useEffect(() => {
        const newList = deepCloneObject(element);
        let aryelems = [];
        if (!Array.isArray(newList)) {
            Object.keys(newList).forEach((eachlist) => {
                aryelems.push(newList[eachlist]);
            });
        } else {
            aryelems = newList;
        }
        setLocalElement(aryelems);
    }, [element]);

    const pickColor2 = () => {
        colorpick({ color: font_color, name: 'font_color' });
    };
    // fixing here
    return (
        <div className="pt-20 setting__element slideElement">
            {/* <h3 className="gotham-black fz16 fw-normal mb20">{language.choose_elements}</h3> */}
            <div className="mb30">
                <div className="pfc-toggle label-left toggle_small justify-space-between mb10">
                    <label className="cc_switch">
                        <input
                            type="checkbox"
                            checked={editorLogo.visible}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                onCustomerLogoChange1({ type: 'visible', value: e.target.checked });
                            }}
                        />
                        <span className="slider" />
                    </label>
                    <span className="label text-regular_default fz14">{language.end_customer_logo_check}</span>
                </div>

                <div className="pfc-toggle label-left toggle_small justify-space-between mb10">
                    <label className="cc_switch">
                        <input
                            type="checkbox"
                            checked={presentationLogo.visible}
                            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                                onPresentationLogoChange1({
                                    type: 'visible',
                                    value: target.checked,
                                });
                            }}
                        />
                        <span className="slider" />
                    </label>
                    <span className="label text-regular_default fz14">{language.presentation_logo_check}</span>
                </div>
            </div>

            {Array.isArray(localElement) &&
                localElement.length > 0 &&
                localElement.map(({ _id, label, value, element_id }: any) => {
                    const text = language[element_id] !== undefined ? language[element_id] : label;
                    let languageTranslated = text;
                    // Note: Fixed
                    // issue with ecq slide DATE and global element Date checkbox
                    if (element_id === 'date_label') languageTranslated = language['date_label_ecq'];
                    return (
                        <div className="pfc-toggle label-left toggle_small justify-space-between mb10" key={_id}>
                            <label className="cc_switch">
                                <input
                                    type="checkbox"
                                    checked={value}
                                    name={_id}
                                    onChange={() => onElementChangge({ _id, label, value })}
                                />
                                <span className="slider"></span>
                            </label>
                            <span className="label text-regular_default fz14">{languageTranslated}</span>
                        </div>
                    );
                })}
            <div className="cc2-setting__divider mt20 mb5"></div>
            <div className="flex align-center settig__element__color_pillow">
                <div className="flex align-center" onClick={pickColor2}>
                    <span
                        className="mr10"
                        style={{
                            background: `${font_color}`,
                            width: '56px',
                            height: '36px',
                            display: 'block',
                            border: '1px solid rgb(0, 0, 0)',
                        }}
                    ></span>
                    <span className="text-black flex1 fz12">
                        {language['font_color'] !== undefined ? language['font_color'] : 'Font color'}
                    </span>
                </div>
            </div>
        </div>
    );
}
