import axiosInstance from '../../lib/axios';
import urls from '../';

export const getglobalSetting = async (base: any, client_id: any, extra: any, auth: any, data: any) => {
    // data = { presentation_type, theme_id }
    const urll = `${base}/${urls.getGlobalSetting}`;
    return await axiosInstance(urll, {
        method: 'get',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        params: {
            ...data,
        },
    }).then((response: any) => {
        const { status, data } = response;
        return { status, data: data.data };
    });
};
