import axiosInstance from '../../lib/axios';
import urls from '..';

export const putPresentationUpdate = async (
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    data: any,
    presentationId: any
) => {
    const urll = `${base}/${urls.postPresentationUpdate}/${presentationId}`;
    return await axiosInstance(urll, {
        method: 'put',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data,
    }).then((response: any) => {
        return { status: response.status };
    });
};
