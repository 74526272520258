import React from 'react';

export const COLOR_SVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="37" viewBox="0 0 36 37" fill="none">
        <path
            d="M17.4996 1.80458V-0.769043C16.0882 -0.769043 14.7116 -0.619992 13.3896 -0.341762L13.9264 2.17721C15.0794 1.93376 16.2771 1.80458 17.5046 1.80458H17.4996Z"
            fill="#FF664C"
        />
        <path
            d="M17.5001 4.37782V1.8042C16.2726 1.8042 15.0749 1.93338 13.9219 2.17683L14.4586 4.6958C15.4376 4.48713 16.4564 4.37782 17.5001 4.37782Z"
            fill="#FF7C65"
        />
        <path
            d="M17.5005 6.95155V4.37793C16.4568 4.37793 15.438 4.48723 14.459 4.69591L14.9957 7.21488C15.8058 7.04595 16.6407 6.95155 17.5005 6.95155Z"
            fill="#FF917E"
        />
        <path
            d="M17.4999 9.52528V6.95166C16.6401 6.95166 15.8002 7.04109 14.9951 7.21498L15.5318 9.73395C16.168 9.59981 16.824 9.53025 17.4999 9.53025V9.52528Z"
            fill="#FFA797"
        />
        <path
            d="M17.5002 12.1039V9.53027C16.8244 9.53027 16.1634 9.59983 15.5322 9.73398L16.069 12.2529C16.5311 12.1536 17.0132 12.1039 17.5052 12.1039H17.5002Z"
            fill="#FFBCB1"
        />
        <path
            d="M17.4997 14.6776V12.104C17.0077 12.104 16.5257 12.1537 16.0635 12.2531L16.6002 14.772C16.8885 14.7124 17.1916 14.6776 17.4997 14.6776Z"
            fill="#FFD2CA"
        />
        <path
            d="M17.1373 17.2911C17.2566 17.2663 17.3759 17.2514 17.5001 17.2514V14.6777C17.192 14.6777 16.8888 14.7125 16.6006 14.7721L17.1373 17.2911Z"
            fill="#FFE7E3"
        />
        <path
            d="M8.41082 -1.41509L7.36221 -3.76514C5.73711 -3.04472 4.23128 -2.16532 2.84473 -1.16667L4.36049 0.915081C5.60293 0.0158033 6.95469 -0.769202 8.41082 -1.41509Z"
            fill="#FF6819"
        />
        <path
            d="M9.45929 0.939889L8.41068 -1.41016C6.95455 -0.764266 5.60278 0.0207375 4.36035 0.920015L5.87612 3.00177C6.97443 2.20683 8.17213 1.51125 9.45929 0.939889Z"
            fill="#FF7832"
        />
        <path
            d="M10.5078 3.28999L9.45915 0.939941C8.17199 1.51131 6.97429 2.20688 5.87598 3.00182L7.39174 5.08357C8.34593 4.39297 9.38958 3.78682 10.5078 3.28999Z"
            fill="#FF894C"
        />
        <path
            d="M11.5514 5.64497L10.5027 3.29492C9.38455 3.79176 8.34091 4.3979 7.38672 5.08851L8.90249 7.17026C9.71255 6.58399 10.5972 6.06728 11.5514 5.64497Z"
            fill="#FF9965"
        />
        <path
            d="M12.5998 7.99507L11.5512 5.64502C10.597 6.06733 9.71241 6.58404 8.90234 7.17031L10.4181 9.25207C11.089 8.76516 11.8146 8.34285 12.5998 7.99507Z"
            fill="#FFAA7E"
        />
        <path
            d="M13.6483 10.3452L12.5997 7.99512C11.8145 8.3429 11.0839 8.76522 10.418 9.25212L11.9337 11.3339C12.4605 10.9513 13.032 10.6184 13.6483 10.3452Z"
            fill="#FFBA97"
        />
        <path
            d="M14.6968 12.7001L13.6482 10.3501C13.0319 10.6234 12.4554 10.9562 11.9336 11.3388L13.4494 13.4206C13.832 13.1423 14.2495 12.8989 14.6968 12.7001Z"
            fill="#FFCBB1"
        />
        <path
            d="M15.7404 15.0502L14.6918 12.7002C14.2445 12.8989 13.8271 13.1424 13.4395 13.4206L14.9552 15.5024C15.1938 15.3285 15.4572 15.1745 15.7404 15.0502Z"
            fill="#FFDBCA"
        />
        <path
            d="M16.4708 17.5842C16.5702 17.5146 16.6746 17.45 16.7889 17.4003L15.7403 15.0503C15.457 15.1745 15.1986 15.3285 14.9551 15.5024L16.4708 17.5842Z"
            fill="#FFECE3"
        />
        <path
            d="M13.3899 -0.336987L12.8532 -2.85596C11.2927 -2.52308 9.80674 -2.03617 8.40527 -1.41513L9.45389 0.934919C10.6913 0.383429 12.0083 -0.0438519 13.3849 -0.336987H13.3899Z"
            fill="#FF6232"
        />
        <path
            d="M13.9268 2.18206L13.39 -0.336914C12.0085 -0.0437795 10.6964 0.38847 9.45898 0.934992L10.5076 3.28504C11.586 2.80807 12.7291 2.43047 13.9268 2.17709V2.18206Z"
            fill="#FF754C"
        />
        <path
            d="M14.4588 4.69622L13.9221 2.17725C12.7244 2.43063 11.5764 2.80823 10.5029 3.2852L11.5515 5.64021C12.466 5.2328 13.44 4.91483 14.4588 4.69622Z"
            fill="#FF8865"
        />
        <path
            d="M14.9948 7.21477L14.4581 4.6958C13.4393 4.91441 12.4652 5.23239 11.5508 5.63979L12.5994 7.98984C13.3548 7.65696 14.1549 7.39363 14.9948 7.21477Z"
            fill="#FF9C7E"
        />
        <path
            d="M15.5318 9.73381L14.995 7.21484C14.1551 7.39371 13.355 7.65703 12.5996 7.98991L13.6482 10.34C14.2396 10.0766 14.8708 9.86796 15.5318 9.72885V9.73381Z"
            fill="#FFAF97"
        />
        <path
            d="M16.0687 12.2529L15.532 9.73389C14.871 9.873 14.2398 10.0817 13.6484 10.345L14.6971 12.7C15.1294 12.5062 15.5866 12.3572 16.0687 12.2578V12.2529Z"
            fill="#FFC2B1"
        />
        <path
            d="M16.6008 14.7719L16.064 12.2529C15.582 12.3573 15.1247 12.5063 14.6924 12.6951L15.741 15.0452C16.0143 14.9259 16.2976 14.8315 16.6008 14.7669V14.7719Z"
            fill="#FFD6CA"
        />
        <path
            d="M16.7888 17.4003C16.8982 17.3506 17.0125 17.3159 17.1367 17.2861L16.6 14.7671C16.2968 14.8317 16.0086 14.9261 15.7402 15.0453L16.7888 17.3954V17.4003Z"
            fill="#FFE9E3"
        />
        <path
            d="M2.84939 -1.1619L1.33363 -3.24365C-0.251719 -2.09099 -1.67803 -0.799208 -2.94531 0.596908L-1.03197 2.32094C0.111071 1.054 1.40817 -0.118539 2.84442 -1.1619H2.84939Z"
            fill="#FF7900"
        />
        <path
            d="M4.36481 0.920131L2.84905 -1.16162C1.41279 -0.118261 0.115694 1.05428 -1.02734 2.32122L0.886002 4.04524C1.91474 2.90748 3.07268 1.85916 4.35984 0.920131H4.36481Z"
            fill="#FF8619"
        />
        <path
            d="M5.87535 3.00656L4.35958 0.924805C3.07242 1.85886 1.90951 2.91216 0.885742 4.04992L2.79909 5.77395C3.70855 4.76536 4.73231 3.84125 5.87535 3.01153V3.00656Z"
            fill="#FF9332"
        />
        <path
            d="M7.39184 5.08859L5.87607 3.00684C4.73303 3.83656 3.70927 4.76564 2.7998 5.76926L4.71315 7.49329C5.50334 6.61885 6.39789 5.809 7.38687 5.08859H7.39184Z"
            fill="#FFA14C"
        />
        <path
            d="M8.90237 7.17013L7.38661 5.08838C6.39266 5.80879 5.50308 6.61367 4.71289 7.49308L6.62624 9.2171C7.29715 8.47185 8.05752 7.78621 8.90237 7.1751V7.17013Z"
            fill="#FFAE65"
        />
        <path
            d="M10.4179 9.25216L8.90211 7.17041C8.05726 7.78152 7.30186 8.46716 6.62598 9.21242L8.53932 10.9364C9.09096 10.3253 9.71715 9.75894 10.4129 9.25216H10.4179Z"
            fill="#FFBB7E"
        />
        <path
            d="M11.9343 11.3386L10.4185 9.25684C9.72275 9.76361 9.09656 10.325 8.54492 10.9411L10.4583 12.6651C10.8956 12.1832 11.3826 11.7361 11.9293 11.3436L11.9343 11.3386Z"
            fill="#FFC897"
        />
        <path
            d="M13.4448 13.4206L11.929 11.3389C11.3824 11.7363 10.8904 12.1785 10.458 12.6605L12.3714 14.3845C12.6894 14.0317 13.0472 13.7088 13.4448 13.4206Z"
            fill="#FFD6B1"
        />
        <path
            d="M14.9603 15.5022L13.4446 13.4204C13.047 13.7086 12.6892 14.0315 12.3711 14.3843L14.2844 16.1083C14.4832 15.8897 14.7069 15.686 14.9554 15.5022H14.9603Z"
            fill="#FFE3CA"
        />
        <path
            d="M16.2034 17.8276C16.2829 17.7382 16.3724 17.6587 16.4767 17.5842L14.961 15.5024C14.7125 15.6813 14.4888 15.885 14.29 16.1086L16.2034 17.8326V17.8276Z"
            fill="#FFF0E3"
        />
        <path
            d="M0.890802 4.0502L-1.02254 2.32617C-2.21031 3.64776 -3.23407 5.05878 -4.08887 6.53936L-1.85746 7.82617C-1.09212 6.49961 -0.17272 5.23268 0.890802 4.0502Z"
            fill="#FF9319"
        />
        <path
            d="M2.80419 5.76944L0.890839 4.04541C-0.177653 5.22789 -1.09208 6.49482 -1.85742 7.82138L0.373987 9.10819C1.05484 7.93565 1.86491 6.8128 2.80916 5.76447L2.80419 5.76944Z"
            fill="#FF9F32"
        />
        <path
            d="M4.71765 7.49307L2.80431 5.76904C1.86006 6.81737 1.04999 7.93526 0.369141 9.11277L2.60055 10.3996C3.19195 9.38106 3.89765 8.40229 4.71765 7.49307Z"
            fill="#FFAB4C"
        />
        <path
            d="M6.63104 9.21719L4.71769 7.49316C3.89769 8.40238 3.19198 9.38115 2.60059 10.3997L4.83199 11.6865C5.33394 10.817 5.93527 9.99226 6.63104 9.21719Z"
            fill="#FFB765"
        />
        <path
            d="M8.54442 10.9413L6.63107 9.21729C5.93531 9.99235 5.33397 10.8221 4.83203 11.6866L7.06344 12.9734C7.47593 12.2579 7.9729 11.5773 8.54442 10.9363V10.9413Z"
            fill="#FFC27E"
        />
        <path
            d="M10.4578 12.6606L8.54446 10.9365C7.96797 11.5725 7.47596 12.2581 7.06348 12.9736L9.29489 14.2604C9.61792 13.6989 10.0105 13.1624 10.4628 12.6606H10.4578Z"
            fill="#FFCE97"
        />
        <path
            d="M12.3762 14.3842L10.4628 12.6602C10.0106 13.162 9.62292 13.6985 9.29492 14.26L11.5263 15.5468C11.7649 15.1394 12.0482 14.7469 12.3762 14.3792V14.3842Z"
            fill="#FFDAB1"
        />
        <path
            d="M14.2895 16.1083L12.3762 14.3843C12.0482 14.7519 11.7649 15.1395 11.5264 15.5518L13.7578 16.8387C13.9069 16.5803 14.0858 16.3369 14.2895 16.1083Z"
            fill="#FFE6CA"
        />
        <path
            d="M15.9843 18.1256C16.044 18.0212 16.1136 17.9219 16.198 17.8324L14.2847 16.1084C14.076 16.3369 13.902 16.5804 13.7529 16.8388L15.9843 18.1256Z"
            fill="#FFF2E3"
        />
        <path
            d="M0.374073 9.11298L-1.85734 7.82617C-2.65249 9.20738 -3.28862 10.6333 -3.76074 12.094L-1.31067 12.889C-0.888238 11.6021 -0.331629 10.3352 0.374073 9.11298Z"
            fill="#FFA832"
        />
        <path
            d="M2.6056 10.3996L0.374191 9.11279C-0.33151 10.335 -0.89309 11.597 -1.31055 12.8888L1.13953 13.6837C1.50729 12.5609 1.99432 11.4628 2.6056 10.3996Z"
            fill="#FFB34C"
        />
        <path
            d="M4.83713 11.6867L2.60572 10.3999C1.99444 11.4631 1.50741 12.5611 1.13965 13.684L3.58973 14.4789C3.90282 13.525 4.31531 12.591 4.83713 11.6867Z"
            fill="#FFBE65"
        />
        <path
            d="M7.06377 12.9782L4.83236 11.6914C4.31054 12.5957 3.89805 13.5297 3.58496 14.4836L6.03504 15.2786C6.29346 14.4936 6.63141 13.7235 7.06377 12.9782Z"
            fill="#FFC87E"
        />
        <path
            d="M9.2953 14.2653L7.06389 12.9785C6.63649 13.7238 6.29358 14.4939 6.03516 15.2789L8.48523 16.0738C8.68899 15.4577 8.95239 14.8516 9.29033 14.2653H9.2953Z"
            fill="#FFD397"
        />
        <path
            d="M11.5267 15.5519L9.29533 14.2651C8.95739 14.8514 8.68902 15.4575 8.49023 16.0736L10.9403 16.8686C11.0894 16.4164 11.2832 15.9792 11.5267 15.5519Z"
            fill="#FFDEB1"
        />
        <path
            d="M13.7583 16.8386L11.5269 15.5518C11.2784 15.979 11.0846 16.4212 10.9404 16.8684L13.3905 17.6633C13.48 17.3801 13.6042 17.1069 13.7583 16.8386Z"
            fill="#FFE8CA"
        />
        <path
            d="M15.8397 18.4586C15.8745 18.3443 15.9242 18.235 15.9888 18.1257L13.7574 16.8389C13.6033 17.1072 13.4791 17.3804 13.3896 17.6636L15.8397 18.4586Z"
            fill="#FFF3E3"
        />
        <path
            d="M1.14489 13.6836L-1.30519 12.8887C-1.74253 14.2301 -2.0258 15.5815 -2.16992 16.9329L0.389489 17.2012C0.513732 16.0287 0.762219 14.8512 1.13992 13.6836H1.14489Z"
            fill="#FFC94C"
        />
        <path
            d="M3.59015 14.4839L1.14008 13.689C0.762378 14.8565 0.513892 16.034 0.389648 17.2066L2.94906 17.4749C3.05342 16.4762 3.26712 15.4776 3.59015 14.4839Z"
            fill="#FFD065"
        />
        <path
            d="M6.03941 15.2788L3.58934 14.4839C3.26631 15.4776 3.05758 16.4762 2.94824 17.4749L5.50765 17.7431C5.59711 16.9234 5.76608 16.0986 6.03445 15.2788H6.03941Z"
            fill="#FFD87E"
        />
        <path
            d="M8.48956 16.0737L6.03949 15.2788C5.77609 16.0936 5.60215 16.9184 5.5127 17.7431L8.07211 18.0114C8.14168 17.3655 8.27587 16.7147 8.48459 16.0737H8.48956Z"
            fill="#FFDF97"
        />
        <path
            d="M10.9397 16.8687L8.48964 16.0737C8.28091 16.7147 8.14672 17.3655 8.07715 18.0114L10.6366 18.2797C10.6863 17.8077 10.7857 17.3357 10.9397 16.8687Z"
            fill="#FFE7B1"
        />
        <path
            d="M13.3899 17.6636L10.9399 16.8687C10.7858 17.3357 10.6864 17.8077 10.6367 18.2797L13.1961 18.548C13.2259 18.2548 13.2906 17.9567 13.385 17.6636H13.3899Z"
            fill="#FFEECA"
        />
        <path
            d="M15.7607 18.8162C15.7756 18.697 15.8004 18.5778 15.8352 18.4585L13.3851 17.6636C13.2907 17.9567 13.2261 18.2548 13.1963 18.5479L15.7557 18.8162H15.7607Z"
            fill="#FFF6E3"
        />
        <path
            d="M0.393983 17.2014L-2.16543 16.9331C-2.31452 18.3342 -2.30955 19.7204 -2.16543 21.0668L0.393983 20.7985C0.26974 19.626 0.26974 18.4236 0.393983 17.2014Z"
            fill="#FFDE4C"
        />
        <path
            d="M2.95818 17.47L0.398766 17.2017C0.269553 18.4189 0.274523 19.6262 0.398766 20.7988L2.95818 20.5305C2.85381 19.5318 2.85381 18.5083 2.95818 17.4749V17.47Z"
            fill="#FFE365"
        />
        <path
            d="M5.51846 17.738L2.95905 17.4697C2.84972 18.5081 2.85469 19.5316 2.95905 20.5253L5.51846 20.257C5.43398 19.4372 5.42901 18.5926 5.51846 17.738Z"
            fill="#FFE77E"
        />
        <path
            d="M8.07768 18.0115L5.51826 17.7432C5.42881 18.5977 5.42881 19.4424 5.51826 20.2621L8.07768 19.9938C8.0081 19.348 8.0081 18.6872 8.07768 18.0115Z"
            fill="#FFEC97"
        />
        <path
            d="M10.6419 18.2795L8.08246 18.0112C8.01288 18.682 8.01288 19.3477 8.08246 19.9936L10.6419 19.7253C10.5922 19.2533 10.5922 18.7714 10.6419 18.2845V18.2795Z"
            fill="#FFF1B1"
        />
        <path
            d="M13.2012 18.5481L10.6418 18.2798C10.5921 18.7667 10.5921 19.2536 10.6418 19.7206L13.2012 19.4523C13.1714 19.1592 13.1714 18.8561 13.2012 18.5481Z"
            fill="#FFF5CA"
        />
        <path
            d="M15.7605 19.1838C15.7456 19.0646 15.7456 18.9404 15.7605 18.8161L13.2011 18.5479C13.1713 18.8559 13.1713 19.159 13.2011 19.4521L15.7605 19.1838Z"
            fill="#FFFAE3"
        />
        <path
            d="M0.394372 20.7983L-2.16504 21.0666C-2.01595 22.4677 -1.7277 23.8191 -1.31025 25.1109L1.13983 24.316C0.777041 23.1931 0.523585 22.0156 0.394372 20.7983Z"
            fill="#FFEB65"
        />
        <path
            d="M2.95882 20.5254L0.399414 20.7937C0.528627 22.0109 0.782083 23.1884 1.14487 24.3113L3.59495 23.5164C3.28683 22.5624 3.07313 21.5638 2.96379 20.5254H2.95882Z"
            fill="#FFEB65"
        />
        <path
            d="M5.5184 20.2568L2.95898 20.5251C3.06832 21.5635 3.28202 22.5622 3.59014 23.5161L6.04022 22.7212C5.78676 21.9361 5.60785 21.1114 5.5184 20.2568Z"
            fill="#FFEF7E"
        />
        <path
            d="M8.07699 19.9888L5.51758 20.2571C5.60703 21.1116 5.78594 21.9364 6.0394 22.7214L8.48948 21.9264C8.29069 21.3104 8.15154 20.6595 8.07699 19.9888Z"
            fill="#FFF297"
        />
        <path
            d="M10.6414 19.7202L8.08203 19.9885C8.15161 20.6592 8.29076 21.3101 8.49452 21.9262L10.9446 21.1312C10.8005 20.6791 10.6961 20.2071 10.6464 19.7202H10.6414Z"
            fill="#FFF5B1"
        />
        <path
            d="M13.201 19.4521L10.6416 19.7204C10.6913 20.2073 10.7957 20.6793 10.9398 21.1315L13.3899 20.3365C13.3004 20.0533 13.2358 19.7602 13.201 19.4521Z"
            fill="#FFF8CA"
        />
        <path
            d="M15.84 19.5413C15.8052 19.427 15.7754 19.3078 15.7655 19.1836L13.2061 19.4519C13.2359 19.7599 13.3005 20.0531 13.3949 20.3363L15.845 19.5413H15.84Z"
            fill="#FFFBE3"
        />
        <path
            d="M-1.30578 25.1113L-3.75586 25.9063C-3.26386 27.4216 -2.62276 28.8475 -1.85742 30.1741L0.373986 28.8873C-0.301898 27.7148 -0.868446 26.4528 -1.30578 25.1113Z"
            fill="#FFF132"
        />
        <path
            d="M1.14441 24.311L-1.30566 25.106C-0.868328 26.4474 -0.306749 27.7094 0.374104 28.8819L2.60551 27.5951C2.01909 26.5716 1.52708 25.4736 1.14441 24.311Z"
            fill="#FFF24C"
        />
        <path
            d="M3.58973 23.5161L1.13965 24.3111C1.51735 25.4786 2.00935 26.5766 2.60075 27.5952L4.83216 26.3083C4.33022 25.4389 3.91276 24.5048 3.58973 23.5161Z"
            fill="#FFF24C"
        />
        <path
            d="M6.03992 22.7212L3.58984 23.5161C3.91288 24.5098 4.33033 25.4389 4.83228 26.3084L7.06368 25.0216C6.6512 24.3061 6.30829 23.536 6.03992 22.7212Z"
            fill="#FFF67E"
        />
        <path
            d="M8.49012 21.9263L6.04004 22.7212C6.30343 23.536 6.65132 24.3061 7.0638 25.0216L9.29521 23.7348C8.97218 23.1733 8.69884 22.5672 8.49012 21.9263Z"
            fill="#FFF897"
        />
        <path
            d="M10.9403 21.1313L8.49023 21.9263C8.69896 22.5672 8.9723 23.1734 9.29533 23.7348L11.5267 22.448C11.2882 22.0406 11.0944 21.5984 10.9403 21.1313Z"
            fill="#FFF9B1"
        />
        <path
            d="M13.3905 20.3364L10.9404 21.1314C11.0945 21.5984 11.2883 22.0406 11.5269 22.448L13.7583 21.1612C13.6092 20.9028 13.4849 20.6296 13.3905 20.3364Z"
            fill="#FFFBCA"
        />
        <path
            d="M15.9839 19.8744C15.9243 19.7701 15.8746 19.6607 15.8348 19.5415L13.3848 20.3364C13.4792 20.6296 13.6034 20.9078 13.7525 21.1612L15.9839 19.8744Z"
            fill="#FFFDE3"
        />
        <path
            d="M-1.85746 30.1738L-4.08887 31.4606C-3.19929 33.0008 -2.17055 34.4069 -1.02751 35.6788L0.885833 33.9548C-0.137932 32.817 -1.0623 31.5501 -1.85746 30.1738Z"
            fill="#DFEF19"
        />
        <path
            d="M0.373987 28.8819L-1.85742 30.1687C-1.06226 31.5499 -0.137895 32.8119 0.88587 33.9497L2.79922 32.2256C1.89472 31.2171 1.07969 30.0992 0.373987 28.877V28.8819Z"
            fill="#E3F132"
        />
        <path
            d="M2.60543 27.5952L0.374023 28.882C1.07973 30.1042 1.89476 31.2221 2.79925 32.2307L4.7126 30.5067C3.92241 29.6273 3.21671 28.6584 2.60046 27.5952H2.60543Z"
            fill="#E6F24C"
        />
        <path
            d="M4.83688 26.3086L2.60547 27.5954C3.21675 28.6586 3.92742 29.6275 4.7176 30.5069L6.63095 28.7829C5.96004 28.0376 5.3587 27.2128 4.83688 26.3086Z"
            fill="#EAF465"
        />
        <path
            d="M7.06344 25.0215L4.83203 26.3083C5.35385 27.2125 5.95519 28.0373 6.6261 28.7826L8.53945 27.0585C7.98781 26.4424 7.49084 25.7618 7.05847 25.0215H7.06344Z"
            fill="#EDF67E"
        />
        <path
            d="M9.29489 23.7349L7.06348 25.0218C7.49087 25.767 7.98785 26.4477 8.54446 27.0588L10.4578 25.3348C10.0254 24.8528 9.63283 24.3162 9.29489 23.73V23.7349Z"
            fill="#F1F897"
        />
        <path
            d="M11.5263 22.4482L9.29492 23.7351C9.63286 24.3213 10.0255 24.8529 10.4578 25.3398L12.3712 23.6158C12.0531 23.2631 11.7698 22.8755 11.5263 22.4482Z"
            fill="#F4F9B1"
        />
        <path
            d="M13.7578 21.1611L11.5264 22.4479C11.7699 22.8752 12.0581 23.2628 12.3712 23.6155L14.2846 21.8915C14.0858 21.6729 13.9069 21.4245 13.7528 21.1611H13.7578Z"
            fill="#F8FBCA"
        />
        <path
            d="M16.2029 20.1677C16.1234 20.0783 16.0489 19.9789 15.9892 19.8696L13.7578 21.1564C13.9119 21.4247 14.0908 21.6682 14.2896 21.8868L16.2029 20.1628V20.1677Z"
            fill="#FBFDE3"
        />
        <path
            d="M-1.0222 35.6738L-2.93555 37.3979C-1.62354 38.8536 -0.192256 40.1404 1.33345 41.2484L2.84922 39.1666C1.46763 38.163 0.170534 36.9954 -1.0222 35.6788V35.6738Z"
            fill="#BADF00"
        />
        <path
            d="M0.890885 33.9497L-1.02246 35.6737C0.165305 36.9953 1.46737 38.1579 2.84896 39.1615L4.36473 37.0798C3.12726 36.1755 1.95938 35.1322 0.895855 33.9497H0.890885Z"
            fill="#C1E219"
        />
        <path
            d="M2.80495 32.2261L0.891602 33.9501C1.96009 35.1326 3.12301 36.1759 4.36047 37.0802L5.87624 34.9984C4.77793 34.1985 3.7492 33.2794 2.80495 32.231V32.2261Z"
            fill="#C8E532"
        />
        <path
            d="M4.71803 30.5068L2.80469 32.2309C3.74894 33.2792 4.77767 34.2033 5.87598 34.9983L7.39175 32.9165C6.43756 32.2209 5.54301 31.421 4.723 30.5068H4.71803Z"
            fill="#CEE84C"
        />
        <path
            d="M6.63112 28.7827L4.71777 30.5067C5.53778 31.416 6.43233 32.2208 7.38652 32.9164L8.90229 30.8347C8.09222 30.2434 7.33185 29.5628 6.63112 28.7877V28.7827Z"
            fill="#D5EB65"
        />
        <path
            d="M8.5442 27.0586L6.63086 28.7826C7.32662 29.5577 8.09196 30.2384 8.90203 30.8296L10.4178 28.7478C9.75185 28.2609 9.12069 27.6995 8.54918 27.0586H8.5442Z"
            fill="#DCEF7E"
        />
        <path
            d="M10.4583 25.3398L8.54492 27.0639C9.12141 27.6998 9.7476 28.2662 10.4135 28.7531L11.9293 26.6714C11.4025 26.2888 10.9105 25.8466 10.4583 25.3448V25.3398Z"
            fill="#E3F297"
        />
        <path
            d="M12.3762 23.6157L10.4629 25.3398C10.9151 25.8416 11.4071 26.2837 11.9339 26.6663L13.4497 24.5846C13.067 24.3063 12.7092 23.9834 12.3762 23.6207V23.6157Z"
            fill="#E9F5B1"
        />
        <path
            d="M14.2893 21.8916L12.376 23.6156C12.704 23.9833 13.0668 24.3013 13.4494 24.5795L14.9652 22.4977C14.7267 22.3239 14.4981 22.1201 14.2943 21.8916H14.2893Z"
            fill="#F0F8CA"
        />
        <path
            d="M16.4709 20.4158C16.3764 20.3463 16.282 20.2618 16.1975 20.1724L14.2842 21.8964C14.4929 22.1249 14.7165 22.3286 14.9551 22.5025L16.4709 20.4208V20.4158Z"
            fill="#F7FBE3"
        />
        <path
            d="M5.87612 34.9932L4.36035 37.0749C5.64751 38.0139 7.00425 38.789 8.40571 39.415L9.45433 37.065C8.21686 36.5135 7.01419 35.8229 5.87612 34.9932Z"
            fill="#8CD232"
        />
        <path
            d="M7.39174 32.9116L5.87598 34.9934C7.01901 35.8231 8.21672 36.5087 9.45418 37.0652L10.5028 34.7151C9.42436 34.2332 8.38072 33.637 7.39174 32.9166V32.9116Z"
            fill="#9AD84C"
        />
        <path
            d="M8.90249 30.8296L7.38672 32.9113C8.38066 33.6318 9.42431 34.2329 10.4978 34.7099L11.5464 32.3598C10.632 31.9524 9.74237 31.4407 8.89752 30.8296H8.90249Z"
            fill="#A8DD65"
        />
        <path
            d="M10.4181 28.7432L8.90234 30.8249C9.7472 31.436 10.6318 31.9478 11.5512 32.3552L12.5998 30.0051C11.8444 29.6673 11.1139 29.2499 10.4181 28.7432Z"
            fill="#B7E37E"
        />
        <path
            d="M11.9337 26.6612L10.418 28.743C11.1137 29.2497 11.8443 29.6671 12.5997 30.0049L13.6483 27.6499C13.0569 27.3866 12.4804 27.0537 11.9337 26.6562V26.6612Z"
            fill="#C5E897"
        />
        <path
            d="M13.4445 24.5796L11.9287 26.6613C12.4754 27.0588 13.0519 27.3867 13.6433 27.655L14.6919 25.305C14.2595 25.1112 13.8421 24.8727 13.4445 24.5846V24.5796Z"
            fill="#D3EEB1"
        />
        <path
            d="M14.9601 22.4976L13.4443 24.5794C13.8419 24.8676 14.2594 25.111 14.6917 25.2998L15.7404 22.9448C15.472 22.8256 15.2086 22.6715 14.9601 22.4927V22.4976Z"
            fill="#E1F3CA"
        />
        <path
            d="M16.7889 20.595C16.6796 20.5453 16.5752 20.4857 16.4708 20.4111L14.9551 22.4929C15.2036 22.6717 15.467 22.8258 15.7353 22.945L16.7839 20.59L16.7889 20.595Z"
            fill="#EFF9E3"
        />
        <path
            d="M10.5076 34.71L9.45898 37.06C10.7461 37.6314 12.0631 38.0586 13.39 38.3418L13.9268 35.8229C12.7738 35.5745 11.6258 35.2068 10.5076 34.71Z"
            fill="#75D14C"
        />
        <path
            d="M11.5515 32.3551L10.5029 34.7051C11.6211 35.2019 12.7691 35.5746 13.9221 35.818L14.4588 33.2991C13.4798 33.0904 12.5057 32.7774 11.5515 32.3501V32.3551Z"
            fill="#88D865"
        />
        <path
            d="M12.5994 30.0049L11.5508 32.3549C12.505 32.7772 13.479 33.0952 14.4581 33.3039L14.9948 30.7849C14.1847 30.611 13.3846 30.3527 12.5994 30.0049Z"
            fill="#9CDE7E"
        />
        <path
            d="M13.6482 27.65L12.5996 30C13.3848 30.3478 14.185 30.6062 14.995 30.7801L15.5318 28.2611C14.8956 28.127 14.2645 27.9232 13.6482 27.645V27.65Z"
            fill="#AFE597"
        />
        <path
            d="M14.6971 25.2998L13.6484 27.6499C14.2647 27.9231 14.8958 28.1268 15.532 28.2659L16.0687 25.747C15.6065 25.6476 15.1443 25.4985 14.6971 25.2998Z"
            fill="#C2EBB1"
        />
        <path
            d="M15.741 22.9497L14.6924 25.2998C15.1397 25.4985 15.6018 25.6475 16.064 25.7469L16.6008 23.2279C16.3125 23.1683 16.0243 23.0739 15.741 22.9497Z"
            fill="#D6F2CA"
        />
        <path
            d="M17.1367 20.709C17.0175 20.6842 16.9032 20.6444 16.7888 20.5947L15.7402 22.9448C16.0235 23.069 16.3118 23.1634 16.6 23.223L17.1367 20.704V20.709Z"
            fill="#E9F8E3"
        />
        <path
            d="M13.9264 35.8179L13.3896 38.3368C14.7712 38.63 16.1429 38.7691 17.4996 38.7741V36.2004C16.3218 36.2004 15.1241 36.0762 13.9214 35.8228L13.9264 35.8179Z"
            fill="#4CCC4C"
        />
        <path
            d="M14.4586 33.2988L13.9219 35.8178C15.1196 36.0712 16.3173 36.1954 17.5001 36.1954V33.6218C16.4962 33.6218 15.4774 33.5174 14.4586 33.2988Z"
            fill="#65D365"
        />
        <path
            d="M14.9957 30.7852L14.459 33.3041C15.4778 33.5227 16.4966 33.6271 17.5005 33.6271V31.0534C16.6755 31.0534 15.8356 30.964 14.9957 30.7901V30.7852Z"
            fill="#7EDA7E"
        />
        <path
            d="M15.5318 28.2661L14.9951 30.7851C15.835 30.9639 16.6749 31.0484 17.4999 31.0484V28.4748C16.8488 28.4748 16.1928 28.4052 15.5318 28.2661Z"
            fill="#97E197"
        />
        <path
            d="M16.069 25.7471L15.5322 28.266C16.1932 28.4052 16.8542 28.4747 17.5002 28.4747V25.9011C17.0281 25.9011 16.5461 25.8514 16.064 25.7471H16.069Z"
            fill="#B1E9B1"
        />
        <path
            d="M16.6002 23.228L16.0635 25.747C16.5455 25.8513 17.0226 25.901 17.4997 25.901V23.3274C17.2015 23.3274 16.9034 23.2976 16.6002 23.233V23.228Z"
            fill="#CAF0CA"
        />
        <path
            d="M17.5001 20.7487C17.3808 20.7487 17.2566 20.7338 17.1373 20.709L16.6006 23.228C16.9037 23.2925 17.2019 23.3224 17.5001 23.3224V20.7487Z"
            fill="#E3F7E3"
        />
        <path
            d="M17.5001 36.1954V38.769C18.9115 38.769 20.2881 38.62 21.6101 38.3417L21.0733 35.8228C19.9203 36.0662 18.7226 36.1954 17.4951 36.1954H17.5001Z"
            fill="#4CD183"
        />
        <path
            d="M17.5 33.6222V36.1958C18.7275 36.1958 19.9252 36.0666 21.0782 35.8232L20.5415 33.3042C19.5624 33.5129 18.5436 33.6222 17.5 33.6222Z"
            fill="#65D794"
        />
        <path
            d="M17.5 31.0436V33.6172C18.5436 33.6172 19.5624 33.5079 20.5415 33.2992L20.0047 30.7803C19.1947 30.9492 18.3598 31.0436 17.5 31.0436Z"
            fill="#7EDEA6"
        />
        <path
            d="M17.5 28.4699V31.0435C18.3598 31.0435 19.1996 30.9541 20.0047 30.7802L19.468 28.2612C18.8319 28.3954 18.1759 28.4649 17.5 28.4649V28.4699Z"
            fill="#97E4B7"
        />
        <path
            d="M17.5001 25.8961V28.4697C18.176 28.4697 18.8369 28.4002 19.4681 28.266L18.9314 25.7471C18.4692 25.8464 17.9871 25.8961 17.4951 25.8961H17.5001Z"
            fill="#B1EBC8"
        />
        <path
            d="M17.5 23.3224V25.8961C17.992 25.8961 18.4741 25.8464 18.9363 25.747L18.3995 23.228C18.1113 23.2876 17.8081 23.3224 17.5 23.3224Z"
            fill="#CAF1DA"
        />
        <path
            d="M17.8628 20.709C17.7435 20.7338 17.6242 20.7487 17.5 20.7487V23.3224C17.8081 23.3224 18.1113 23.2876 18.3995 23.228L17.8628 20.709Z"
            fill="#E3F8EB"
        />
        <path
            d="M21.0781 35.8178L21.6149 38.3368C22.9964 38.0437 24.3084 37.6114 25.5459 37.0649L24.4973 34.7148C23.4189 35.1918 22.2758 35.5694 21.0781 35.8228V35.8178Z"
            fill="#4CD7BC"
        />
        <path
            d="M20.541 33.2989L21.0777 35.8179C22.2755 35.5645 23.4235 35.1869 24.4969 34.7099L23.4483 32.3599C22.5339 32.7673 21.5598 33.0852 20.541 33.3039V33.2989Z"
            fill="#65DDC5"
        />
        <path
            d="M20.0049 30.7848L20.5416 33.3038C21.5604 33.0852 22.5345 32.7672 23.4489 32.3598L22.4003 30.0098C21.6449 30.3426 20.8448 30.606 20.0049 30.7848Z"
            fill="#7EE2CF"
        />
        <path
            d="M19.4727 28.2659L20.0094 30.7849C20.8493 30.606 21.6494 30.3427 22.4048 30.0098L21.3562 27.6548C20.7648 27.9181 20.1336 28.1268 19.4727 28.2659Z"
            fill="#97E8D8"
        />
        <path
            d="M18.9365 25.7469L19.4733 28.2658C20.1342 28.1267 20.7654 27.9181 21.3568 27.6547L20.3082 25.3047C19.8758 25.4985 19.4186 25.6475 18.9365 25.7469Z"
            fill="#B1EEE1"
        />
        <path
            d="M18.3994 23.2279L18.9361 25.7469C19.4182 25.6426 19.8754 25.4935 20.3078 25.3047L19.2592 22.9497C18.9858 23.0689 18.7026 23.1633 18.3994 23.2279Z"
            fill="#CAF3EB"
        />
        <path
            d="M18.2102 20.5947C18.1009 20.6444 17.9865 20.6792 17.8623 20.709L18.399 23.228C18.7022 23.1634 18.9904 23.069 19.2588 22.9497L18.2102 20.5947Z"
            fill="#E3F9F4"
        />
        <path
            d="M25.5459 37.0599L26.5945 39.41C28.0506 38.7641 29.4024 37.9791 30.6448 37.0798L29.1291 34.998C28.0308 35.793 26.8331 36.4886 25.5459 37.0599Z"
            fill="#32BFD9"
        />
        <path
            d="M24.4971 34.7101L25.5457 37.0601C26.8328 36.4888 28.0305 35.7932 29.1289 34.9983L27.6131 32.9165C26.6589 33.6071 25.6153 34.2133 24.4971 34.7101Z"
            fill="#4CC7DD"
        />
        <path
            d="M23.4492 32.3549L24.4978 34.7049C25.616 34.2081 26.6597 33.6019 27.6139 32.9113L26.0981 30.8296C25.288 31.4159 24.4034 31.9326 23.4492 32.3549Z"
            fill="#65CFE2"
        />
        <path
            d="M22.4004 30.005L23.449 32.3551C24.4032 31.9328 25.2878 31.4161 26.0979 30.8298L24.5821 28.748C23.9112 29.2349 23.1856 29.6573 22.4004 30.005Z"
            fill="#7ED6E7"
        />
        <path
            d="M21.3564 27.6498L22.4051 29.9999C23.1903 29.6521 23.9208 29.2298 24.5868 28.7429L23.071 26.6611C22.5442 27.0437 21.9727 27.3766 21.3564 27.6498Z"
            fill="#97DEEC"
        />
        <path
            d="M20.3076 25.3L21.3562 27.6501C21.9725 27.3768 22.549 27.0439 23.0708 26.6613L21.555 24.5796C21.1723 24.8578 20.7549 25.1013 20.3076 25.3Z"
            fill="#B1E6F0"
        />
        <path
            d="M19.2589 22.9497L20.3075 25.2997C20.7548 25.101 21.1722 24.8575 21.5549 24.5793L20.0391 22.4976C19.8006 22.6715 19.5372 22.8255 19.2539 22.9497H19.2589Z"
            fill="#CAEEF5"
        />
        <path
            d="M18.529 20.416C18.4296 20.4856 18.3252 20.5502 18.2109 20.5998L19.2596 22.9499C19.5428 22.8257 19.8013 22.6717 20.0448 22.4978L18.529 20.416Z"
            fill="#E3F6FA"
        />
        <path
            d="M32.1504 39.1615L33.6662 41.2433C35.2515 40.0906 36.6778 38.7989 37.9451 37.4027L36.0317 35.6787C34.8887 36.9456 33.5916 38.1182 32.1554 39.1615H32.1504Z"
            fill="#009BCC"
        />
        <path
            d="M30.6396 37.0748L32.1554 39.1566C33.5917 38.1132 34.8888 36.9407 36.0318 35.6737L34.1185 33.9497C33.0897 35.0875 31.9318 36.1358 30.6446 37.0748H30.6396Z"
            fill="#19A5D1"
        />
        <path
            d="M29.124 34.9935L30.6398 37.0752C31.9269 36.1412 33.0899 35.0879 34.1136 33.9501L32.2003 32.2261C31.2908 33.2347 30.2671 34.1588 29.124 34.9885V34.9935Z"
            fill="#32AFD6"
        />
        <path
            d="M27.6133 32.9115L29.129 34.9933C30.2721 34.1636 31.2958 33.2345 32.2053 32.2309L30.292 30.5068C29.5018 31.3813 28.6072 32.1911 27.6182 32.9115H27.6133Z"
            fill="#4CB9DB"
        />
        <path
            d="M26.0977 30.8297L27.6134 32.9114C28.6074 32.191 29.497 31.3861 30.2871 30.5067L28.3738 28.7827C27.7029 29.528 26.9425 30.2136 26.0977 30.8247V30.8297Z"
            fill="#65C3E0"
        />
        <path
            d="M24.582 28.7429L26.0978 30.8246C26.9427 30.2135 27.6981 29.5279 28.3739 28.7826L26.4606 27.0586C25.909 27.6697 25.2828 28.2361 24.587 28.7429H24.582Z"
            fill="#7ECCE5"
        />
        <path
            d="M23.0713 26.661L24.5871 28.7428C25.2828 28.236 25.909 27.6746 26.4606 27.0585L24.5473 25.3345C24.11 25.8164 23.6229 26.2636 23.0763 26.6561L23.0713 26.661Z"
            fill="#97D6EA"
        />
        <path
            d="M21.5557 24.5796L23.0714 26.6613C23.6181 26.2639 24.1101 25.8217 24.5425 25.3398L22.6291 23.6157C22.3111 23.9685 21.9532 24.2914 21.5557 24.5796Z"
            fill="#B1E0EF"
        />
        <path
            d="M20.0449 22.4977L21.5607 24.5795C21.9583 24.2913 22.3161 23.9684 22.6342 23.6156L20.7208 21.8916C20.522 22.1102 20.2984 22.3139 20.0499 22.4977H20.0449Z"
            fill="#CAEAF4"
        />
        <path
            d="M18.8017 20.1676C18.7221 20.257 18.6327 20.3365 18.5283 20.411L20.0441 22.4928C20.2926 22.3139 20.5162 22.1102 20.715 21.8866L18.8017 20.1626V20.1676Z"
            fill="#E3F4F9"
        />
        <path
            d="M34.1084 33.9498L36.0217 35.6738C37.2095 34.3522 38.2333 32.9412 39.0881 31.4606L36.8567 30.1738C36.0913 31.5004 35.1719 32.7673 34.1084 33.9498Z"
            fill="#1993C9"
        />
        <path
            d="M32.1954 32.226L34.1087 33.95C35.1772 32.7675 36.0917 31.5006 36.857 30.174L34.6256 28.8872C33.9447 30.0597 33.1347 31.1826 32.1904 32.2309L32.1954 32.226Z"
            fill="#329FCF"
        />
        <path
            d="M30.2822 30.5066L32.1956 32.2306C33.1398 31.1823 33.9499 30.0644 34.6307 28.8869L32.3993 27.6001C31.8079 28.6186 31.1022 29.5974 30.2822 30.5066Z"
            fill="#4CABD5"
        />
        <path
            d="M28.3691 28.7828L30.2825 30.5068C31.1025 29.5976 31.8082 28.6188 32.3996 27.6003L30.1682 26.3135C29.6662 27.1829 29.0649 28.0077 28.3691 28.7828Z"
            fill="#65B7DB"
        />
        <path
            d="M26.4551 27.0589L28.3684 28.783C29.0642 28.0079 29.6655 27.1782 30.1675 26.3137L27.9361 25.0269C27.5236 25.7423 27.0266 26.423 26.4551 27.0639V27.0589Z"
            fill="#7EC2E0"
        />
        <path
            d="M24.5421 25.3396L26.4554 27.0636C27.0319 26.4276 27.5239 25.742 27.9364 25.0266L25.705 23.7397C25.382 24.3012 24.9894 24.8378 24.5371 25.3396H24.5421Z"
            fill="#97CEE6"
        />
        <path
            d="M22.6289 23.6157L24.5423 25.3398C24.9945 24.8379 25.3821 24.3014 25.7101 23.7399L23.4787 22.4531C23.2402 22.8605 22.9569 23.253 22.6289 23.6207V23.6157Z"
            fill="#B1DAEC"
        />
        <path
            d="M20.7158 21.8915L22.6292 23.6155C22.9572 23.2479 23.2404 22.8603 23.479 22.4479L21.2476 21.1611C21.0985 21.4195 20.9196 21.6629 20.7158 21.8915Z"
            fill="#CAE6F2"
        />
        <path
            d="M19.0155 19.8745C18.9558 19.9788 18.8862 20.0782 18.8018 20.1676L20.7151 21.8917C20.9238 21.6631 21.0978 21.4197 21.2469 21.1613L19.0155 19.8745Z"
            fill="#E3F2F8"
        />
        <path
            d="M34.626 28.8819L36.8574 30.1687C37.6525 28.7875 38.2887 27.3616 38.7608 25.9009L36.3107 25.106C35.8883 26.3928 35.3317 27.6597 34.626 28.8819Z"
            fill="#3290CC"
        />
        <path
            d="M32.3994 27.5951L34.6308 28.8819C35.3365 27.6597 35.8981 26.3978 36.3156 25.106L33.8655 24.311C33.4977 25.4339 33.0107 26.5319 32.3994 27.5951Z"
            fill="#4C9DD2"
        />
        <path
            d="M30.168 26.3083L32.3994 27.5952C33.0107 26.5319 33.4977 25.4339 33.8654 24.3111L31.4154 23.5161C31.1023 24.47 30.6898 25.4041 30.168 26.3083Z"
            fill="#65ABD8"
        />
        <path
            d="M27.9365 25.0216L30.1679 26.3084C30.6898 25.4041 31.1022 24.4701 31.4153 23.5161L28.9653 22.7212C28.7068 23.5062 28.3689 24.2763 27.9365 25.0216Z"
            fill="#7EB9DF"
        />
        <path
            d="M25.7051 23.7348L27.9365 25.0216C28.3639 24.2763 28.7068 23.5062 28.9652 22.7212L26.5151 21.9263C26.3114 22.5423 26.048 23.1485 25.71 23.7348H25.7051Z"
            fill="#97C7E5"
        />
        <path
            d="M23.4736 22.448L25.705 23.7348C26.043 23.1485 26.3113 22.5424 26.5101 21.9263L24.0601 21.1313C23.911 21.5785 23.7171 22.0207 23.4736 22.448Z"
            fill="#B1D4EB"
        />
        <path
            d="M21.2471 21.1612L23.4785 22.448C23.722 22.0207 23.9208 21.5785 24.0649 21.1314L21.6148 20.3364C21.5254 20.6196 21.4011 20.8929 21.2471 21.1612Z"
            fill="#CAE2F2"
        />
        <path
            d="M19.1647 19.5415C19.1299 19.6558 19.0802 19.7651 19.0156 19.8744L21.247 21.1612C21.4011 20.8929 21.5253 20.6196 21.6148 20.3364L19.1647 19.5415Z"
            fill="#E3F0F8"
        />
        <path
            d="M33.8604 24.3111L36.3104 25.106C36.7478 23.7646 37.031 22.4132 37.1752 21.0618L34.6157 20.7935C34.4915 21.966 34.243 23.1435 33.8653 24.3111H33.8604Z"
            fill="#4C79CE"
        />
        <path
            d="M31.4102 23.5164L33.8602 24.3113C34.2379 23.1437 34.4864 21.9662 34.6107 20.7937L32.0513 20.5254C31.9469 21.524 31.7332 22.5227 31.4151 23.5164H31.4102Z"
            fill="#658CD5"
        />
        <path
            d="M28.96 22.7212L31.41 23.5161C31.7331 22.5224 31.9418 21.5238 32.0462 20.5251L29.4868 20.2568C29.3973 21.0766 29.2283 21.9014 28.96 22.7212Z"
            fill="#7E9EDC"
        />
        <path
            d="M26.5098 21.9264L28.9598 22.7214C29.2232 21.9066 29.3972 21.0818 29.4866 20.2571L26.9272 19.9888C26.8576 20.6347 26.7235 21.2855 26.5147 21.9264H26.5098Z"
            fill="#97B1E3"
        />
        <path
            d="M24.0596 21.1312L26.5096 21.9262C26.7184 21.2853 26.8526 20.6344 26.9221 19.9885L24.3627 19.7202C24.313 20.1922 24.2136 20.6642 24.0596 21.1312Z"
            fill="#B1C4E9"
        />
        <path
            d="M21.6152 20.3365L24.0653 21.1315C24.2194 20.6644 24.3188 20.1924 24.3685 19.7204L21.8091 19.4521C21.7792 19.7453 21.7146 20.0434 21.6202 20.3365H21.6152Z"
            fill="#CAD7F0"
        />
        <path
            d="M19.2396 19.1836C19.2247 19.3028 19.1998 19.4221 19.165 19.5413L21.6151 20.3363C21.7095 20.0431 21.7741 19.745 21.804 19.4519L19.2446 19.1836H19.2396Z"
            fill="#E3EAF7"
        />
        <path
            d="M34.6055 20.7985L37.1649 21.0668C37.314 19.6657 37.309 18.2795 37.1649 16.9331L34.6055 17.2014C34.7297 18.3739 34.7297 19.5763 34.6055 20.7985Z"
            fill="#4D68CE"
        />
        <path
            d="M32.0469 20.5251L34.6063 20.7934C34.7355 19.5761 34.7305 18.3688 34.6063 17.1963L32.0469 17.4646C32.1512 18.4632 32.1512 19.4867 32.0469 20.5201V20.5251Z"
            fill="#667DD5"
        />
        <path
            d="M29.4873 20.257L32.0467 20.5253C32.1561 19.4869 32.1511 18.4634 32.0467 17.4697L29.4873 17.738C29.5718 18.5578 29.5768 19.4024 29.4873 20.257Z"
            fill="#7F93DC"
        />
        <path
            d="M26.9229 19.9885L29.4823 20.2568C29.5717 19.4022 29.5717 18.5576 29.4823 17.7378L26.9229 18.0061C26.9924 18.652 26.9924 19.3128 26.9229 19.9885Z"
            fill="#98A8E3"
        />
        <path
            d="M24.3633 19.7204L26.9227 19.9887C26.9923 19.318 26.9923 18.6522 26.9227 18.0063L24.3633 18.2746C24.413 18.7466 24.413 19.2286 24.3633 19.7155V19.7204Z"
            fill="#B1BDE9"
        />
        <path
            d="M21.7988 19.4523L24.3582 19.7206C24.4079 19.2337 24.4079 18.7468 24.3582 18.2798L21.7988 18.5481C21.8286 18.8412 21.8286 19.1443 21.7988 19.4523Z"
            fill="#CAD2F0"
        />
        <path
            d="M19.2393 18.8161C19.2492 18.9354 19.2542 19.0596 19.2393 19.1838L21.7987 19.4521C21.8285 19.1441 21.8285 18.841 21.7987 18.5479L19.2393 18.8161Z"
            fill="#E3E7F7"
        />
        <path
            d="M34.6058 17.2012L37.1652 16.9329C37.0161 15.5319 36.7279 14.1805 36.3104 12.8887L33.8604 13.6836C34.2231 14.8065 34.4766 15.979 34.6058 17.2012Z"
            fill="#8263CA"
        />
        <path
            d="M32.0463 17.4695L34.6057 17.2012C34.4765 15.984 34.223 14.8064 33.8602 13.6836L31.4102 14.4785C31.7183 15.4325 31.932 16.4311 32.0413 17.4695H32.0463Z"
            fill="#9379D1"
        />
        <path
            d="M29.4867 17.7383L32.0461 17.47C31.9367 16.4316 31.723 15.4329 31.4149 14.479L28.9648 15.2739C29.2183 16.0589 29.3972 16.8837 29.4867 17.7383Z"
            fill="#A58FD9"
        />
        <path
            d="M26.9223 18.0114L29.4817 17.7431C29.3922 16.8886 29.2133 16.0638 28.9598 15.2788L26.5098 16.0737C26.7086 16.6898 26.8477 17.3407 26.9173 18.0114H26.9223Z"
            fill="#B6A5E0"
        />
        <path
            d="M24.3636 18.2797L26.923 18.0114C26.8534 17.3407 26.7143 16.6898 26.5155 16.0737L24.0654 16.8687C24.2096 17.3208 24.3139 17.7878 24.3636 18.2797Z"
            fill="#C8BAE8"
        />
        <path
            d="M21.7992 18.548L24.3586 18.2797C24.3089 17.7928 24.2046 17.3208 24.0604 16.8687L21.6104 17.6636C21.6998 17.9468 21.7644 18.2399 21.7992 18.548Z"
            fill="#D9D0EF"
        />
        <path
            d="M19.1651 18.4585C19.1999 18.5728 19.2297 18.692 19.2397 18.8162L21.7991 18.5479C21.7693 18.2399 21.7047 17.9468 21.6102 17.6636L19.1602 18.4585H19.1651Z"
            fill="#EBE6F7"
        />
        <path
            d="M36.3106 12.889L38.7607 12.094C38.2687 10.5787 37.6276 9.15273 36.8623 7.82617L34.6309 9.11298C35.3067 10.2855 35.8733 11.5525 36.3106 12.889Z"
            fill="#904FBF"
        />
        <path
            d="M33.8605 13.6837L36.3106 12.8888C35.8733 11.5473 35.3117 10.2853 34.6308 9.11279L32.3994 10.3996C32.9858 11.4231 33.4778 12.5211 33.8605 13.6837Z"
            fill="#9E65C7"
        />
        <path
            d="M31.4104 14.4838L33.8605 13.6889C33.4828 12.5213 32.9908 11.4233 32.3994 10.4048L30.168 11.6916C30.6699 12.5611 31.0874 13.4951 31.4104 14.4838Z"
            fill="#AC7BCF"
        />
        <path
            d="M28.9603 15.2786L31.4104 14.4836C31.0873 13.49 30.6699 12.5609 30.1679 11.6914L27.9365 12.9782C28.349 13.6937 28.6919 14.4638 28.9603 15.2786Z"
            fill="#B990D6"
        />
        <path
            d="M26.5102 16.0738L28.9603 15.2789C28.6969 14.4641 28.349 13.694 27.9365 12.9785L25.7051 14.2653C26.0281 14.8268 26.3014 15.4329 26.5102 16.0738Z"
            fill="#C7A6DE"
        />
        <path
            d="M24.0601 16.8686L26.5101 16.0736C26.3014 15.4327 26.0281 14.8266 25.705 14.2651L23.4736 15.5519C23.7122 15.9594 23.906 16.4015 24.0601 16.8686Z"
            fill="#D5BCE6"
        />
        <path
            d="M21.6148 17.6633L24.0649 16.8684C23.9108 16.4014 23.717 15.9592 23.4785 15.5518L21.2471 16.8386C21.3962 17.0969 21.5204 17.3702 21.6148 17.6633Z"
            fill="#E2D1EE"
        />
        <path
            d="M19.0156 18.1257C19.0753 18.23 19.125 18.3393 19.1647 18.4586L21.6148 17.6636C21.5204 17.3705 21.3961 17.0923 21.247 16.8389L19.0156 18.1257Z"
            fill="#F0E7F6"
        />
        <path
            d="M36.8576 7.82626L39.089 6.53945C38.1994 4.99925 37.1706 3.5932 36.0276 2.32129L34.1143 4.04532C35.138 5.18308 36.0624 6.45002 36.8576 7.82626Z"
            fill="#B140C3"
        />
        <path
            d="M34.6254 9.11316L36.8568 7.82635C36.0617 6.44514 35.1373 5.18317 34.1135 4.04541L32.2002 5.76944C33.1047 6.77802 33.9197 7.89591 34.6254 9.11813V9.11316Z"
            fill="#BA55C9"
        />
        <path
            d="M32.3992 10.3997L34.6306 9.11285C33.9249 7.89063 33.1098 6.77274 32.2053 5.76416L30.292 7.48819C31.0822 8.36759 31.7879 9.33643 32.4041 10.3997H32.3992Z"
            fill="#C26AD0"
        />
        <path
            d="M30.1681 11.6866L32.3995 10.3998C31.7882 9.33652 31.0776 8.36769 30.2874 7.48828L28.374 9.21231C29.0449 9.95757 29.6463 10.7823 30.1681 11.6866Z"
            fill="#CB7FD7"
        />
        <path
            d="M27.936 12.9784L30.1674 11.6915C29.6456 10.7873 29.0442 9.96254 28.3733 9.21729L26.46 10.9413C27.0116 11.5574 27.5086 12.2381 27.9409 12.9784H27.936Z"
            fill="#D393DD"
        />
        <path
            d="M25.7049 14.2653L27.9363 12.9784C27.5089 12.2332 27.0119 11.5525 26.4553 10.9414L24.542 12.6654C24.9744 13.1474 25.367 13.684 25.7049 14.2702V14.2653Z"
            fill="#DCA8E4"
        />
        <path
            d="M23.4738 15.5518L25.7052 14.2649C25.3672 13.6787 24.9746 13.1471 24.5423 12.6602L22.6289 14.3842C22.947 14.7369 23.2302 15.1245 23.4738 15.5518Z"
            fill="#E4BDEB"
        />
        <path
            d="M21.2475 16.8387L23.4789 15.5518C23.2354 15.1246 22.9471 14.737 22.634 14.3843L20.7207 16.1083C20.9195 16.3269 21.0984 16.5753 21.2525 16.8387H21.2475Z"
            fill="#EDD2F1"
        />
        <path
            d="M18.8018 17.8275C18.8813 17.9169 18.9558 18.0163 19.0155 18.1256L21.2469 16.8388C21.0928 16.5705 20.9139 16.327 20.7151 16.1084L18.8018 17.8324V17.8275Z"
            fill="#F5E7F8"
        />
        <path
            d="M36.0267 2.32599L37.94 0.601962C36.628 -0.853775 35.1967 -2.14059 33.671 -3.24854L32.1553 -1.16678C33.5369 -0.163169 34.834 1.0044 36.0267 2.32102V2.32599Z"
            fill="#C82DB3"
        />
        <path
            d="M34.1086 4.05021L36.022 2.32618C34.8342 1.00459 33.5321 -0.158008 32.1505 -1.16162L30.6348 0.92013C31.8722 1.82438 33.0401 2.86774 34.1036 4.05021H34.1086Z"
            fill="#CD42BB"
        />
        <path
            d="M32.1953 5.76915L34.1087 4.04512C33.0402 2.86264 31.8773 1.81928 30.6398 0.915039L29.124 2.99679C30.2223 3.7967 31.2511 4.71585 32.1953 5.76418V5.76915Z"
            fill="#D357C2"
        />
        <path
            d="M30.2821 7.49337L32.1955 5.76934C31.2512 4.72101 30.2225 3.79689 29.1242 3.00195L27.6084 5.08371C28.5626 5.77928 29.4571 6.57919 30.2771 7.49337H30.2821Z"
            fill="#D86BCA"
        />
        <path
            d="M28.3688 9.21719L30.2822 7.49316C29.4622 6.58395 28.5676 5.77907 27.6134 5.0835L26.0977 7.16525C26.9077 7.75649 27.6681 8.43716 28.3688 9.21222V9.21719Z"
            fill="#DE80D1"
        />
        <path
            d="M26.4556 10.9414L28.369 9.21738C27.6732 8.44232 26.9079 7.76165 26.0978 7.17041L24.582 9.25216C25.248 9.73906 25.8791 10.3005 26.4507 10.9414H26.4556Z"
            fill="#E395D9"
        />
        <path
            d="M24.5423 12.6603L26.4557 10.9363C25.8792 10.3004 25.253 9.73397 24.5871 9.24707L23.0713 11.3288C23.5981 11.7114 24.0901 12.1536 24.5423 12.6554V12.6603Z"
            fill="#E9AAE0"
        />
        <path
            d="M22.6291 14.3846L24.5425 12.6605C24.0902 12.1587 23.5982 11.7165 23.0714 11.334L21.5557 13.4157C21.9383 13.694 22.2962 14.0169 22.6291 14.3796V14.3846Z"
            fill="#EEBEE8"
        />
        <path
            d="M20.7149 16.1083L22.6283 14.3843C22.3003 14.0166 21.9375 13.6986 21.5548 13.4204L20.0391 15.5022C20.2776 15.6761 20.5062 15.8798 20.71 16.1083H20.7149Z"
            fill="#F4D3EF"
        />
        <path
            d="M18.5283 17.5838C18.6277 17.6533 18.7172 17.7378 18.8017 17.8272L20.715 16.1032C20.5063 15.8747 20.2826 15.671 20.0441 15.4971L18.5283 17.5788V17.5838Z"
            fill="#F9E8F7"
        />
        <path
            d="M30.6401 0.920133L32.1559 -1.16162C30.7196 -2.20498 29.2038 -3.07445 27.6433 -3.77002L26.5947 -1.41997C27.9962 -0.793958 29.348 -0.0188913 30.6401 0.920133Z"
            fill="#D63C98"
        />
        <path
            d="M29.1241 3.00682L30.6399 0.925069C29.3527 -0.0139555 27.996 -0.789023 26.5945 -1.41504L25.5459 0.935006C26.7834 1.4865 27.986 2.1771 29.1241 3.00682Z"
            fill="#DB52A3"
        />
        <path
            d="M27.613 5.08863L29.1288 3.00687C27.9857 2.17715 26.788 1.49152 25.5506 0.935059L24.502 3.2851C25.5804 3.76704 26.624 4.36324 27.613 5.08366V5.08863Z"
            fill="#DF67AF"
        />
        <path
            d="M26.098 7.17035L27.6138 5.08859C26.6198 4.36818 25.5762 3.767 24.5027 3.29004L23.4541 5.64505C24.3685 6.05246 25.2581 6.5642 26.103 7.17531L26.098 7.17035Z"
            fill="#E47CBA"
        />
        <path
            d="M24.5821 9.25215L26.0979 7.1704C25.253 6.55929 24.3684 6.04754 23.449 5.64014L22.4004 7.99018C23.1558 8.32803 23.8863 8.74538 24.5821 9.25215Z"
            fill="#E892C5"
        />
        <path
            d="M23.071 11.3388L24.5868 9.25709C23.891 8.75031 23.1605 8.33297 22.4051 7.99512L21.3564 10.3452C21.9478 10.6085 22.5243 10.9414 23.071 11.3388Z"
            fill="#EDA7D0"
        />
        <path
            d="M21.555 13.4206L23.0708 11.3389C22.5241 10.9414 21.9476 10.6135 21.3562 10.3452L20.3076 12.7002C20.74 12.894 21.1574 13.1325 21.555 13.4206Z"
            fill="#F1BCDC"
        />
        <path
            d="M20.0449 15.5024L21.5607 13.4206C21.1631 13.1324 20.7456 12.889 20.3133 12.7002L19.2646 15.0502C19.533 15.1695 19.7964 15.3235 20.0449 15.5024Z"
            fill="#F6D2E7"
        />
        <path
            d="M18.2109 17.4003C18.3203 17.45 18.4246 17.5096 18.529 17.5842L20.0448 15.5024C19.7963 15.3236 19.5329 15.1695 19.2645 15.0503L18.2159 17.4003H18.2109Z"
            fill="#FAE7F2"
        />
        <path
            d="M25.5463 0.939888L26.5949 -1.41016C25.1388 -2.05605 23.6528 -2.53798 22.152 -2.85596L21.6152 -0.336986C22.9422 -0.0537884 24.2591 0.368524 25.5463 0.944856V0.939888Z"
            fill="#EA528B"
        />
        <path
            d="M24.4973 3.29009L25.5459 0.940046C24.2588 0.368682 22.9418 -0.0585991 21.6149 -0.341797L21.0781 2.17717C22.2311 2.42559 23.3791 2.79325 24.4973 3.29009Z"
            fill="#ED679A"
        />
        <path
            d="M23.4483 5.64509L24.4969 3.29505C23.3787 2.79821 22.2307 2.42558 21.0777 2.18213L20.541 4.7011C21.5201 4.90977 22.4941 5.22278 23.4483 5.65006V5.64509Z"
            fill="#EF7CA8"
        />
        <path
            d="M22.4003 7.99481L23.4489 5.64476C22.4947 5.22245 21.5206 4.90447 20.5416 4.6958L20.0049 7.21477C20.8149 7.38866 21.6151 7.64702 22.4003 7.99481Z"
            fill="#F292B6"
        />
        <path
            d="M21.3562 10.3449L22.4048 7.99488C21.6196 7.64709 20.8195 7.38874 20.0094 7.21484L19.4727 9.73381C20.1088 9.86796 20.7399 10.0717 21.3562 10.3499V10.3449Z"
            fill="#F4A7C4"
        />
        <path
            d="M20.3082 12.7L21.3568 10.35C20.7405 10.0767 20.1094 9.873 19.4733 9.73389L18.9365 12.2529C19.3987 12.3522 19.8609 12.5013 20.3082 12.7Z"
            fill="#F7BCD3"
        />
        <path
            d="M19.2592 15.0501L20.3078 12.7001C19.8605 12.5013 19.3983 12.3523 18.9361 12.2529L18.3994 14.7719C18.6877 14.8315 18.9759 14.9259 19.2592 15.0501Z"
            fill="#F9D2E1"
        />
        <path
            d="M17.8623 17.2909C17.9816 17.3157 18.0959 17.3554 18.2102 17.4051L19.2588 15.0551C18.9755 14.9309 18.6873 14.8365 18.399 14.7769L17.8623 17.2958V17.2909Z"
            fill="#FCE7EF"
        />
        <path
            d="M21.0781 2.18177L21.6148 -0.337196C20.2333 -0.630331 18.8616 -0.769446 17.5049 -0.774414V1.79921C18.6827 1.79921 19.8804 1.92342 21.0831 2.1768L21.0781 2.18177Z"
            fill="#F66679"
        />
        <path
            d="M20.5415 4.69588L21.0782 2.17691C19.8805 1.92353 18.6828 1.79932 17.5 1.79932V4.37294C18.5039 4.37294 19.5227 4.47728 20.5415 4.69588Z"
            fill="#F77B8C"
        />
        <path
            d="M20.0047 7.21496L20.5415 4.69599C19.5227 4.47738 18.5039 4.37305 17.5 4.37305V6.94667C18.325 6.94667 19.1649 7.0361 20.0047 7.20999V7.21496Z"
            fill="#F9919E"
        />
        <path
            d="M19.4729 9.73395L20.0096 7.21498C19.1697 7.03612 18.3299 6.95166 17.5049 6.95166V9.52528C18.1559 9.52528 18.8119 9.59484 19.4729 9.73395Z"
            fill="#FAA6B1"
        />
        <path
            d="M18.9362 12.253L19.4729 9.73406C18.8119 9.59495 18.1509 9.52539 17.5049 9.52539V12.099C17.977 12.099 18.4591 12.1487 18.9411 12.253H18.9362Z"
            fill="#FBBCC4"
        />
        <path
            d="M18.3995 14.7721L18.9363 12.2531C18.4542 12.1488 17.9771 12.0991 17.5 12.0991V14.6727C17.7982 14.6727 18.0964 14.7026 18.3995 14.7671V14.7721Z"
            fill="#FDD1D7"
        />
        <path
            d="M17.5 17.2514C17.6193 17.2514 17.7435 17.2663 17.8628 17.2911L18.3995 14.7721C18.0964 14.7075 17.7982 14.6777 17.5 14.6777V17.2514Z"
            fill="#FEE7EA"
        />
    </svg>
);

export const IMAGE_SVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="37" viewBox="0 0 36 37" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 3.5C0 1.84315 1.34315 0.5 3 0.5H33C34.6569 0.5 36 1.84315 36 3.5V33.5C36 35.1569 34.6569 36.5 33 36.5H3C1.34315 36.5 0 35.1569 0 33.5V3.5ZM33 3.5H3V33.5H33V3.5Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.3787 15.5C23.9413 14.9374 24.7043 14.6213 25.5 14.6213C26.2956 14.6213 27.0587 14.9374 27.6213 15.5L35.5606 23.4393C36.1464 24.0251 36.1464 24.9748 35.5606 25.5606C34.9748 26.1464 34.0251 26.1464 33.4393 25.5606L25.5 17.6213L17.1213 26C16.5587 26.5626 15.7956 26.8787 15 26.8787C14.2043 26.8787 13.4413 26.5626 12.8787 26L8.99999 22.1213L2.56066 28.5607C1.97488 29.1464 1.02513 29.1464 0.439341 28.5607C-0.146446 27.9749 -0.146447 27.0251 0.439339 26.4393L6.87867 20C7.44128 19.4374 8.20435 19.1213 8.99999 19.1213C9.79564 19.1213 10.5587 19.4374 11.1213 20L15 23.8787L23.3787 15.5Z"
            fill="black"
        />
        <path
            d="M12.75 14C13.9926 14 15 12.9926 15 11.75C15 10.5074 13.9926 9.5 12.75 9.5C11.5074 9.5 10.5 10.5074 10.5 11.75C10.5 12.9926 11.5074 14 12.75 14Z"
            fill="black"
        />
    </svg>
);
