import Main from './Main';

declare global {
  interface Window {
    CommApp: any;
    Headers: any;
  }
}

Object.defineProperty(window, "PTApp", {
  enumerable: false,
  configurable: false,
  value: Main
});
