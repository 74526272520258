import React, { useState } from 'react';

export type MenuContextType = {
    sideMenu: boolean;
    updateSideMenu: (f: boolean) => void;
    navMenu: boolean;
    updateNavMenu: (f: boolean) => void;
};

export const MenuContext = React.createContext<MenuContextType | null>(null);

const MenuProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [sideMenu, setSideMenu] = useState<boolean>(false);
    const [navMenu, setNavMenu] = useState<boolean>(false);

    const updateSideMenu = (flag: boolean) => {
        setSideMenu(flag);
    };

    const updateNavMenu = (flag: boolean) => {
        setNavMenu(flag);
    };

    return (
        <MenuContext.Provider value={{ sideMenu, updateSideMenu, navMenu, updateNavMenu }}>
            {children}
        </MenuContext.Provider>
    );
};
export default MenuProvider;
