import React, { useCallback, useEffect, useRef } from 'react';

import './menu.css';

import { IMAGE_SVG, EYE_SVG, TRASH_SVG } from '../icons';

const Menu = ({ index, order_no, _id, x, y, visible, fn }: any) => {
    const menuRef = useRef<HTMLDivElement>(null);

    const posClean = useCallback(() => {
        fn.pos({
            x: 0,
            y: 0,
            order_no: null,
            index: null,
            _id: null,
            visible: false,
        });
    }, [fn]); // Include fn in the dependencies array if it might change

    useEffect(() => {
        const handleClick = (event: any) => {
            const { target } = event;
            if (menuRef.current && menuRef.current.contains(target as Node)) {
                // console.log('contains true');
            } else {
                if (target instanceof HTMLButtonElement && target.classList.contains('item-action')) {
                    // console.log('Clicked the button with class "item-action"');
                } else {
                    fn.show(false);
                    posClean();
                }
            }
        };

        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [menuRef, fn, posClean]);

    return (
        <div
            ref={menuRef}
            className="c-f-menu"
            style={{
                top: y,
                left: x,
            }}
        >
            <div
                role="button"
                onClick={() => {
                    fn.show(false);
                    posClean();
                    fn.add(index, order_no);
                }}
            >
                <span>
                    <IMAGE_SVG />
                </span>
                Add image slide
            </div>
            <div
                role="button"
                onClick={() => {
                    fn.visible(_id, visible === false);
                    fn.show(false);
                    posClean();
                }}
            >
                <span>
                    <EYE_SVG />
                </span>
                {visible === true ? 'Hide slide' : 'Show slide'}
            </div>
            <div
                role="button"
                onClick={() => {
                    fn.show(false);

                    fn.del(_id);
                }}
            >
                <span>
                    <TRASH_SVG />
                </span>
                Delete slide
            </div>
        </div>
    );
};

export default Menu;
