import axiosInstance from '../../lib/axios';
import urls from '..';

// ## SLIDE REMOVE
export const deleteRemoveSlide = async (
    base: any,
    id: any,
    presentationId: any,
    client_id: any,
    extra: any,
    auth: any
) => {
    const urll = `${base}/${urls.deleteRemoveSlide}?slide=${id}&presentation_id=${presentationId}`;
    return await axiosInstance(urll, {
        method: 'DELETE',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
    }).then((response: any) => {
        return { status: response.status };
    });
};
