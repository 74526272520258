import React from 'react';

const ThemesColor = ({ themeList }: any) => {
    return (
        <ul className="cc2-aside-panel-theme-ul">
            {themeList.map(({ name, color }: any) => (
                <li key={name}>
                    <div className="cc2-aside-panel-theme-ul-li-color">
                        {color.map((col: string) => (
                            <span
                                key={col}
                                style={{
                                    background: col,
                                }}
                            >
                                {col}
                            </span>
                        ))}
                    </div>
                    {name}
                </li>
            ))}
        </ul>
    );
};

export default ThemesColor;
