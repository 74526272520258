import React, { useState, useEffect, useContext } from 'react';

import boardContext from '../../../context/BoardContext';

import PtImage from '../atom/PT_Image';
import PtShape from '../atom/PT_Shape';

import iconEdit from '../../../assets/icons/icon-pencil.png';
import iconEye from '../../../assets/icons/icon-eye.png';
import iconEyeOff from '../../../assets/icons/icon-eye-off.png';

import '../contact.css';

// import { group_elements } from '../ContactSlide'

export function getElement(data: any, key: string) {
    const result = data.filter((d: any) => d.element_id === key);
    return result.length > 0 ? result[0] : null;
}

function DebtorLogo(props: any) {
    const [flag, setflag] = useState(true);
    const [obj, setobj] = useState<any>({});
    const { brandLogo, elements, value, style = {}, cssStyle = {}, onSlideUpdate } = props;

    const { colorpickview }: any = useContext(boardContext);

    // console.log(colorpickview);
    // console.log(props);

    const backgroundObj = getElement(elements, 'end_customer_logo_background');

    useEffect(() => {
        const scopeObject: any = {};
        elements.map((ele: any) => {
            scopeObject[ele.element_id] = ele;
            return null;
        });
        const trinary = scopeObject.end_customer_logo.visible !== false ? true : false;
        setobj(scopeObject);
        setflag(trinary);
    }, [elements, setflag]);

    function visiblity() {
        if (obj.end_customer_logo !== null) {
            const { _id, visible, group } = obj?.end_customer_logo;
            const updateObj: any = {
                id: _id,
                type: 'visible',
                value: visible !== false ? false : true,
                group,
            };
            onSlideUpdate(updateObj);
        }
    }

    function backgroundColorPick() {
        colorpickview({
            id: backgroundObj._id,
            name: backgroundObj.element_id,
            color: backgroundObj.fill || value.background_color,
        });
    }

    return (
        <PtShape cssname={'debtorLogo relative action-wrap mt5 mr5'}>
            {flag !== false ? (
                <PtShape
                    cssname={'debtorLogo flex justify-center align-center p-t_5 p-r_5 p-b_5 p-l_5'}
                    cssStyle={style}
                    fill={backgroundObj.fill || value.background_color}
                >
                    {brandLogo && (
                        <PtImage
                            value={brandLogo}
                            cssStyle={{
                                ...cssStyle,
                                maxWidth: '85px',
                                maxHeight: '35px',
                            }}
                        />
                    )}
                </PtShape>
            ) : null}
            <div className="absolute action-box">
                <button onClick={visiblity}>
                    {flag ? <img src={`${iconEye}`} alt="visible" /> : <img src={`${iconEyeOff}`} alt="visible" />}
                </button>
                <button onClick={backgroundColorPick}>
                    <img src={`${iconEdit}`} alt="edit" />
                </button>
            </div>
        </PtShape>
    );
}

export default DebtorLogo;
