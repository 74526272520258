import axiosInstance from '../../lib/axios';
import urls from '../';

export const postImageUpload = async (
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    payload: any,
    contact_id: any
) => {
    const urll = `${base}/${urls.postImageUpload}`;
    const formData = new FormData();
    formData.append('images', payload.images);
    return await axiosInstance
        .post(urll, formData, {
            params: {
                subdir: 'Your Images',
                contact_id: contact_id,
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                client_id,
                'x-extra-token': `${extra}`,
                authorization: `Bearer ${auth}`,
            },
            data: payload,
        })
        .then((response: any) => {
            const { status, data } = response;
            const { files = [] } = data;
            return { status, background: files };
        });
};
