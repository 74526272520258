import React, { useState, useContext } from 'react';
import { PhotoshopPicker } from 'react-color';
// SketchPicker
import ColorPickerWrapper from './ColorPickerWrapper';
import getColorTitle from './colorhelper';
import { popCloseIcons } from '../../helpers/IconList';
import languageContext from '../../context/languageContext';

import './colorPicker.css';

interface ColorPickerProps {
    data: any;
    view: () => void;
    onPresentationLogoChange?: any;
    onCustomerLogoChange?: any;
    onLookAndFeelChange?: any;
    onSlideUpdate?: any;
    color?: string;
    localEdit?: any;
    elements?: any;
    localmenuClose: any;
    settingColor: any;
    logoColor: string[] | null;
}

const ColorPicker = ({
    view,
    color,
    data,
    onPresentationLogoChange,
    onCustomerLogoChange,
    onLookAndFeelChange,
    onSlideUpdate,
    localEdit,
    elements = [],
    localmenuClose,
    settingColor,
    logoColor,
}: ColorPickerProps) => {
    const [loccolor, setLoccolor] = useState(data.color !== '' ? data.color : color);
    const { cancel, ok, ...language }: any = useContext(languageContext);
    const [colorset] = useState(data.list !== undefined ? data.list : ['#ffffff', '#838383', '#000000']);

    const pushBgcolorChange = () => {
        if (data.name === 'Show Debtor logo') onPresentationLogoChange({ type: 'background_color', value: loccolor });
        if (data.name === 'Show End customer logo') onCustomerLogoChange({ type: 'background_color', value: loccolor });
        if (data.name === 'look_and_feel') onLookAndFeelChange({ type: 'template_color', value: loccolor });
        if (data.name === 'font_color') onLookAndFeelChange({ type: 'font_color', value: loccolor });
        // NEED TO REFACTOR
        if (data.name === 'main' || data.name === 'accent_1' || data.name === 'accent_2') {
            const { element, onElementChangge } = settingColor;
            const colorobj = {
                ...element,
                colors: {
                    ...element.colors,
                    value: {
                        ...element.colors.value,
                        [element.style.applied]: {
                            ...element.colors.value[element.style.applied],
                            [data.name]: {
                                ...element.colors.value[element.style.applied][data.name],
                                applied: loccolor,
                            },
                        },
                    },
                },
            };
            onElementChangge(colorobj.colors);
        }

        if (data.name === 'presentation_logo_background' || data.name === 'end_customer_logo_background') {
            onSlideUpdate({ id: data.id, type: 'fill', value: loccolor });
        }
        if (data.name === 'background_color')
            onSlideUpdate({ id: data.id, type: 'fill', value: loccolor, group: 'background' });

        if (data.name === 'end_customer_logo') {
            if (elements !== undefined && elements.length > 0) {
                elements.map((ele: any) => {
                    if (ele.element_id === 'end_customer_logo_background') {
                        onSlideUpdate({ id: ele._id, type: 'fill', value: loccolor, group: ele.group });
                    }
                    return null;
                });
            }
        }
        if (data.name === 'title_text_background') {
            let objj = null;
            if (elements !== undefined && elements.length > 0) {
                elements.map((ele: any) => {
                    if (ele.element_id === 'end_customer_logo_background') {
                        objj = { id: ele._id, type: 'fill', value: loccolor, group: ele.group };
                    }
                    return null;
                });
            }

            const obj = { id: data.id, type: 'fill', value: loccolor, group: localEdit.group };
            if (objj !== null) onSlideUpdate([objj, obj]);
            else onSlideUpdate(obj);
        }

        if (data.name === 'title_text') {
            onSlideUpdate({ id: data.id, type: 'color', value: loccolor, group: localEdit.group });
        }
        localmenuClose();
        view();
    };

    const handleChangeComplete = (color: any) => {
        const { hex } = color;
        setLoccolor(hex);
    };

    const title = data !== undefined && data.name !== '' ? language[data.name] || data.name : getColorTitle(data.name);

    return (
        <div className="flex justify-center align-center colorBoxWrap">
            <div className="colorBox">
                <div className="colorBox__title">
                    <h4>{title}</h4>
                    <button onClick={view}>
                        <img src={popCloseIcons} alt="close" />
                        {/* close */}
                    </button>
                </div>
                <div className="colorBox__body">
                    <div className="flex">
                        <div className="colorset">
                            <div className="set">
                                {logoColor !== null
                                    ? logoColor
                                          .slice(0, 10)
                                          .map((c: any, index: number) => (
                                              <span
                                                  key={c + index}
                                                  style={{ backgroundColor: c }}
                                                  onClick={() => setLoccolor(c)}
                                              />
                                          ))
                                    : colorset.map((c: any, index: number) => (
                                          <span
                                              key={c + index}
                                              style={{ backgroundColor: c }}
                                              onClick={() => setLoccolor(c)}
                                          />
                                      ))}
                            </div>
                            <span style={{ backgroundColor: loccolor }} />
                        </div>
                        <PhotoshopPicker
                            color={loccolor}
                            onChangeComplete={handleChangeComplete}
                            onAccept={pushBgcolorChange}
                            onCancel={view}
                        />
                        <ColorPickerWrapper loccolor={loccolor} onChangeComplete={handleChangeComplete} />
                    </div>
                </div>
                <div className="colorBox__footer">
                    <div />
                    <div>
                        <button onClick={view}>{cancel}</button>
                        <button className="ok" onClick={pushBgcolorChange} disabled={false}>
                            {ok}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default React.memo(ColorPicker);
