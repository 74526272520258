// src/component/Board/Board.tsx
import React, { useEffect, useState, useContext } from "react";
import boardContext from "../../context/BoardContext";

import ThreeDModel from "../ThreeDModel";
import { Rect, BackgroundImage, Logos, Text, ProductImage, Textonly, TextEditable, AvailableColor, RectFlat, FlatImage, FlatLogo, Svg } from "../Shape";
import { editableElementList, imageGroupList, textGroupList } from "./groupList";
import { boardProps } from "./typesBoard";

const Board = ({
    id,
    type: propstype,
    name: propsname,
    elements: propselements,
    presentationLogo,
    editorLogo,
    localmenuView,
    onSlideUpdate,
    setECQView,
    onProductImageChange,
    product_id,
    currentid,
    currenttype,
    productTempType,
}: boardProps) => {
    const { element, margin, design }: any = useContext(boardContext);

    const settingELementFlag: any = {};
    if (element !== undefined && element.length > 0) {
        element.map(({ group, value }: any) => {
            settingELementFlag[`${group}`] = value;
            return null;
        });
    }

    const [name, setName] = useState("Empty");
    const [elements, setElements] = useState([]);

    useEffect(() => {
        setName(propsname);
        setElements(propselements);
    }, [propsname, propselements, propstype]);

    if (!propselements.length) {
        return (
            <div className='ground'>
                <div className='cc' data-title={name} id={id}>
                    <div className='text blank' key={Math.random()}>
                        {name}
                    </div>
                </div>
            </div>
        );
    }

    const sorter = (a: any, b: any) => {
        if (a.order_no > b.order_no) {
            return 1;
        }
        return -1;
    };

    const slidesArray = elements
        .sort(sorter)
        .map((e: any) => {
            const { _id, type, element_id, group, draw_value } = e;

            switch (type) {
                case "shape": {
                    if (
                        group.startsWith("item_row_") ||
                        group === "right-pan" ||
                        group === "left-pan" ||
                        element_id === "bottom_pan_hover" ||
                        element_id === "horizontal_line_bg" ||
                        element_id === "vertical_line_bg"
                    ) {
                        return <RectFlat slideType={propstype} setECQView={setECQView} key={_id} {...e} />;
                    }
                    if (group === "presentation_logo") {
                        const newVisible = e.visible === null ? presentationLogo.visible : e.visible;
                        const newprops = { ...presentationLogo, ...e, ...{ visible: newVisible } };
                        /**
                         * ### need code improvement here
                         */
                        const filteredDesignList = design.filter((d: any) => d.element_id === "your_logo");
                        if (filteredDesignList.length > 0) {
                            if (!filteredDesignList[0].value) return null;
                        }
                        return <Rect slideType={propstype} key={_id} {...newprops} onSlideUpdate={onSlideUpdate} localmenuView={localmenuView} />;
                    }
                    if (group === "end_customer_logo") {
                        const newVisible = e.visible === null ? editorLogo.visible : e.visible;
                        const newprops = { ...editorLogo, ...e, ...{ visible: newVisible } };

                        return <Rect slideType={propstype} key={_id} {...newprops} onSlideUpdate={onSlideUpdate} localmenuView={localmenuView} />;
                    }
                    return <Rect slideType={propstype} key={_id} {...e} onSlideUpdate={onSlideUpdate} localmenuView={localmenuView} />;
                }
                case "image": {
                    if (element_id === "scene_3d" && draw_value) {
                        return <ThreeDModel key={_id} {...e} />;
                    }
                    if (imageGroupList.includes(group)) {
                        if (settingELementFlag[group] !== undefined && settingELementFlag[group] === false) return null;
                    }

                    if (element_id === "presentation_logo") {
                        const newVisible = e.visible === null ? presentationLogo.visible : e.visible;
                        const newprops = { ...presentationLogo, ...e, ...{ visible: newVisible } };

                        /**
                         * ### need code improvement here
                         */
                        const filteredDesignList = design.filter((d: any) => d.element_id === "your_logo");
                        if (filteredDesignList.length > 0) {
                            if (!filteredDesignList[0].value) return null;
                        }
                        return <Logos slideType={propstype} key={_id} {...newprops} onSlideUpdate={onSlideUpdate} localmenuView={localmenuView} />;
                    }
                    if (element_id === "end_customer_logo") {
                        let newVisible = e.visible === null ? editorLogo.visible : e.visible;
                        const newprops = { ...editorLogo, ...e, ...{ visible: newVisible } };
                        return (
                            <Logos slideType={propstype} key={_id} {...newprops} onSlideUpdate={onSlideUpdate} localmenuView={localmenuView} productTempType={productTempType} />
                        );
                    }
                    if (element_id === "bottom_logo") {
                        const newprops = { ...presentationLogo, ...e, ...{ visible: editorLogo.visible } };
                        return <FlatLogo slideType={propstype} key={_id} {...newprops} />;
                    }
                    /**
                     * * End Customer Quotes (ECA)
                     * * center buttom logo wrapper hover effect removing
                     */
                    if (element_id === "bottom_logo_background") {
                        return <FlatImage slideType={propstype} key={_id} {...editorLogo} {...e} onSlideUpdate={onSlideUpdate} localmenuView={localmenuView} />;
                    }

                    if (
                        element_id === "product_artwork_design" ||
                        element_id === "item_color" ||
                        element_id === "bottom_logo" ||
                        element_id === "column_1" ||
                        element_id === "mailbox_image" ||
                        element_id === "stamp_image" ||
                        element_id === "product_artwork_background"
                    ) {
                        return <ProductImage slideType={propstype} key={_id} {...editorLogo} {...e} onSlideUpdate={onSlideUpdate} localmenuView={localmenuView} />;
                    }

                    if (
                        element_id === "decorator_product_image" ||
                        element_id === "product_image_1" ||
                        element_id === "product_image_2" ||
                        element_id === "product_image_3" ||
                        element_id === "left_large_image"
                    ) {
                        return (
                            <ProductImage
                                slideType={propstype}
                                key={_id}
                                {...editorLogo}
                                {...e}
                                onSlideUpdate={onSlideUpdate}
                                onProductImageChange={onProductImageChange}
                                product_id={product_id}
                            />
                        );
                    }
                    if (element_id === "available_colors") {
                        return <AvailableColor slideType={propstype} key={_id} {...e} localmenuView={localmenuView} />;
                    }
                    return <BackgroundImage slideType={propstype} key={_id} {...e} currentid={currentid} currenttype={currenttype} localmenuView={localmenuView} />;
                }
                case "text": {
                    if (textGroupList.includes(group)) {
                        if (settingELementFlag[group] !== undefined && settingELementFlag[group] === false) {
                            return null;
                        }
                    }

                    // product-slide-element removing hover effect
                    if (
                        group.startsWith("more_cost_row") ||
                        group.startsWith("item_row") ||
                        // conact
                        // element_id === 'textbox_left' ||
                        // element_id === 'textbox_right' ||
                        // ecq
                        element_id === "price_per_product_label_text" ||
                        element_id === "price_per_product_value_text" ||
                        element_id === "total_exclusive_vat_label_text" ||
                        element_id === "total_exclusive_vat_value_text" ||
                        element_id === "recommended_decoration_option_label" ||
                        element_id === "default_print_location_label" ||
                        element_id === "recommended_decoration_option_value" ||
                        element_id === "default_print_location_value" ||
                        element_id === "ink_color_label" ||
                        element_id === "ink_color_value" ||
                        element_id === "bottom_price_per_product_value_text" ||
                        element_id === "bottom_total_exclusive_vat_value_text"
                    ) {
                        let price_value = "";
                        if (element_id === "price_label") {
                            elements.map((a: any) => {
                                if (a.element_id === "price_text") price_value = a.draw_value;
                                return null;
                            });
                        }
                        return <Textonly slideType={propstype} key={_id} {...e} price_value={price_value} />;
                    }

                    if (editableElementList.includes(element_id)) {
                        let price_value = "";
                        if (element_id === "price_label") {
                            elements.map((a: any) => {
                                if (a.element_id === "price_text") price_value = a.draw_value;
                                return null;
                            });
                        }
                        return (
                            <TextEditable
                                slideType={propstype}
                                key={_id}
                                {...e}
                                onSlideUpdate={onSlideUpdate}
                                margin={margin}
                                price_value={price_value}
                                productTempType={productTempType}
                            />
                        );
                    }
                    return <Text slideType={propstype} key={_id} {...e} localmenuView={localmenuView} onSlideUpdate={onSlideUpdate} />;
                }
                case "svg": {
                    return <Svg slideType={propstype} key={_id} {...e} />;
                }
            }
            return null;
        })
        .filter(i => i !== null);

    return <>{slidesArray}</>;
};
export default React.memo(Board);
