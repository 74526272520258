import React from 'react';
import PtImage from '../atom/PT_Image';
import PtShape from '../atom/PT_Shape';

import '../contact.css';

export function getElement(data: any, key: string) {
    const result = data.filter((d: any) => d.element_id === key);
    return result.length > 0 ? result[0] : null;
}

export default function ContactLogo({ logo, value, visible = { value: true } }: any) {
    // const background: any = getElement(logo, "presentation_logo_background");
    if (visible === null) return null;
    const { value: flag = true } = visible;

    // function visiblity() {
    //     alert("toggle");
    // }
    // function backgroundColorPick() {
    //     alert("color picker");
    // }

    if (flag === false) return <div className="contact_logo_wrapper flex m-b_60" style={{ height: '120px' }} />;

    return (
        <PtShape cssname={'contact_logo_warpper flex justify-center m-b_60'} fill={value.background_color}>
            <PtImage value={value.source} cssStyle={{ objectFit: 'contain' }} />
        </PtShape>
    );
}
