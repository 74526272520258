import axiosInstance from '../../lib/axios';
import urls from '../';

export const putToggleColor = async ({ baseUrl, clientID, extraKey, accesskey, data, presentationID }: any) => {
    const urll = `${baseUrl}/${urls.putToggleColor}${presentationID}`;
    return await axiosInstance(urll, {
        method: 'put',
        headers: {
            client_id: clientID,
            'x-extra-token': `${extraKey}`,
            authorization: `Bearer ${accesskey}`,
        },
        data,
    }).then((response: any) => {
        return { status: response.status };
    });
};
