import React, { useState, useContext, useEffect } from 'react';

import lookandFeelContext from '../../../../context/LookAndFeelContext';
import languageContext from '../../../../context/languageContext';

import './element.css';

const Element = ({ colorpick }: any) => {
    const { element, design, onDesignChangge, lookFeel }: any = useContext(lookandFeelContext);
    const language: any = useContext(languageContext);

    const { font_color } = lookFeel;

    const [ele, setele] = useState(design);

    useEffect(() => setele(design), [design]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        onDesignChangge({ _id: name, value: checked });
    };

    const pickColor2 = () => {
        if (element.colors.value.style_1.main.colors !== undefined)
            colorpick({ color: font_color, name: 'font_color', list: element.colors.value.style_1.main.colors });
        else colorpick({ color: font_color, name: 'font_color' });
    };

    return (
        <section className="pt-20 c-s-p-element">
            <h3 className="gotham-black fz16 fw-350 mb20">
                {language['Content'] !== undefined ? language['Content'] : 'Content'}
            </h3>
            {ele
                .filter((d: any) => d.group === 'content')
                .map((d: any) => {
                    return (
                        <div className="pfc-toggle label-left toggle_small justify-space-between mb10" key={d._id}>
                            <label>
                                <input type="checkbox" checked={d.value} name={d._id} onChange={onChange} />
                                <span className="slider"></span>
                            </label>
                            <span className="label text-regular_default fz14">
                                {language[d.element_id] !== undefined ? language[d.element_id] : d.label}
                            </span>
                        </div>
                    );
                })}
            <ul className="hide">
                {ele
                    .filter((d: any) => {
                        return d.group === 'content';
                    })
                    .map((d: any) => (
                        <li key={d._id} className="fz14">
                            <div className={'flex justify-space-between'}>
                                {language[d.element_id] !== undefined ? language[d.element_id] : d.label}
                                <label className="cc_switch">
                                    <input type="checkbox" checked={d.value} name={d._id} onChange={onChange} />
                                    <span className="slider_pillow cursor-pointer round"></span>
                                </label>
                            </div>
                        </li>
                    ))}
            </ul>
            <div className="cc2-setting__divider mt20 mb15">
                <h3 className="gotham-black fz16 fw-normal">
                    {language['pillow_font'] !== undefined ? language['pillow_font'] : 'Font'}
                </h3>
            </div>
            <div className="flex align-center setting__element__color_pillow" onClick={pickColor2}>
                <span
                    className="setting__element__font_colorpicker mr10 cursor-pointer"
                    style={{
                        background: font_color,
                        width: '56px',
                        height: '36px',
                        display: 'block',
                        border: '1px solid #000',
                    }}
                />
                <span className="text-black flex1 fz12">
                    {language['font_color'] !== undefined ? language['font_color'] : 'Font color'}
                </span>
            </div>
        </section>
    );
};

export default Element;
