import React, { useContext } from 'react';
import boardContest from '../../context/BoardContext';
import iconEdit from '../../assets/icons/icon-pencil.png';
import iconEye from '../../assets/icons/icon-eye.png';
import iconEyeOff from '../../assets/icons/icon-eye-off.png';

import { LogosProp } from './shape';

const Logos = (props: LogosProp) => {
    const {
        _id,
        draw_value,
        element_id,
        height,
        width,
        x_pos,
        y_pos,
        visible,
        group,
        source,
        onSlideUpdate = () => {},
        slideType,
    } = props;
    // console.info('Logos : ', _id, draw_value, element_id, height, width, x_pos, y_pos, visible);
    // const BURL = useContext(globalContext);
    const context: any = useContext(boardContest);

    const style = {
        height,
        width,
        left: x_pos,
        top: y_pos,
        display: 'flex',
        justifyContent: slideType === 'title_slide' ? 'center' : 'flex-end',
        alignItems: 'center',
    };

    const constumerLogoFlag = element_id === 'end_customer_logo';
    const cname = constumerLogoFlag ? 'pointer_events_none' : 'slider-bottom-logo';
    const flagSlideType = slideType === 'product_slide' ? 'product-logo' : '';
    const cname2 = constumerLogoFlag ? '' : 'slidelogos';
    const style2 = {
        width: 'auto',
        height: 'auto',
        maxWidth: '89%',
        maxHeight: '81%',
        display: 'flex',
        justifyContent: 'flex-end',
    };

    /**
     * ! Discussion with develoopment team (2021/02/03)
     * ! -------------------------------------------
     * ! draw_value first if not source value is implement for image
     */
    const finalSoure = draw_value || source;
    return (
        <div className={`${cname2} ${cname} ${flagSlideType} absolute logo-${_id}`} style={style}>
            {finalSoure && visible ? <img src={`${finalSoure}`} style={style2} alt={element_id} /> : null}
            {
                <div className="logo-action absolute">
                    {element_id !== 'end_customer_logo' && (
                        <button
                            onClick={() =>
                                onSlideUpdate({
                                    id: _id,
                                    type: 'visible',
                                    value: !visible,
                                    group,
                                })
                            }
                        >
                            {visible ? (
                                <img src={`${iconEye}`} alt="visible" />
                            ) : (
                                <img src={`${iconEyeOff}`} alt="visible" />
                            )}
                        </button>
                    )}
                    {element_id !== 'end_customer_logo' && (
                        <button
                            onClick={() => {
                                if (context.currentSlide.elements) {
                                    const target = context.currentSlide.elements.find(
                                        (cce: any) => cce.element_id !== element_id && cce.group === group
                                    );
                                    const obj = {
                                        color: props.background_color,
                                        id: target._id,
                                        name: target.element_id,
                                    };
                                    context.colorpickview(obj);
                                }
                                // localmenuView({ _id, element_id, group })
                            }}
                        >
                            <img src={`${iconEdit}`} alt="edit" />
                        </button>
                    )}
                </div>
            }
        </div>
    );
};
export default Logos;

