// src/component/SingleSelect/SingleSelect.tsx
import React from 'react';
import { sortUpIcon, sortDownIcon } from '../../helpers/IconList';

type SingleSelectPropType = {
    label: string;
    direction?: string;
    name: string;
    val?: string;
    lists: string[];
    // change: (property: String, value: any) => void;
    change: (value: any) => void;
    changedirection?: (value: any) => void;
    stylename?: any;
    language?: any;
};

const SingleSelect: React.FC<SingleSelectPropType> = ({
    label = '',
    direction = '',
    name,
    val = '',
    lists,
    change,
    changedirection = () => {},
    stylename = '',
    language,
}) => {
    const handleChange = (e: any) => {
        const { value = '', name } = e.target;
        if (name === 'size') change(value * 1);
        else change(value);
    };

    const handleDirection = () => {
        const dir = direction === 'asc' ? 'desc' : 'asc';
        changedirection(dir);
    };

    const addedButton = () => {
        if (name !== 'sort') return null;
        return direction === 'asc' ? (
            <button onClick={handleDirection}>
                <img src={sortUpIcon} alt="up" role="presentation" />
            </button>
        ) : (
            <button onClick={handleDirection}>
                <img src={sortDownIcon} alt="down" role="presentation" />
            </button>
        );
    };

    const firstUpper = (text: string) => {
        const a = text.toLowerCase();
        return a.charAt(0).toUpperCase() + a.slice(1);
    };

    return (
        <section className={direction !== 'reverse' ? 'selector' : 'selector reverse'}>
            <label htmlFor="sort">{label}</label>
            <select className={stylename} name={name} id={name} value={val} onChange={handleChange}>
                {name !== 'sort'
                    ? lists.map((ll: any) => (
                          <option key={ll} value={ll}>
                              {ll}
                          </option>
                      ))
                    : lists.map((ll: any) => (
                          <option key={ll.id} value={ll.label}>
                              {firstUpper(language[ll.id] || ll.label)}
                          </option>
                      ))}
            </select>
            {addedButton()}
        </section>
    );
};
export default SingleSelect;
